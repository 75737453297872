// tslint:disable
import { CMValidator } from "../../validator";
import { CMInputConfig } from "./cminput.component";

export class CMValidatorMinLength extends CMValidator {
  validate(value: string, gridRow) {
    let v = CMValidator.trimValue(value);
    let config: CMInputConfig = this.validFunc(v);
    let minLength = 0;
    if (config.required || v.length > 0)
      minLength = config.getMinLength(gridRow);
    if (minLength > 0 && v.length < minLength)
      return "Tamanho mínimo (" + minLength + ")";
    else return undefined;
  }
}

export class CMValidatorMaxLength extends CMValidator {
  validate(value: string, gridRow) {
    let v = CMValidator.trimValue(value);
    let config: CMInputConfig = this.validFunc(v);
    if (
      config.getMaxLength(gridRow) > 0 &&
      v.length > config.getMaxLength(gridRow)
    ) {
      return "Tamanho máximo (" + config.getMaxLength(gridRow) + ")";
    } else return undefined;
  }
}

export class CMValidatorRequired extends CMValidator {
  validate(value: string, gridRow) {
    let v = CMValidator.trimValue(value);
    let config: CMInputConfig = this.validFunc(v);
    if (config.required) {
      let valido = v.length !== 0;
      if (valido) {
        if (config.pesqFunc !== undefined) {
          if (value === undefined) valido = false;
          else valido = JSON.stringify(value) !== JSON.stringify({});
        } else if (
          config.dataType === "integer" ||
          config.dataType.startsWith("double")
        )
          valido = CMInputConfig.valueToFloat(v) > 0;
      }
      if (!valido) return "Valor não informado";
    } else return undefined;
  }
}

export class CMValidatorDataType extends CMValidator {
  validate(value: string, gridRow) {
    let v: string = CMValidator.trimValue(value);
    let config: CMInputConfig = this.validFunc(v);
    let r = undefined;
    if (config.dataType === "integer" && v.length !== 0) {
      let int = CMInputConfig.valueToInteger(v);
      let valido = !isNaN(int);
      if (valido) valido = int === Math.floor(int);
      if (valido) valido = v.indexOf(".") === -1 && v.indexOf(",") === -1;
      if (!valido) r = "Apenas números";
    } else if (config.dataType.startsWith("double") && v.length !== 0) {
      if (v.indexOf(".") > -1) r = "Utilize vírgula";
      else {
        let int = CMInputConfig.valueToFloat(v);
        let valido = !isNaN(int);
        if (!valido) r = "Apenas números";
      }
    } else if (
      v.length === 10 &&
      (config.dataType === "date" || config.mask === "99/99/9999")
    ) {
      if (!CMInputConfig.validDate(v)) return "Data inválida";
    } else if (
      v.length === 5 &&
      (config.dataType === "time" ||
        config.mask === "99:99:99" ||
        config.mask === "99:99")
    ) {
      let arr = v.split(":");
      let valido = false;
      if (config.mask === "99:99:99") valido = arr.length === 3;
      else if (config.mask === "99:99") valido = arr.length === 2;
      if (valido) {
        let hora = CMInputConfig.valueToInteger(arr[0], 0);
        let minuto = CMInputConfig.valueToInteger(arr[1], 0);
        let segundos = 0;
        if (config.mask === "99:99:99")
          segundos = CMInputConfig.valueToInteger(arr[2], 0);
        valido = hora >= 0 && minuto >= 0 && segundos >= 0;
        if (valido) valido = hora >= 0 && hora < 24;
        if (valido) valido = minuto >= 0 && minuto < 60;
        if (valido && config.mask === "99:99:99")
          valido = segundos >= 0 && segundos < 60;
      }
      if (!valido) return "Hora inválida";
    }
    return r;
  }
}

export class CMValidatorEmail extends CMValidator {
  aceitaMaisUmEmail: boolean;
  constructor(aceitaMaisUmEmail: boolean = false) {
    super(null);
    this.aceitaMaisUmEmail = aceitaMaisUmEmail;
  }

  validate(value: string, gridRow) {
    let v = CMValidator.trimValue(value);
    if (v !== "") {
      let result: any = true;
      let emails = [];
      emails = v.split(";");
      if (!this.aceitaMaisUmEmail) result = emails.length <= 1;
      if (result) {
        emails.forEach(email => {
          email = CMInputConfig.trimValue(email);
          if (email !== "" && result) {
            result = email.match(
              /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/
            );
            if (result) {
              if (email.indexOf) {
                let sTab = "	";
                let invalidos = [",", ";", " ", sTab];
                invalidos.forEach(invalido => {
                  if (result) {
                    if (email.indexOf(invalido) > -1) result = false;
                  }
                });
              }
            }
          }
        });
      }
      if (!result) return "formato de e-Mail inválido";
      else return undefined;
    }
  }
}
