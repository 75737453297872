// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormRelatComponent, CMInputConfig,
         CMFormGlobaisComponent
       } from './../../../@cmcore/component';
import { AssociadoBrowseComponent } from '../../associado';

@Component({
    selector: 'uso-plataforma',
    templateUrl: 'uso-plataforma.component.html'
})
export class RelatUsoPlataformaComponent extends CMFormRelatComponent {

  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = 'ImpressaoUsoPlataforma';
    this.filterConfig = {
      dataini: new CMInputConfig({
        form: this,
        caption: 'Data Inicial',
        mask: '99/99/9999'
      }),
      datafim: new CMInputConfig({
        form: this,
        caption: 'Data Final',
        mask: '99/99/9999'
      }),

      status: new CMInputConfig({
        form: this,
        caption: "Status",
        values: [
          { value: "Ativo", text: "Ativo" },
          { value: "Inativo", text: "Inativo" },
          { value: "Todos", text: "Todos" },
        ],
      }),
      associado: new CMInputConfig({
        form: this,
        caption: "Associado",
        pesqFunc: (sender) => {
          AssociadoBrowseComponent.pesquisa(sender, this.filter, "Associado");
        },
      }),
      tipodocumento: new CMInputConfig({
        form: this,
        caption: "Tipo de Documento",
        values: [
          { value: "ApenasSAT", text: "Apenas SAT" },
          { value: "ApenasNFe", text: "Apenas NFe" },
          { value: "ApenasPreVenda", text: "Apenas Pré Venda" },
          { value: "Todos", text: "Todos" },
        ],
      }),
    }
    this.filter.dataini = this.firstDate();
    this.filter.datafim = this.lastDate();
    this.filter.status = 'Todos';
    this.filter.tipodocumento = 'Todos';
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r)
        CMFormGlobaisComponent.toastErro('Data inicial não informada');
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r)
        CMFormGlobaisComponent.toastErro('Data final não informada');
    }
    return r;
  }

}