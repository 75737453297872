// tslint:disable
import { Component, Injector } from "@angular/core";
import { ClienteBrowseComponent } from "./../cliente/cliente-browse.component";

import {
  CMFormEditComponent,
  CMFormGlobaisComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMInputConfig,
} from "./../../@cmcore/component";
import { FormaPagamentoBrowseComponent } from "../formapagamento/formapagamento-browse.component";

@Component({
  selector: "cupom-desconto-edit",
  templateUrl: "cupom-desconto-edit.component.html",
})
export class CupomDescontoEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_data";
  }

  getTitle() {
    let r: string = "Cupom de Desconto";
    let tmp: string = this.fieldByName("numero");
    if (tmp !== "0") r += " - #" + tmp;
    return r;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "CupomDesconto";
    this.setModelConfig();
  }

  afterGetModelData() {
    super.afterGetModelData();
    if (!this.disabledAll) this.disabledAll = this.model.utilizado === "Sim";
    if (!this.disabledAll) this.disabledAll = this.model.podeeditar !== "Sim";
    let dataprimeirovencimento = this.valueToDate(this.date);
    dataprimeirovencimento.setMonth(dataprimeirovencimento.getMonth() + 1);
    this.model.dataprimeirovencimento = this.dateToValue(
      dataprimeirovencimento
    );
  }

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({
        form: this,
        caption: "Código",
        disabled: true,
      }),
      datacadastro: new CMInputConfig({
        form: this,
        caption: "Data Cadastro",
        mask: "99/99/9999",
        required: true,
        disabledFunc: () => this.model.tipo === "Troca",
      }),
      datavalidade: new CMInputConfig({
        form: this,
        caption: "Data Validade",
        mask: "99/99/9999",
      }),

      datautilizado: new CMInputConfig({
        form: this,
        caption: "Data Utilização",
        mask: "99/99/9999",
        disabled: true,
      }),
      valor: new CMInputConfig({
        form: this,
        caption: "Valor",
        dataType: "double(2)",
        required: true,
        disabledFunc: () => this.model.tipo === "Troca",
        onExit: () => {
          this.model.contareceber.vliquido = this.model.valor;
        },
      }),
      vutilizado: new CMInputConfig({
        form: this,
        caption: "Valor Utilizado",
        dataType: "double(2)",
        disabled: true,
      }),
      utilizado: new CMInputConfig({
        form: this,
        caption: "Utilizado",
        dataType: "boolean",
        disabled: true,
      }),
      cliente: new CMInputConfig({
        form: this,
        caption: "Cliente",
        pesqFunc: (sender) => {
          ClienteBrowseComponent.pesquisa(sender, this.model);
        },
        disabledFunc: () => this.model.tipo === "Troca",
      }),

      tipo: new CMInputConfig({
        form: this,
        caption: "Tipo",
        values: [
          { value: "Desconto" },
          { value: "ValePresente", text: "Vale-Presente" },
          { value: "Troca", text: "Troca de Produto", disabled: true },
          { value: "Outros" },
        ],
        disabledFunc: () => this.model.tipo === "Troca",
      }),
      geracontareceber: new CMInputConfig({
        form: this,
        caption: "Gera conta a receber",
        dataType: "boolean",
      }),
      qtdeparcelas: new CMInputConfig({
        form: this,
        onSetValue: (oldValue, newValue) => {
          if (this.trimValue(oldValue) !== "" && oldValue !== newValue) {
            this.gerarDuplicatas();
          }
        },
        caption: "Condição de pagamento",
        values: FormaPagamentoBrowseComponent.valuesQtdeParcelas(),
      }),
      formapagamento: new CMInputConfig({
        form: this,
        caption: "Forma de pagamento",
        values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
        valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
      }),
      dataprimeirovencimento: new CMInputConfig({
        form: this,
        caption: "Data primeiro vencimento para nova geração",
        mask: "99/99/9999",
        iconButton: "refresh",
        hintButton: "Gerar duplicatas",
        execPesqOnlyOnExit: false,
        showButtonFunc: () => !this.disabledAll,
        pesqFunc: () => {
          this.gerarDuplicatas();
        },
      }),
      contareceber: {
        duplicatas: new CMGridEditConfig({
          form: this,
          caption: "Duplicatas",
          customValidItem: (item) => {
            return this.validDuplicata(item);
          },
          requiredFunc: () => {
            return this.model.geracontareceber === "Sim";
          },
          fields: {
            dvenc: new CMInputConfig({
              form: this,
              caption: "Data de vencimento",
              mask: "99/99/9999",
              gridConfig: new CMGridEditConfigColumn(),
              required: true,
            }),
            valor: new CMInputConfig({
              form: this,
              caption: "Valor",
              dataType: "double(2)",
              onExit: () => this.recalcularValorParcelas(),
              gridConfig: new CMGridEditConfigColumn({
                disabledFunc: (row) =>
                  this.getValueObject(row, "alteradomanualmente") !== "Sim",
              }),
              required: true,
            }),
            alteradomanualmente: new CMInputConfig({
              form: this,
              caption: "Alterar",
              dataType: "boolean",
              gridConfig: new CMGridEditConfigColumn({
                tipo: "checkbox",
                width: "60px",
              }),
            }),
            formapagamento: new CMInputConfig({
              form: this,
              caption: "Forma de pagamento",
              required: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: "select",
                onGetValue: (row) =>
                  this.getValueObject(row, "formapagamento.id"),
                onSetValue: (row, value) => {
                  this.modelConfig.contareceber.duplicatas.fields.formapagamento.setValueInObject(
                    row,
                    "formapagamento",
                    value
                  );
                },
              }),
              values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
              valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
            }),
          },
        }),
      },
    };
  }

  gerarDuplicatas() {
    CMFormGlobaisComponent.showSpinner();
    let duplicatas = [];
    let numeroParcelas = this.valueToInteger(this.model.qtdeparcelas, 0);
    if (numeroParcelas > 0) {
      let vliquido = this.valueToFloat(this.model.contareceber.vliquido, 0);
      let valorParcela = this.valueToFloat(
        this.arredondar(vliquido / numeroParcelas)
      );
      let dvenc = this.valueToDate(this.model.dataprimeirovencimento);
      dvenc.setMonth(dvenc.getMonth() - 1);
      for (let i = 0; i < numeroParcelas; i++) {
        dvenc.setMonth(dvenc.getMonth() + 1);
        duplicatas.push({
          id: CMGridEditConfig.newId(),
          dvenc: this.dateToValue(dvenc),
          valor: this.floatToValue(valorParcela),
          formapagamento: this.model.formapagamento,
        });
      }
      let dif = this.valueToFloat(
        this.arredondar(vliquido - valorParcela * numeroParcelas)
      );
      if (dif !== 0) {
        let valor = this.valueToFloat(duplicatas[0].valor);
        valor += dif;
        duplicatas[0].valor = this.floatToValue(valor);
      }
    }
    setTimeout(() => {
      this.modelConfig.contareceber.duplicatas.unSelect();
      this.model.contareceber.duplicatas = duplicatas;
      CMFormGlobaisComponent.hideSpinner();
    }, 500);
  }

  recalcularValorParcelas() {
    let valorFixo = 0;
    let numeroParcelas = 0;
    let primeiraDuplicataAutomatica = undefined;
    this.model.contareceber.duplicatas.forEach((duplicata) => {
      if (duplicata.alteradomanualmente == "Sim")
        valorFixo += this.valueToFloat(duplicata.valor);
      else {
        if (primeiraDuplicataAutomatica == undefined)
          primeiraDuplicataAutomatica = duplicata;
        numeroParcelas += 1;
      }
    });
    if (numeroParcelas > 0) {
      let vliquido =
        this.valueToFloat(this.model.contareceber.vliquido, 0) - valorFixo;
      if (vliquido > 0) {
        let valorParcela = this.valueToFloat(
          this.arredondar(vliquido / numeroParcelas)
        );
        this.model.contareceber.duplicatas.forEach((duplicata) => {
          if (duplicata.alteradomanualmente !== "Sim")
            duplicata.valor = this.floatToValue(valorParcela);
        });
        let dif = this.valueToFloat(
          this.arredondar(vliquido - valorParcela * numeroParcelas)
        );
        if (dif !== 0) {
          let valor = this.valueToFloat(primeiraDuplicataAutomatica.valor);
          valor += dif;
          primeiraDuplicataAutomatica.valor = this.floatToValue(valor);
        }
      }
    }
  }

  validDuplicata(duplicata: any) {
    let r: string = "";
    let dvenc = this.valueToDate(duplicata.dvenc);
    let data = this.valueToDate(this.model.datacadastro);
    if (dvenc < data)
      r = "Data de vencimento deve ser maior que a data de cadastro";
    return r;
  }
}
