// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMInputConfig,
  CMFormExecComponent,
  CMFormGlobaisComponent,
} from "../../@cmcore/component";
import { FuncsGlobais } from "../funcs.globais";

@Component({
  templateUrl: "pdv.component.html",
})
export class PDVComponent extends CMFormExecComponent {
  private sub: any;
  title: string = "";
  titleDestinatario: string = "";

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.sub = this.route.params.subscribe((params) => {
      this.title = "Geração do Arquivo Sped Fiscal";
      this.setfilterConfig();
    });
  }

  
  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  clearCampos() {
    this.filter.filtro.valoralterarstring = "";
  }

  setfilterConfig() {
    this.filterConfig = {
      filtro: {

        versao: new CMInputConfig({
          form: this,
          caption: 'Versão:',
          values: [
            {'value': "001"},
            {'value': "002"},
            {'value': "003"},
            {'value': "004"},
            {'value': "005"},
            {'value': "006"},
            {'value': "007"},
            {'value': "008"},
            {'value': "009"},
            {'value': "010"},
            {'value': "011"},
            {'value': "012"},
            {'value': "013"},
            {'value': "014"},
            {'value': "015"},
            {'value': "016"},
            {'value': "017"},
            {'value': "018"},
            {'value': "019"},
            {'value': "020"},
            {'value': "021"},
            {'value': "022"},
            {'value': "023"},
            {'value': "024"},
            {'value': "025"},
            {'value': "026"},
            {'value': "027"},
            {'value': "028"},
          ],
        }),
        dataini: new CMInputConfig({
          form: this,
          required: true,
          caption: "Data Inicial",
          mask: "99/99/9999",
        }),
        datafim: new CMInputConfig({
          form: this,
          required: true,
          caption: "Data Final",
          mask: "99/99/9999",
        }),

        /*
        indicadoratividade: new CMInputConfig({
            form: this,
            visible: false,
            caption: 'Indicador de atividade:',
            values: [
              {'value': "0", 'text': "Outros"},
              {'value': "1", 'text': "Industrial ou Equiparado"},
            ],
        }),*/

        gerarbloco0: new CMInputConfig({
            form: this,
            caption: "Gerar Bloco 0 - Abertura, Identificação e Referências"
        }),
        gerarbloco1: new CMInputConfig({
            form: this,
            caption: "Gerar Bloco 1 - Outras Informações"
        }),
        gerarregistro0400: new CMInputConfig({
            form: this,
            caption: "Gerar Registro 0400"
        }),
        gerarblococ: new CMInputConfig({
            form: this,
            caption: "Gerar Bloco C"
        }),

        /*
        gerarregistroc170: new CMInputConfig({
            form: this,
            visible: false,
            caption: "Gerar Registro C170 - Itens da NF-e"
        }),*/
        gerarblocoe: new CMInputConfig({
            form: this,
            caption: "Gerar Bloco E"
        }),
        e116: new CMInputConfig({
            form: this,
            caption: 'E116',
            maxlength: 100,
        }),
        e250: new CMInputConfig({
            form: this,
            caption: 'E250',
            maxlength: 100,
        }),
      },
    };
    this.filter.filtro.versao = "016";
    this.filter.filtro.indicadoratividade = "0";
  }

  valorAlterarVazio() {
    let valido = true;
    switch(this.filter.filtro.propriedadenova){
      case "Preço de Compra": {
        if (this.filter.filtro.valoralterarnumber.trim().length === 0) {
          valido = false;
        }
        break;
      }
    }

    return valido;
  }

  innerExec() {
    super.innerExec();
		this.geraArquivoSped();
  }


  geraArquivoSped() {
		let filter = this.filter.filtro;
    CMFormGlobaisComponent.showSpinner();
    let date = new Date;
    let dia = date.getDate();
    let diaformatado;
    let mes = date.getMonth()+1;
    let mesformatado;
    
    if (dia < 10) {
      diaformatado = "0" + dia;
    }
    else{
      diaformatado = dia;
    }

    if (mes === 10){
      mesformatado = "O";
    }
    else if (mes === 11){
      mesformatado = "N";
    }
    else if (mes === 12){
      mesformatado = "D";
    }
    else{
      mesformatado = mes;
    }

    console.log(this.paramEmpresa);

    console.log('filter: ', filter);
    
    this.postSubscribeT<Array<string>>('/api/empresaassociado/GeraSpedFiscal',
                  JSON.stringify(filter),
            (result) => {
              let result_str = result.pop();
              if (result_str === 'ok') {
                let ie = result.pop();
                let cnpj = result.pop();
                let contentFile = result.pop();

                console.log('ie: ', ie);
                console.log('cnpj: ', cnpj);
                console.log('contentFile: ', contentFile);

                let date = new Date();
                date.setMonth(mesformatado);

                let mesabreviado = date.toLocaleString('default', { month: 'short'}).slice(0,-1);
                let ano = date.getFullYear().toString();

                let fileName = 'SpedEFD-'+cnpj+'-'+ie+'Remessa de arquivo original-'+mesabreviado+ano+'.txt';
                //let fileName = 'SpedEFD-'+this.paramEmpresa.Convenio+mesformatado+diaformatado+'.crm';
                this.downloadContent(contentFile, fileName);


              }
              else {
                CMFormGlobaisComponent.toastErro(result_str);
              }
              CMFormGlobaisComponent.hideSpinner();
            },
            error => {
              CMFormGlobaisComponent.hideSpinner();
              this.showMessageErrorHTTP('geraArquivoCNAB', error);
            });
  }
  
}
