// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
  CMFormGlobaisComponent,
} from "./../../@cmcore/component";
import { ClienteBrowseComponent } from "../cliente";
import { FormaPagamentoBrowseComponent } from "../formapagamento";
import { PreVendaBrowseComponent } from "../prevenda";
import { NFeBrowseComponent } from "../nfe";
import { NFCeBrowseComponent } from "../nfce";
import { FuncsGlobais } from "../funcs.globais";

@Component({
  selector: "lote-conta-receber-edit",
  templateUrl: "lote-conta-receber-edit.component.html",
})
export class LoteContaReceberEditComponent extends CMFormEditComponent {
  valuesOrigem = [];
  setValuesOrigem() {
    if (this.paramEmpresa.modoOperacao === "Nivel1"){
    this.valuesOrigem = [
      { value: "PreVenda", text: "Pré-Venda" },
      { value: "NFe", text: "NF-e" },
    ];
    }else {this.valuesOrigem = [
      { value: "PreVenda", text: "Pré-Venda" },
      { value: "NFe", text: "NF-e" },
      { value: "NFCe", text: "NFC-e" },
      
    ];
    }
  }

  getFirstComponentName() {
    return "model_contareceber_destinatario";
  }

  getTitle() {
    return "Lote Conta a Receber";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.setValuesOrigem();
    this.controllerName = "LoteContaReceber";
    this.setModelConfig();
  }

  afterGetModelData() {
    super.afterGetModelData();
    if (!this.disabledAll) {
      this.disabledAll = this.model.podeeditar !== "Sim";
    }
  }

  setModelConfig() {
    this.modelConfig = {
      formapagamento: new CMInputConfig({
        form: this,
        caption: "Forma de pagamento",
        gridConfig: new CMGridEditConfigColumn({
          tipo: "select",
          onGetValue: (row) =>
            this.getValueObject(row, "formapagamento.id"),
          onSetValue: (row, value) => {
            this.modelConfig.contareceber.duplicatas.fields.formapagamento.setValueInObject(
              row,
              "formapagamento",
              value
            );
          },
        }),
        values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
        valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
      }),
      qtdeparcelas: new CMInputConfig({
        form: this,
        onSetValue: (oldValue, newValue) => {
          if (this.trimValue(oldValue) !== "" && oldValue !== newValue)
            this.gerarDuplicatas();
        },
        caption: "Condição de pagamento",
        values: FormaPagamentoBrowseComponent.valuesQtdeParcelas(),
      }),
      documentos: new CMGridEditConfig({
        form: this,
        caption: "Documentos",
        onNewItem: (model) => {
          this.clearDuplicatas();
          Object.assign(model, {
            tipo: "PreVenda",
          });
        },
        onRemoveItem: (item) => {
          this.clearDuplicatas();
        },
        fields: {
          tipo: new CMInputConfig({
            form: this,
            caption: "Tipo",
            required: true,
            gridConfig: new CMGridEditConfigColumn(),
            values: this.valuesOrigem,
          }),
          prevenda: new CMInputConfig({
            form: this,
            caption: "Pré-Venda",
            pesqFunc: (sender) => {
              PreVendaBrowseComponent.pesquisa(
                sender,
                this.modelConfig.documentos.current,
                undefined,
                () => this.calcValorOriginal(),
                this.model.contareceber.destinatario.id,
                "RecebimentoFuturo"
              );
            },
            onExit: () => {
              this.calcValorOriginal();
            },
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "prevenda.numero");
              },
              getBottomLabelColumn: (row) => {
                let r = this.getValueObject(row, "prevenda.cobranca.vliquido");
                if (r !== "") r = "Valor: " + r;
                return r;
              },
              disabledFunc: (row) => {
                return this.getValueObject(row, "tipo") !== "PreVenda";
              },
            }),
          }),
          nfe: new CMInputConfig({
            form: this,
            caption: "NF-e",
            pesqFunc: (sender) => {
              NFeBrowseComponent.pesquisa(
                sender,
                this.modelConfig.documentos.current,
                'nfe',
                () => this.calcValorOriginal(),
                this.model.contareceber.destinatario.id,
                "RecebimentoFuturo"
              );
            },
            onExit: () => {
              this.calcValorOriginal();
            },
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "nfe.infnfe.ide.nnf");
              },
              getBottomLabelColumn: (row) => {
                let r = this.getValueObject(row, "nfe.infnfe.cobr.vliquido");
                if (r !== "") r = "Valor: " + r;
                return r;
              },
              disabledFunc: (row) => {
                return this.getValueObject(row, "tipo") !== "NFe";
              },
            }),
          }),
          nfce: new CMInputConfig({
            form: this,
            caption: "NFC-e",
            visibleFunc: () =>
            FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
            pesqFunc: (sender) => {
              NFCeBrowseComponent.pesquisa(
                sender,
                this.modelConfig.documentos.current,
                'nfce',
                () => this.calcValorOriginal(),
                this.model.contareceber.destinatario.id,
                "RecebimentoFuturo"
              );
            },
            onExit: () => {
              this.calcValorOriginal();
            },
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "nfce.infnfe.ide.nnf");
              },
              getBottomLabelColumn: (row) => {
                let r = this.getValueObject(row, "nfce.infnfe.cobr.vliquido");
                if (r !== "") r = "Valor: " + r;
                return r;
              },
              disabledFunc: (row) => {
                return this.getValueObject(row, "tipo") !== "NFCe";
              },
            }),
          }),
        },
      }),
      contareceber: {
        voriginal: new CMInputConfig({
          form: this,
          caption: "Valor bruto",
          dataType: "double(2)",
          disabled: true,
          onExit: () => {
            this.calcValorLiquido();
          },
        }),
        vdesconto: new CMInputConfig({
          form: this,
          caption: "Desconto financeiro",
          dataType: "double(2)",
          onExit: () => {
            this.calcValorLiquido();
          },
        }),
        vliquido: new CMInputConfig({
          form: this,
          caption: "Valor liquído",
          dataType: "double(2)",
          disabled: true,
        }),
        destinatario: new CMInputConfig({
          form: this,
          caption: "Cliente",
          required: true,
          disabledFunc: () => this.model.documentos.length > 0,
          pesqFunc: (sender) => {
            ClienteBrowseComponent.pesquisa(
              sender,
              this.model.contareceber,
              "destinatario"
            );
          },
        }),
        datacadastro: new CMInputConfig({
          form: this,
          caption: "Data de cadastro",
          mask: "99/99/9999",
          required: true,
          disabled: true,
        }),
        vacrescimo: new CMInputConfig({
          form: this,
          caption: "Acréscimo financeiro",
          dataType: "double(2)",
          onExit: () => {
            this.calcValorLiquido();
          },
        }),
        duplicatas: new CMGridEditConfig({
          form: this,
          caption: "Duplicatas",
          customValidItem: (item) => {
            return this.validDuplicata(item);
          },
          moreActions: [
            new CMGridEditConfigMore({
              hint: "Mais detalhes",
              icone: () => {
                if (this.disabledAll) return "visibility";
                else return "edit";
              },
            }),
          ],
          fields: {
            dvenc: new CMInputConfig({
              form: this,
              caption: "Data de vencimento",
              mask: "99/99/9999",
              gridConfig: new CMGridEditConfigColumn(),
              required: true,
            }),
            valor: new CMInputConfig({
              form: this,
              caption: "Valor",
              dataType: "double(2)",
              onExit: () => this.recalcularValorParcelas(),
              gridConfig: new CMGridEditConfigColumn({
                disabledFunc: (row) =>
                  this.getValueObject(row, "alteradomanualmente") !== "Sim",
              }),
              required: true,
            }),
            alteradomanualmente: new CMInputConfig({
              form: this,
              caption: "Alterar",
              dataType: "boolean",
              gridConfig: new CMGridEditConfigColumn({
                tipo: "checkbox",
                width: "60px",
              }),
            }),
            formapagamento: new CMInputConfig({
              form: this,
              caption: "Forma de pagamento",
              required: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: "select",
                onGetValue: (row) =>
                  this.getValueObject(row, "formapagamento.id"),
                onSetValue: (row, value) => {
                  this.modelConfig.contareceber.duplicatas.fields.formapagamento.setValueInObject(
                    row,
                    "formapagamento",
                    value
                  );
                },
              }),
              values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
              valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
            }),
            observacao: new CMInputConfig({
              form: this,
              caption: "Observação",
            }),
          },
        }),
      },
    };
  }

  validDuplicata(duplicata: any) {
    let r: string = "";
    let dvenc = this.valueToDate(duplicata.dvenc);
    let data = this.valueToDate(this.model.contareceber.datacadastro);
    if (dvenc < data)
      r = "Data de vencimento deve ser maior que a data de cadastro";
    return r;
  }

  calcValorLiquido() {
    let vliquido =
      this.valueToFloat(this.model.contareceber.voriginal, 0) -
      this.valueToFloat(this.model.contareceber.vdesconto, 0) +
      this.valueToFloat(this.model.contareceber.vacrescimo, 0);
    this.model.contareceber.vliquido = this.floatToValue(vliquido);
  }

  customValid() {
    let r = super.customValid();
    if (r === "") {
      if (this.valueToFloat(this.model.contareceber.vliquido, 0) <= 0)
        r = "Valor líquido deve ser maior que zero";
    }
    if (r === "") {
      let duplicatas = this.model.contareceber.duplicatas;
      let vTotalDup: number = 0;
      for (let i = 0; i < duplicatas.length; i++)
        vTotalDup += this.valueToFloat(duplicatas[i].valor);
      let vliquido = this.valueToFloat(this.model.contareceber.vliquido, 0);
      if (this.arredondar(vliquido) !== this.arredondar(vTotalDup))
        r = "Valor líquido difere do valor total das duplicatas";
    }
    return r;
  }

  gerarDuplicatas() {
    CMFormGlobaisComponent.showSpinner();
    let duplicatas = [];
    let numeroParcelas = this.valueToInteger(this.model.qtdeparcelas, 0);
    if (numeroParcelas > 0) {
      let vliquido = this.valueToFloat(this.model.contareceber.vliquido, 0);
      let valorParcela = this.valueToFloat(
        this.arredondar(vliquido / numeroParcelas)
      );
      let dvenc = this.valueToDate(this.model.contareceber.datacadastro);
      for (let i = 0; i < numeroParcelas; i++) {
        dvenc.setMonth(dvenc.getMonth() + 1);
        duplicatas.push({
          id: CMGridEditConfig.newId(),
          dvenc: this.dateToValue(dvenc),
          valor: this.floatToValue(valorParcela),
          formapagamento: this.model.formapagamento,
        });
      }
      let dif = this.valueToFloat(
        this.arredondar(vliquido - valorParcela * numeroParcelas)
      );
      if (dif !== 0) {
        let valor = this.valueToFloat(duplicatas[0].valor);
        valor += dif;
        duplicatas[0].valor = this.floatToValue(valor);
      }
    }
    setTimeout(() => {
      this.modelConfig.contareceber.duplicatas.unSelect();
      this.model.contareceber.duplicatas = duplicatas;
      CMFormGlobaisComponent.hideSpinner();
    }, 500);
  }

  recalcularValorParcelas() {
    let valorFixo = 0;
    let numeroParcelas = 0;
    let primeiraDuplicataAutomatica = undefined;
    this.model.contareceber.duplicatas.forEach((duplicata) => {
      if (duplicata.alteradomanualmente == "Sim")
        valorFixo += this.valueToFloat(duplicata.valor);
      else {
        if (primeiraDuplicataAutomatica == undefined)
          primeiraDuplicataAutomatica = duplicata;
        numeroParcelas += 1;
      }
    });
    if (numeroParcelas > 0) {
      let vliquido =
        this.valueToFloat(this.model.contareceber.vliquido, 0) - valorFixo;
      if (vliquido > 0) {
        let valorParcela = this.valueToFloat(
          this.arredondar(vliquido / numeroParcelas)
        );
        this.model.contareceber.duplicatas.forEach((duplicata) => {
          if (duplicata.alteradomanualmente !== "Sim")
            duplicata.valor = this.floatToValue(valorParcela);
        });
        let dif = this.valueToFloat(
          this.arredondar(vliquido - valorParcela * numeroParcelas)
        );
        if (dif !== 0) {
          let valor = this.valueToFloat(primeiraDuplicataAutomatica.valor);
          valor += dif;
          primeiraDuplicataAutomatica.valor = this.floatToValue(valor);
        }
      }
    }
  }

  calcValorOriginal() {
    let total = 0;
    this.model.documentos.forEach((documento) => {
      if (documento.tipo === "PreVenda") {
        total += this.valueToFloat(
          this.getValueObject(documento, "prevenda.cobranca.vliquido"),
          0
        );
      } else if (documento.tipo === "NFe") {
        total += this.valueToFloat(
          this.getValueObject(documento, "nfe.infnfe.cobr.vliquido"),
          0
        );
      }
      else if (documento.tipo === "NFCe") {
        total += this.valueToFloat(
          this.getValueObject(documento, "nfce.infnfe.cobr.vliquido"),
          0
        );
      }

    });
    this.model.contareceber.voriginal = this.floatToValue(total);
    this.calcValorLiquido();
  }

  showActionAddDocumentos() {
    return (
      this.getValueObject(this.model, "contareceber.destinatario.id") !== ""
    );
  }

  clearDuplicatas() {
    setTimeout(() => {
      this.model.qtdeparcelas = 0;
      this.modelConfig.contareceber.duplicatas.unSelect();
      this.model.contareceber.duplicatas = [];
      this.calcValorOriginal();
    }, 500);
  }
  
  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }
}
