// tslint:disable
import { Component, Injector } from "@angular/core";
import { FormaPagamentoBrowseComponent } from "../../formapagamento";

import {
  CMFormRelatComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
} from "./../../../@cmcore/component";
import { ClienteBrowseComponent } from "./../../cliente/cliente-browse.component";

@Component({
  selector: "conta-receber",
  templateUrl: "conta-receber.component.html",
})
export class RelatContaReceberComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoContaReceber";
    this.filterConfig = {
      cliente: new CMInputConfig({
        form: this,
        caption: "Cliente",
        pesqFunc: (sender) => {
          ClienteBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      formapagamento: new CMInputConfig({
        form: this,
        caption: "Forma de Pagamento",
        disabledFunc: () => {
          return this.filter.tipopagamento == 'AVista';
        },
        pesqFunc: (sender) => {
          FormaPagamentoBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      dataini: new CMInputConfig({
        form: this,
        caption: "Data Inicial",
        mask: "99/99/9999",
      }),
      datafim: new CMInputConfig({
        form: this,
        caption: "Data Final",
        mask: "99/99/9999",
      }),
      somentecontasemaberto: new CMInputConfig({
        form: this,
        caption: "Somente contas em aberto",
        disabledFunc: () => {
          return this.filter.tipopagamento == 'AVista';
        },
      }),
      demonstrarpagamentosavista: new CMInputConfig({
        form: this,
        caption: "Demonstra pagamentos a vista",
        disabledFunc: () => {
          return this.filter.tipopagamento !== 'Todos';
        },
      }),
      mostraendereco: new CMInputConfig({
        form: this,
        caption: "Demonstra endereço do cliente",
      }),
      tipopagamento: new CMInputConfig({
        form: this,
        caption: "Tipo de Recebimento",
        values: [
          { value: "Todos", text: "Todos" },
          { value: "AVista", text: "A Vista" },
          { value: "FormaPagamento", text: "Por forma de pagamento" },
        ],
      }),
    };
    this.filter.dataini = this.firstDate();
    this.filter.datafim = this.lastDate();
    this.filter.somentecontasemaberto = "Sim";
    this.filter.demonstrarpagamentosavista = "Não";
    this.filter.mostraendereco = "Não";
    this.filter.tipopagamento = "Todos";
    
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r) CMFormGlobaisComponent.toastErro("Data inicial não informada");
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r) CMFormGlobaisComponent.toastErro("Data final não informada");
    }
    return r;
  }
}
