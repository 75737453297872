// tslint:disable
import { Injector, OnDestroy } from "@angular/core";

import { CMFormComponent } from "../../../component/cmform/cmform.component";
import {
  CMGridComponent,
  CMFormModalComponent,
  CMFormEditComponent,
  CMDynamicCreatorComponent,
  CMFormGlobaisComponent,
  CMInputConfig,
  CMFormEmailComponent,
} from "../../../component";
import { AppComponent } from "./../../../../app.component";

export class CMFormBrowseComponent
  extends CMFormComponent
  implements OnDestroy {
  static iconeFiltro: string = "keyboard_arrow_down";
  constructor(_injector: Injector) {
    super(_injector);
    this.addCheckReload();
  }

  ngOnDestroy() {
    this.removeCheckReload();
  }

  protected formEdit: CMFormEditComponent = null;

  public formEditComponent: any = undefined;

  protected __formEditDynamic: any;

  public beforeComponent: any = undefined;

  protected columns = [];

  public columnsGrid = [];

  public grid: CMGridComponent;

  public fixedFooterFormEdit: boolean = true;

  protected showPrintButton: boolean = false;

  protected showEmailButton: boolean = false;

  protected controllerName: string = "";

  public filters = [];

  public valuesFilters: any = {};

  protected extraValuesFilters: any = {};

  public buttonsExtra: number = 0;

  protected direitoButtonsExtra: boolean[] = [];

  getButtonsExtraArray() {
    let r = [];
    r.length = this.buttonsExtra;
    return r;
  }

  buttonsExtraClick(index: number) {}
  buttonsExtraIcon(index: number) {}
  buttonsExtraHint(index: number) {}
  buttonsExtraPosicao(index: number) {
    return "record";
  }
  buttonsExtraVisible(index: number) {
    return this.buttonsExtraDireito(index);
  }
  buttonsExtraDireito(index: number) {
    let r: boolean = false;
    if (index < this.direitoButtonsExtra.length)
      r = this.direitoButtonsExtra[index];
    return r;
  }

  fieldNameId() {
    return "id";
  }

  loadDireitoButtonsExtra() {
    if (this.buttonsExtra > 0 && this.direitoButtonsExtra.length === 0) {
      for (let i = 0; i < this.buttonsExtra; i++) {
        this.loadDireitoButtonExtra(i);
        this.direitoButtonsExtra.push(false);
      }
    }
  }

  loadDireitoButtonExtra(index: number) {}

  disabledAllFormEdit() {
    let r: boolean = false;
    if (this.formEdit) r = this.formEdit.disabledAll;
    return r;
  }

  showButtonExtraFormEdit() {
    let r: boolean = false;
    if (this.formEdit) r = this.formEdit.showButtonExtra();
    return r;
  }

  hintButtonExtraFormEdit() {
    let r: string = "";
    if (this.formEdit) r = this.formEdit.hintButtonExtra();
    return r;
  }

  iconButtonExtraFormEdit() {
    let r: string = "";
    if (this.formEdit) r = this.formEdit.iconButtonExtra();
    return r;
  }

  clickButtonExtraFormEdit() {
    if (this.formEdit) this.formEdit.clickButtonExtra();
  }

  _print() {
    if (this.diretoImprimir) this.print();
  }

  _email() {
    if (this.diretoEmail) this.getDadosEmail();
  }

  getDataPrint(data) {
    return data;
  }

  print() {
    let data = { id: this.grid.fieldByName(this.fieldNameId()) };
    data = this.getDataPrint(data);
    this.showRelat("Print", data, this.controllerName);
  }

  getColumnsFilter() {
    let r = Math.floor(11 / this.filters.length);
    if (r < 3) r = 3;
    return r;
  }

  afterOnPage() {}

  msgSemDados = "";
  onPage(grid: CMGridComponent) {
    this.grid = grid;
    this.msgSemDados = "Buscando dados. Aguarde...";
    if (this.possuiAlgumDireito()) {
      let valuesFilters: any = this.extraValuesFilters;
      this.filters.forEach((item) => {
        if (item.filter) {
          let value = this.getValueObject(this.valuesFilters, item.filterField);
          if (value !== undefined) {
            if (item.filter.valid(value, true) === "")
              valuesFilters[item.filterField] = value;
          }
        }
      });
      this.postSubscribe(
        AppComponent.basePathApi +
          "/api/" +
          this.controllerName.toLowerCase() +
          "/getbrowseobjects",
        JSON.stringify({
          page: grid.page,
          limit: grid.limit,
          filter: valuesFilters,
        }),
        (data) => {
          if (data["data"] !== undefined && data["totalCount"] !== undefined) {
            grid.setPageData(data["data"], data["totalCount"]);
            if (data["data"].length == 0) {
              this.msgSemDados = "Sem dados para exibir";
            }
          } else {
            grid.setPageData([], 0);
            this.msgSemDados = "Sem dados para exibir";
          }
          this.afterOnPage();
          return true;
        },
        (error) => {
          this.showMessageErrorHTTP("onPage", error);
        }
      );
    } else grid.setPageData([], 0);
  }

  selectedItem() {
    if (this.grid) {
      return this.grid.selected.length > 0;
    } else {
      return false;
    }
  }

  modalFormEditClose() {
    this.formEditAberto = false;
    CMFormModalComponent.close("modalFormEdit");
    if (this.formEdit) {
      this.formEdit.unSelectItens(this.formEdit.modelConfig);
      this.generateEmptyModel(this.formEdit.modelConfig, this.formEdit.model);
    }
  }

  getTitleEdit() {
    if (this.formEdit) return this.formEdit.getTitle();
    else return "";
  }

  getTitle() {
    return "";
  }

  modalFormEditSave(event: any) {
    if (this.diretoCadastrar || this.diretoEditar)
      this.formEdit.save(<any>this);
  }

  deleteModel(idDataModel: number) {
    if (this.diretoApagar) {
      CMFormGlobaisComponent.showSpinner();
      let data = {};
      data[this.fieldNameId()] = idDataModel;
      this.postSubscribe(
        AppComponent.basePathApi +
          "/api/" +
          this.controllerName.toLowerCase() +
          "/deleteobject",
        JSON.stringify(data),
        (result) => {
          if (result["result"] === "ok") {
            this.grid.refreshCurrentPage();
          } else {
            let str: string = '<p class="divider"></p><div class="container">';
            str +=
              "<p style='font-size: 1.5em;'><b>Mensagem:</b> " +
              result["result"] +
              "</p>";
            str += '</div><p class="divider"></p>';
            CMFormGlobaisComponent.showMessageAviso(str);
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("deleteModel", error);
        }
      );
      return {};
    }
  }

  focusFirstComponentEdit() {
    this.formEdit.focusFirstComponentEdit();
  }

  formEditAberto: boolean = false;

  openmodalFormEdit() {
    this.formEditAberto = true;
    CMFormModalComponent.open("modalFormEdit", (r) => {
      this.formEdit.activeFirstPageTabs();
      this.focusFirstComponentEdit();
    });
  }

  add(formEditDynamic: CMDynamicCreatorComponent, onAfter?: Function) {
    if (formEditDynamic == undefined) formEditDynamic = this.__formEditDynamic;
    if (this.diretoCadastrar) {
      this.formEdit = formEditDynamic.componentReference.instance;
      this.formEdit.disabledAll = false;
      this.formEdit.getModelData(0, (model) => {
        this.openmodalFormEdit();
        if (onAfter) onAfter(model);
      });
    }
  }

  edit(formEditDynamic: CMDynamicCreatorComponent) {
    if (this.diretoEditar) {
      this.formEdit = formEditDynamic.componentReference.instance;
      this.formEdit.disabledAll = false;
      this.formEdit.getModelData(
        this.grid.selected[0][this.fieldNameId()],
        (r) => {
          this.openmodalFormEdit();
        }
      );
    }
  }

  view(formEditDynamic: CMDynamicCreatorComponent) {
    if (this.diretoVisualizar) {
      this.formEdit = formEditDynamic.componentReference.instance;
      this.formEdit.disabledAll = true;
      this.formEdit.getModelData(
        this.grid.selected[0][this.fieldNameId()],
        (r) => {
          this.openmodalFormEdit();
        }
      );
    }
  }

  delete(formEditDynamic: CMDynamicCreatorComponent) {
    if (this.diretoApagar) {
      this.formEdit = formEditDynamic.componentReference.instance;
      this.deleteModel(this.grid.selected[0][this.fieldNameId()]);
    }
  }

  clickDelete(event) {
    if (this.diretoApagar) {
      if (this.grid) this.grid._onRemove(event);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.internalInit();
  }

  internalInit() {
    this.filters = [];
    this.valuesFilters = {};
    this.columnsGrid = [];
    this.columns.forEach((item) => {
      if (item.visible === undefined) item.visible = true;
      if (item.width === undefined) item.width = 1;
      if (item.visible) {
        if (item.visible instanceof Function) {
          if (item.visible()) this.columnsGrid.push(item);
        } else this.columnsGrid.push(item);
      }
      if (item.filter) {
        if (item.filterVisible === undefined) {
          item.filterVisible = () => {
            return true;
          };
        } else if (!(item.filterVisible instanceof Function)) {
          item._filterVisible = item.filterVisible;
          item.filterVisible = () => {
            return item._filterVisible;
          };
        }
        if (item.filterField === undefined) item.filterField = item.field;
        if (item.filterValue === undefined) item.filterValue = item.filterField;
        if (item.filter === true) {
          item.filter = new CMInputConfig({
            caption: item.caption,
          });
        } else {
          if (item.filter.caption === "") item.filter.caption = item.caption;
          if (item.filter.dataType === "boolean") {
            item.filter.values = [
              { text: "", value: "" },
              { text: "Sim", value: "true" },
              { text: "Não", value: "false" },
            ];
          }
        }
        this.filters.push(item);
      }
      if (item.value) {
        if (item.value instanceof Function)
          this.valuesFilters[item.field] = item.value();
        else this.valuesFilters[item.field] = item.value;
      }
    });
    this.getDireitosMenuBrowse();
  }

  filtrar() {
    if (document.activeElement instanceof HTMLElement)
      document.activeElement.blur();
    if (this.grid) {
      this.grid.page = 0;
      this.grid.refreshCurrentPage();
    }
  }

  lastHashReload: string = localStorage.getItem("lastHashReload");
  needReload() {
    return this.lastHashReload !== localStorage.getItem("lastHashReload");
  }

  private handleStorageEvent = (event: StorageEvent): void => {
    if (this.needReload()) {
      window.location.reload();
    }
  };

  addCheckReload() {
    window.addEventListener("storage", this.handleStorageEvent);
  }

  removeCheckReload() {
    window.removeEventListener("storage", this.handleStorageEvent);
  }

  refresh() {
    if (this.grid) this.grid.refreshCurrentPage();
  }

  getShowPrintButton() {
    let r: boolean = this.diretoImprimir;
    if (r && this.grid) {
      r = this.showPrintButton;
      if (r) r = this.grid.isCurrent;
    }
    return r;
  }

  getShowEmailButton() {
    let r: boolean = this.diretoEmail;
    if (r && this.grid) {
      r = this.showEmailButton;
      if (r) r = this.grid.isCurrent;
    }
    return r;
  }

  getShowActionAdd() {
    return this.diretoCadastrar;
  }

  getShowActionEdit() {
    let r: boolean = this.diretoEditar;
    if (r && this.grid) {
      r = this.grid.isCurrent;
    }
    return r;
  }

  getShowActionView() {
    let r: boolean = this.diretoVisualizar;
    if (r && this.grid) {
      r = this.grid.isCurrent;
    }
    return r;
  }

  getShowMenuActions() {
    return true;
  }

  getShowActionRemove() {
    let r: boolean = this.diretoApagar;
    if (r && this.grid) {
      r = this.grid.isCurrent;
    }
    return r;
  }

  getShowActionHelp() {
    return this.helps.length > 0;
  }

  _hideShowFilters: boolean = false;
  hideShowFilters(event) {
    this._hideShowFilters = !this._hideShowFilters;
  }

  getIconHideShow() {
    if (this._hideShowFilters) return "keyboard_arrow_up";
    else return CMFormBrowseComponent.iconeFiltro;
  }

  limparFiltros() {
    this.valuesFilters = {};
    this.filtrar();
  }

  protected getKeyDownCodes() {
    let r = super.getKeyDownCodes();
    r.push(13);
    return r;
  }

  protected onKeydown(keyCode: number, event: KeyboardEvent) {
    super.onKeydown(keyCode, event);
    switch (keyCode) {
      case 13: // enter
        if (!this.formEditAberto) this.filtrar();
        break;
    }
  }

  protected diretoCadastrar: boolean = false;
  protected diretoEditar: boolean = false;
  protected diretoVisualizar: boolean = false;
  protected diretoApagar: boolean = false;
  protected diretoImprimir: boolean = false;
  protected diretoExecutar: boolean = false;
  protected diretoEmail: boolean = false;
  public helps = [];

  possuiAlgumDireito() {
    let r: boolean =
      this.diretoCadastrar ||
      this.diretoEditar ||
      this.diretoApagar ||
      this.diretoVisualizar ||
      this.diretoExecutar ||
      this.diretoImprimir ||
      this.diretoEmail;
    return r;
  }

  protected innerGetDireitosMenuBrowse(data: Object, controllerName: string) {
    this.diretoCadastrar = data["Cadastrar"] === "true";
    this.diretoEditar = data["Editar"] === "true";
    this.diretoVisualizar = data["Visualizar"] === "true";
    this.diretoApagar = data["Apagar"] === "true";
    this.diretoImprimir = data["Imprimir"] === "true";
    this.diretoEmail = data["Email"] === "true";
    this.diretoExecutar = data["Executar"] === "true";
    if (data["Helps"]) this.helps = data["Helps"];
    else this.helps = [];
    this.setCacheDireitos(data, controllerName);
    if (this.grid) this.onPage(this.grid);
  }

  getDireitosMenuBrowse() {
    let cacheData = this.getCacheDireitos(this.controllerName);
    if (cacheData) {
      this.innerGetDireitosMenuBrowse(cacheData, this.controllerName);
      this.loadDireitoButtonsExtra();
    } else {
      this.postSubscribe(
        AppComponent.basePathApi +
          "/api/" +
          this.controllerName.toLowerCase() +
          "/getdireitos",
        JSON.stringify({
          hash: "ClassName",
          tipo: "Direct",
        }),
        (data) => {
          this.innerGetDireitosMenuBrowse(data, this.controllerName);
          this.loadDireitoButtonsExtra();
          return true;
        },
        (error) => {
          this.showMessageErrorHTTP("getDireitosMenuBrowse", error);
        }
      );
    }
  }

  private setDireitoMenuBrowseButtonsExtraData(index: number, data) {
    this.direitoButtonsExtra[index] = data["Executar"] === "true";
  }

  setDireitoMenuBrowseButtonsExtra(index: number, bol: boolean) {
    this.direitoButtonsExtra[index] = bol;
  }

  getDireitosMenuBrowseButtonsExtra(index: number, funcName: string) {
    let cacheData = this.getCacheDireitos(
      this.controllerName,
      index.toString()
    );
    if (cacheData) {
      this.setDireitoMenuBrowseButtonsExtraData(index, cacheData);
    } else {
      this.postSubscribe(
        AppComponent.basePathApi +
          "/api/" +
          this.controllerName.toLowerCase() +
          "/getdireitos",
        JSON.stringify({
          hash: "ClassName." + funcName,
          tipo: "Extra",
        }),
        (data) => {
          this.setCacheDireitos(data, this.controllerName, index.toString());
          this.setDireitoMenuBrowseButtonsExtraData(index, data);
          return true;
        },
        (error) => {
          this.showMessageErrorHTTP("getDireitosMenuBrowseButtonsExtra", error);
        }
      );
    }
  }

  togglePopup(event) {
    setTimeout(() => {
      const popup = document.getElementById("popupHelp_" + this.name);
      popup.style.top = "auto";
      popup.style.left = "auto";
      popup.style.marginRight = "10px";
      popup.style.right = "0";
      popup.classList.toggle("show");
    }, 10);
  }

  showHelpLinkIFrame(recursoHelp) {
    let html = this.trimValue(recursoHelp.link);
    if (!html.toLocaleLowerCase().startsWith("<iframe")) {
      html =
        '<iframe src="' +
        recursoHelp.link +
        '"' +
        '        style="width: 100%; height: calc(100vh - 200px)"' +
        '        frameborder="0"' +
        '        allow="accelerometer;' +
        "        autoplay;" +
        "        encrypted-media;" +
        "        gyroscope;" +
        '        picture-in-picture"' +
        "        allowfullscreen" +
        "></iframe>";
    }
    CMFormGlobaisComponent.showMessageHtml(html, recursoHelp.titulo);
  }

  emailOpenForm(data) {
    let id = this.grid.fieldByName("id");
    data.id = id;
    data.tipo = "SendMail";
    CMFormEmailComponent.open(
      "/api/" + this.controllerName + "/Email",
      data.caption,
      data
    );
  }

  getDadosEmail() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/" + this.controllerName + "/Email",
      JSON.stringify({
        id: this.grid.fieldByName("id"),
        tipo: "GetData",
      }),
      (data) => {
        if (data["result"] === "true") this.emailOpenForm(data);
        else CMFormGlobaisComponent.toastErro(data["msg"]);
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("getDadosEmail", error);
      }
    );
  }

  areFormsSaved() {
    let r = !this.formEditAberto;
    if (!r) {
      if (this.formEdit) {
        r = this.formEdit.disabledAll;
        if (!r) {
          r = !this.formEdit.modified();
        }
      }
    }
    if (!r) {
      CMFormGlobaisComponent.toastInformacao(
        "Dados não salvos. Verifique!",
        "ATENÇÃO"
      );
    }
    return r;
  }
}
