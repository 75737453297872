// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent } from './../../@cmcore/component';
import { FormaPagamentoEditComponent } from './formapagamento-edit.component';
import { HttpClient } from '@angular/common/http';
import { FuncsGlobais } from '../funcs.globais';

@Component({
  selector: 'formapagamento-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class FormaPagamentoBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'formapagamento';
    this.formEditComponent = FormaPagamentoEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [{field: 'codigo', caption: '#'},
                    {field: 'descricao',   caption: 'Descrição', filter: true},
                    {field: 'numeroparcelas', caption: 'Número de parcelas'},
                    {field: 'geraboleto', caption: 'Gera Boleto'}];
  }

  getTitle() {
    return 'Forma de Pagamento';
  }

  static pesquisa(sender: any, target: any, targetProp?: string) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'formapagamento',
        'Pesquisa de Forma de Pagamento',
        [{field: 'codigo', caption: '#'},
         {field: 'descricao',   caption: 'Descrição', filter: true},
         {field: 'numeroparcelas', caption: 'Número de parcelas'},
         {field: 'geraboleto', caption: 'Gera Boleto'}],
        target,
        targetProp,
        'codigo');
    }
  }

  private static _valuesSelect = [];
  private static _inLoadValuesSelect = false;

  private static _valuesObjectSelect = {};

  private static loadValuesSelect(http: HttpClient) {
    if (!FormaPagamentoBrowseComponent._inLoadValuesSelect) {
      FormaPagamentoBrowseComponent._inLoadValuesSelect = true;
      CMFormBrowse2Component.postSubscribeT<Array<Object>>(http, '/api/formapagamento/GetAllObject',
                    JSON.stringify({}),
              (condpag) => {
                if ((condpag.length) && (condpag.length > 0)) {
                  condpag.sort(function (a, b) {
                    if (a['descricao'] > b['descricao']) {
                      return 1;
                    }
                    if (a['descricao'] < b['descricao']) {
                      return -1;
                    }
                    return 0;
                  });
                  FormaPagamentoBrowseComponent._valuesSelect.push({
                    value: 0,
                    text: ' '
                  });
                  condpag.forEach(element => {
                    FormaPagamentoBrowseComponent._valuesObjectSelect[element['id']] = element;
                    FormaPagamentoBrowseComponent._valuesSelect.push({
                      value: element['id'],
                      text: element['descricao']
                    });
                  });
                }
                FormaPagamentoBrowseComponent._inLoadValuesSelect = false;
                return true;
              });
    }
  }

  static valuesSelect(http: HttpClient) {
    if (FormaPagamentoBrowseComponent._valuesSelect.length == 0)
    FormaPagamentoBrowseComponent.loadValuesSelect(http);
    return FormaPagamentoBrowseComponent._valuesSelect;
  }

  static valuesObjectSelect() {
    return FormaPagamentoBrowseComponent._valuesObjectSelect;
  }

  static clearValuesSelect() {
    FormaPagamentoBrowseComponent._valuesSelect = [];
    FormaPagamentoBrowseComponent._valuesObjectSelect = [];
  }

  private static _valuesQtdeParcelas = [];
  static valuesQtdeParcelas() {
    if (FormaPagamentoBrowseComponent._valuesQtdeParcelas.length == 0)
    for (let i = 0; i < FuncsGlobais.paramEmpresa.qtdeMaximaParcelas; i++) {
      const item = {
        value: (i+1).toString(),
        text: (i+1).toString()+'x'
      };
      FormaPagamentoBrowseComponent._valuesQtdeParcelas.push(item);
    }
    return FormaPagamentoBrowseComponent._valuesQtdeParcelas;
  }

}