// tslint:disable
import { Component, Injector } from "@angular/core";
import { PessoaBrowseComponent } from "../pessoa";

import {
  CMFormEditComponent,
  CMFormGlobaisComponent,
  CMInputConfig,
} from "./../../@cmcore/component";

@Component({
  selector: "relatorio-customizado-edit",
  templateUrl: "relatorio-customizado-edit.component.html",
})
export class RelatorioCustomizadoEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_paraassociado";
  }

  getTitle() {
    return "Relatório Customizado";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "RelatorioCustomizado";
    this.modelConfig = {
      paraassociado: new CMInputConfig({
        form: this,
        caption: "Associado",
        required: true,
        pesqFunc: (sender) => {
          PessoaBrowseComponent.pesquisa(
            sender,
            this.model,
            "Empresa",
            "paraassociado",
            () => this.getEmpresasAssociado()
          );
        },
      }),
      paraempresa_id: new CMInputConfig({
        form: this,
        caption: "Empresa",
        required: true,
        values: this.empresasAssociado,
        onChange: () => {
          if (this.model.paraempresa_id) {
            this.model.paraempresa = {
              id: this.model.paraempresa_id,
            };
          }
        },
      }),
      tipo: new CMInputConfig({
        form: this,
        caption: "Relatório",
        required: true,
        values: [
          { value: "EtiquetaNFeRecebimento", text: "Etiqueta NFe Recebimento" },
          { value: "EtiquetaProduto", text: "Etiqueta de cadastro de produto" },
          { value: "SegundaVia", text: "Pré-Venda duas vias" },
          { value: "PreVenda", text: "Pré-Venda - Ficha"},
          //{ value: "PreVendaListagem", text: "Pré-Venda - Impressão de Pesquisa"},
          { value: "OrdemCompra", text: "Ordem de Compra - Ficha"},
          //{ value: "OrdemCompraListagem", text: "Ordem de Compra - Impressão de Pesquisa"},
          { value: "CupomDesconto", text: "Cupom de Desconto"},
          //{ value: "DuplicataPagar", text: "Duplicatas à Pagar - Ficha"},
          //{ value: "DuplicataPagarListagem", text: "Duplicatas à Pagar - Impressão de Pesquisa"},
          { value: "DuplicataReceber", text: "Duplicatas à Receber - Ficha"},
          //{ value: "DuplicataReceberListagem", text: "Duplicatas à Receber - Impressão de Pesquisa"},
          { value: "Consignacao", text: "Consignação - Ficha"},
          //{ value: "ConsignacaoListagem", text: "Consignação - Impressão de Pesquisa"},
          { value: "Orcamento", text: "Orçamento - Ficha"},
          //{ value: "OrcamentoListagem", text: "Orçamento - Impressão de Pesquisa"},
          { value: "NFe", text: "NFe - Ficha"},
          //{ value: "NFeListagem", text: "NFe - Impressão de Pesquisa"},
        ],
      }),
      descricao: new CMInputConfig({
        form: this,
        caption: "Descrição",
        required: true,
        maxlenght: 60,
      })
    };
  }

  afterGetModelData() {
    super.afterGetModelData();
    if (this.model.paraempresa) {
      this.model.paraempresa_id = this.model.paraempresa.id;
      this.empresasAssociado.length = 0;
      Object.assign(this.empresasAssociado, [
        {
          value: this.model.paraempresa.id,
          text: this.model.paraempresa.nome,
        },
      ]);
    } else {
      this.model.novoarquivo = {
        showbutton: true,
      };
    }
    this.getEmpresasAssociado();
  }

  empresasAssociado = [];

  getEmpresasAssociado() {
    if (this.model.paraassociado) {
      CMFormGlobaisComponent.showSpinner();
      this.postSubscribe(
        "/api/Importacoes/GetEmpresasAssociado",
        JSON.stringify({ associado: this.model.paraassociado.id }),
        (result) => {
          if (result instanceof Array) {
            this.empresasAssociado.length = 0;
            Object.assign(this.empresasAssociado, result);
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("getEmpresasAssociado", error);
        }
      );
    }
  }

  mostraNovoArquivo() {
    if (this.model.novoarquivo && this.model.novoarquivo.showbutton)
      return true;
    else return this.trimValue(this.model.filename) === "";
  }

  reenviarRelatorio() {
    this.model.novoarquivo = {
      showbutton: true,
    };
  }

  getUrlRelat() {
    return (
      "/api/" +
      this.controllerName +
      "/GetDBFileGroup/" +
      this.model.id +
      "/" +
      this.model.filename +
      "/_self"
    );
  }

  onCompleteUploadFile(sender: any) {
    sender.form.model.novoarquivo = {
      keyserver: sender.keyserver,
      filename: sender.file.name,
      showbutton: true,
    };
    CMFormGlobaisComponent.hideSpinner();
  }

  onStartUploadFile(sender: any) {
    CMFormGlobaisComponent.showSpinner();
  }

  onErrorUploadFile(sender: any) {
    CMFormGlobaisComponent.hideSpinner();
  }
}
