// tslint:disable
import { Input, forwardRef, OnInit, Component } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import {
  CMInputComponent,
  CMInputComponent_props,
  CMOutputComponent_props,
} from "./cminput.component";

export const CMSELECT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CMSelectComponent),
  multi: true,
};

@Component({
  selector: "cmselect",
  templateUrl: "cmselect.component.html",
  styleUrls: ["./cminput.component.scss"],
  providers: [CMSELECT_CONTROL_VALUE_ACCESSOR],
  inputs: CMInputComponent_props,
  outputs: CMOutputComponent_props,
})
export class CMSelectComponent extends CMInputComponent implements OnInit {
  @Input()
  options = [];

  getActive() {
    return true;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.inputConfig !== null) {
      this.options = this.inputConfig.values;
    }
  }

  getDisabledOption(option) {
    let r: boolean = false;
    if (option && option.disabled !== undefined) r = option.disabled;
    if (option && option.disabledFunc !== undefined) r = option.disabledFunc();
    return r;
  }
}
