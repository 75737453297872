// tslint:disable
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import {
  CMFormGlobaisComponent,
  CMInputConfig,
} from "./../../@cmcore/component";
import { AppComponent } from "./../../app.component";
import { CMFormComponent } from "./../../@cmcore/component/cmform/cmform.component";

@Injectable()
export class AuthService {
  get logado() {
    let r: boolean = false;
    if (localStorage.getItem("currentUser")) r = true;
    if (r) {
      r = false;
      if (localStorage.getItem("token")) r = true;
    }
    return r;
  }

  private roles = [];
  isRole(role: string) {
    return this.roles.indexOf(role) > -1;
  }

  private recursos = [];
  isRecurso(recurso: number) {
    let s = recurso + "=true";
    return this.recursos.indexOf(s) > -1;
  }

  private innerCountTentativaLogin: number = 0;
  get countTentativaLogin() {
    return this.innerCountTentativaLogin;
  }

  private innerMsgRetLogin: string = "";
  get msgRetLogin() {
    return this.innerMsgRetLogin;
  }

  constructor(private http: HttpClient, private router: Router) {
    let ro = localStorage.getItem("roles");
    if (ro) {
      try {
        this.roles = JSON.parse(ro);
      } catch (e) {
        this.roles = [];
      }
    }
    let re = localStorage.getItem("recursos");
    if (re) {
      try {
        this.recursos = JSON.parse(re);
      } catch (e) {
        this.recursos = [];
      }
    }
  }

  public static routerok = ["/"];
  public static routerError = [];
  public static hash_app = "";

  checkMsgLogin(error) {
    if (error) {
      if (error.error) error = error.error;
      if (error.msg) this.innerMsgRetLogin = error.msg;
      else this.innerMsgRetLogin = error;
    }
  }

  navivateRouterOK(routerok, routerExtras, result) {
    this.innerMsgRetLogin = "";
    CMFormComponent.loadCSS_Color();
    this.roles = JSON.parse(localStorage.getItem("roles"));
    this.recursos = JSON.parse(localStorage.getItem("recursos"));
    if (CMInputConfig.trimValue(result["routerok"]) !== "")
      routerok = result["routerok"].split("/");
    this.router.navigate(routerok, routerExtras);
  }

  login(usuario, authorization?: any) {
    let routerok = AuthService.routerok;
    if (usuario["routerok"] !== undefined) {
      let r: string = usuario["routerok"];
      routerok = r.split("_");
    }
    let routerError = AuthService.routerError;
    if (usuario["routerError"] !== undefined) {
      let r: string = usuario["routerError"];
      routerError = r.split("_");
    }
    let routerExtras = undefined;
    if (usuario["queryParams"] !== undefined) {
      let r: any = {};
      Object.assign(r, usuario["queryParams"]);
      if (r.routerok) {
        let x: string = decodeURIComponent(r.routerok);
        routerok = x.split("/");
        r.routerok = undefined;
      }
      routerExtras = {
        queryParams: r,
      };
    }

    let auth: any = authorization;
    if (auth === undefined && usuario["login"] !== undefined)
      auth = "Basic " + btoa(usuario["login"] + ":" + usuario["senha"]);
    if (auth === undefined) {
      this.navivateRouterOK(routerok, routerExtras, {});
    } else {
      CMFormGlobaisComponent.showSpinner();
      let headers = new HttpHeaders({
        Authorization: auth,
        "Content-Type": "application/json",
        hash_app: AuthService.hash_app,
      });
      let options = { headers: headers };
      let _url = AppComponent.basePathApi + "/api/authenticate";
      this.http.post(_url, JSON.stringify({}), options).subscribe(
        (result) => {
          if (result["token"] !== "") {
            localStorage.setItem("currentUser", usuario["login"]);
            for (var key in result) {
              var value = result[key];
              if (value instanceof Object)
                localStorage.setItem(key, JSON.stringify(value));
              else localStorage.setItem(key, value);
            }
            if (this.logado) {
              this.navivateRouterOK(routerok, routerExtras, result);
            } else if (routerError !== []) {
              this.innerCountTentativaLogin += 1;
              this.checkMsgLogin(result);
              this.router.navigate(routerError, routerExtras);
            }
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          console.error(error);
          this.checkMsgLogin(error);
          this.innerCountTentativaLogin += 1;
          CMFormGlobaisComponent.hideSpinner();
          if (routerError !== [])
            this.router.navigate(routerError, routerExtras);
        }
      );
    }
  }

  internalLogoff() {
    localStorage.clear();
    this.innerCountTentativaLogin = 0;
    this.innerMsgRetLogin = "";
    this.roles = [];
    this.recursos = [];
    if (this.router.url.toLowerCase().indexOf("login") == -1) {
      this.router.navigate(["/login"]);
    }
  }

  logoff() {
    if (this.logado) {
      CMFormComponent.postSubscribe(
        this.http,
        "/api/logoff",
        JSON.stringify({}),
        (response) => {
          this.internalLogoff();
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          this.internalLogoff();
          CMFormGlobaisComponent.hideSpinner();
        }
      );
    }
  }
}
