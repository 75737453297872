// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMInputConfig,
  CMFormExecComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMFormGlobaisComponent,
} from "./../../@cmcore/component";
import { PessoaBrowseComponent } from "../pessoa";
import { FormaPagamentoBrowseComponent } from "../formapagamento";
import { ContaBancariaBrowseComponent } from "../conta-bancaria";

@Component({
  templateUrl: "cobranca-baixa-em-lote-component.html",
})
export class CobrancaBaixaEmLoteComponent extends CMFormExecComponent {
  private sub: any;
  private controllerName: string = "";
  title: string = "";
  titleDestinatario: string = "";
  fieldGridDestinatario: string = "";

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.sub = this.route.params.subscribe((params) => {
      if (params["tipo"]) {
        if (params["tipo"] == "receber") {
          this.title = "Baixa em lote de Duplicatas a Rebecer";
          this.controllerName = "duplicatareceber";
          this.titleDestinatario = "Cliente";
          this.fieldGridDestinatario = "cliente_nome";
        } else if (params["tipo"] == "pagar") {
          this.title = "Baixa em lote de Duplicatas a Pagar";
          this.controllerName = "duplicatapagar";
          this.titleDestinatario = "Fornecedor";
          this.fieldGridDestinatario = "destinatario_nome";
        }
        this.setfilterConfig();
      }
    });
  }

  setfilterConfig() {
    this.filterConfig = {
      filtro: {
        dataini: new CMInputConfig({
          form: this,
          required: true,
          caption: "Data Inicial",
          mask: "99/99/9999",
        }),
        datafim: new CMInputConfig({
          form: this,
          required: true,
          caption: "Data Final",
          mask: "99/99/9999",
        }),
        destinatario: new CMInputConfig({
          form: this,
          caption: () => this.titleDestinatario,
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisa(
              sender,
              this.filter.filtro,
              this.titleDestinatario,
              "destinatario"
            );
          },
        }),
        valortotalbaixa: new CMInputConfig({
          form: this,
          caption: "Valor total da Baixa",
          dataType: "double(2)",
        }),
        filtroformapagto: new CMInputConfig({
          form: this,
          caption: "Forma de pagamento",
          values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
          valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
        }),
        formapagamento: new CMInputConfig({
          form: this,
          caption: "Forma de pagamento",
          values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
          valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
        }),
        contabancaria: new CMInputConfig({
          form: this,
          caption: "Conta Bancária",
          pesqFunc: (sender) => {
            ContaBancariaBrowseComponent.pesquisa(sender, this.filter.filtro, "Conta Bancária");
            console.log('thismodel: ', this.filter.filtro);
          },
        }),
      },
      dados: {
        duplicatas: new CMGridEditConfig({
          form: this,
          required: false,
          fields: {
            duplicata: new CMInputConfig({
              form: this,
              caption: "Origem - Fatura - Duplicata",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: "input-pesquisa",
                getValue: (row) => {
                  return (
                    this.getValueObject(row, "origem") +
                    " - " +
                    this.getValueObject(row, "nfatura") +
                    " - " +
                    this.getValueObject(row, "nduplicata")
                  );
                },
                getBottomLabelColumn: (row) => {
                  if (
                    this.getValueObject(
                      this.filter.filtro.destinatario,
                      "id"
                    ) === ""
                  )
                    return this.getValueObject(row, this.fieldGridDestinatario);
                  else return "";
                },
              }),
            }),
            dvenc: new CMInputConfig({
              form: this,
              caption: "Vencimento",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: "input-pesquisa",
                width: "120px",
              }),
            }),
            baixa_valorbaixa: new CMInputConfig({
              form: this,
              caption: "Valor Baixa",
              dataType: "double(2)",
              onExit: () => this.calcValorTotal(),
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
                getFooterLabelColumn: () => this.filter.dados.total_valorbaixa,
              }),
            }),
            vsaldo: new CMInputConfig({
              form: this,
              caption: "Saldo Devido",
              dataType: "double(2)",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
              }),
            }),
            baixa_valormulta: new CMInputConfig({
              form: this,
              caption: "Valor da Multa",
              dataType: "double(2)",
              onExit: () => this.calcValorDevido(),
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
              }),
            }),
            baixa_valorjuros: new CMInputConfig({
              form: this,
              caption: "Valor Juros",
              dataType: "double(2)",
              onExit: () => this.calcValorDevido(),
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
              }),
            }),
            baixa_valordesconto: new CMInputConfig({
              form: this,
              caption: "Valor Desconto",
              dataType: "double(2)",
              onExit: () => this.calcValorDevido(),
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
                getFooterLabelColumn: () => "Total",
              }),
            }),
            baixa_valordevido: new CMInputConfig({
              form: this,
              disabled: true,
              caption: "Valor Devido",
              dataType: "double(2)",
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
                getFooterLabelColumn: () => this.filter.dados.total_valordevido,
              }),
            }),
            valor: new CMInputConfig({
              form: this,
              caption: "Valor Original",
              dataType: "double(2)",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
              }),
            }),
            baixa_observacao: new CMInputConfig({
              form: this,
              caption: "Observação",
              maxlength: 60,
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
              }),
            }),
            baixa_formapagamento: new CMInputConfig({
              form: this,
              caption: "Forma de pagamento",
              values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
              valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
              gridConfig: new CMGridEditConfigColumn({
                tipo: "select",
                onGetValue: (row) =>
                  this.getValueObject(row, "baixa_formapagamento.id"),
                onSetValue: (row, value) => {
                  this.filterConfig.dados.duplicatas.fields.baixa_formapagamento.setValueInObject(
                    row,
                    "baixa_formapagamento",
                    value
                  );
                },
              }),
            }),
            contabancaria: new CMInputConfig({
              form: this,
              caption: "Conta Bancaria",
              gridConfig: new CMGridEditConfigColumn({
                tipo: "input-pesquisa",
                getValue: (row) => {
                  return this.getValueObject(row, "contabancaria.codigo");
                },
                getBottomLabelColumn: (row) => {
                  return this.getValueObject(row, "contabancaria.descricao");
                },
              }),
              pesqFunc: (sender) => {
                ContaBancariaBrowseComponent.pesquisa(
                  sender,
                  this.filterConfig.dados.duplicatas.current,
                  "contabancaria"
                );
              },
            }),
          },
        }),
      },
    };
    this.filter.filtro.dataini = this.firstDateLastMonth();
    this.filter.filtro.datafim = this.lastDateNextMonth();
    this.filter.filtro.valortotalbaixa = "0,00";
    this.filter.dados.duplicatas = [];
    this.filter.dados.total_valordevido = "0,00";
    this.filter.dados.total_valorbaixa = "0,00";
  }

  innerExec() {
    super.innerExec();

    let soma = 0;

    this.filter.dados.duplicatas.forEach(element => {
      soma += this.valueToFloat(element.baixa_valorbaixa);
    });

    console.log(this.filter.filtro.valortotalbaixa, ' vtotalbaixa');

    if (this.filter.filtro.valortotalbaixa != this.floatToValue(soma)){
      CMFormGlobaisComponent.showConfirmation(
        "Atenção",
        "O valor da soma das baixas difere do valor total da baixa informado. Deseja continuar?",
        () => {
          this.geraBaixas();
        }
      );
    }
    else{
      this.geraBaixas();
    }

  }

  loadDuplicatas() {

    if (this.valid()) {
      CMFormGlobaisComponent.showSpinner();
      this.filter.filtro.status = "abertas";
      let filtro = {
        origem: "pesquisa",
        page: -1,
        limit: -1,
        filter: this.filter.filtro,
      };
      this.postSubscribe(
        "/api/" + this.controllerName + "/GetBrowseObjects",
        JSON.stringify(filtro),
        (ret) => {
          let duplicatas = [];
          if (ret["data"]) duplicatas = ret["data"];
          let valortotalbaixa = this.valueToFloat(
            this.filter.filtro.valortotalbaixa
          );
          let total_valorbaixa = 0;
          let total_valordevido = 0;
          duplicatas.forEach((duplicata) => {
            let vsaldo = this.valueToFloat(duplicata.vsaldo);
            let vbaixa = 0;
            if (valortotalbaixa > 0) {
              if (vsaldo < valortotalbaixa) vbaixa = vsaldo;
              else vbaixa = valortotalbaixa;
              valortotalbaixa -= vbaixa;
            }
            total_valordevido += vsaldo;
            total_valorbaixa += vbaixa;
            duplicata.baixa_valormulta = "0,00";
            duplicata.baixa_valorjuros = "0,00";
            duplicata.baixa_valordesconto = "0,00";
            duplicata.baixa_valordevido = duplicata.vsaldo;
            duplicata.baixa_valorbaixa = this.floatToValue(vbaixa);
            duplicata.baixa_observacao = "";
            duplicata.baixa_formapagamento = Object.assign(
              {},
              this.filter.filtro.formapagamento
            );
            duplicata.contabancaria = Object.assign(
              {},
              this.filter.filtro.contabancaria
            );
          });
          this.filter.dados.duplicatas = duplicatas;
          this.filter.dados.total_valorbaixa =
            this.floatToValue(total_valorbaixa);
          this.filter.dados.total_valordevido =
            this.floatToValue(total_valordevido);
          CMFormGlobaisComponent.hideSpinner();
          this.filterConfig.dados.duplicatas.fields.baixa_valorbaixa.gridConfig.background = "yellow"
          //console.log(this.filterConfig.dados);
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("loadDuplicatas", error);
        }
      );
    }
  }

  calcValorDevido() {
    let row = this.filterConfig.dados.duplicatas.current;
    if (row) {
      let vsaldo = this.valueToFloat(row.vsaldo);
      let baixa_valormulta = this.valueToFloat(row.baixa_valormulta);
      let baixa_valorjuros = this.valueToFloat(row.baixa_valorjuros);
      let baixa_valordesconto = this.valueToFloat(row.baixa_valordesconto);
      let baixa_valordevido =
        vsaldo + baixa_valormulta + baixa_valorjuros - baixa_valordesconto;
      row.baixa_valordevido = this.floatToValue(baixa_valordevido);
      this.calcValorTotal();
    }
  }

  calcValorTotal() {
    let total_valordevido = 0;
    let total_valorbaixa = 0;
    this.filter.dados.duplicatas.forEach((duplicata) => {
      total_valordevido += this.valueToFloat(duplicata.baixa_valordevido);
      total_valorbaixa += this.valueToFloat(duplicata.baixa_valorbaixa);
    });
    this.filter.dados.total_valordevido = this.floatToValue(total_valordevido);
    this.filter.dados.total_valorbaixa = this.floatToValue(total_valorbaixa);
  }

  geraBaixas() {
    CMFormGlobaisComponent.showSpinner();
    let duplicatas = [];
    this.filter.dados.duplicatas.forEach((duplicata) => {
      if (
        this.valueToFloat(duplicata.baixa_valorbaixa) >
        this.valueToFloat(duplicata.baixa_valordevido)
      )
        duplicata.baixa_valorbaixa = duplicata.baixa_valordevido;
      if (this.valueToFloat(duplicata.baixa_valorbaixa) > 0) {
        duplicatas.push({
          id: duplicata.id,
          vmulta: duplicata.baixa_valormulta,
          vjuros: duplicata.baixa_valorjuros,
          vdesconto: duplicata.baixa_valordesconto,
          vbaixa: duplicata.baixa_valorbaixa,
          observacao: duplicata.baixa_observacao,
          formapagamento: duplicata.baixa_formapagamento,
          contabancaria: duplicata.contabancaria,
        });
      }
      console.log('duplicata conta bancaria: ', duplicata.contabancaria);
    });

    if (duplicatas.length == 0) {
      CMFormGlobaisComponent.hideSpinner();
      CMFormGlobaisComponent.toastErro(
        "Ao menos uma duplicata deve possuir valor de baixa"
      );
    } else {
      this.postSubscribe(
        "/api/" + this.controllerName + "/BaixaEmLote/FuncaoExtra",
        JSON.stringify(duplicatas),
        (result) => {
          if (result["result"] === "ok") {
            this.filter.dados.duplicatas = [];
            this.filter.dados.total_valordevido = "0,00";
            this.filter.dados.total_valorbaixa = "0,00";
            CMFormGlobaisComponent.toastSucesso("Baixas geradas com sucesso.");
            this.filterConfig.dados.duplicatas.fields.baixa_valorbaixa.gridConfig.background = "unset";
          } else {
            CMFormGlobaisComponent.toastErro(result["msg"]);
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("geraBaixas", error);
        }
      );
    }
  }
}
