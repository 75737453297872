// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMInputConfig,
  CMFormExecComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMFormGlobaisComponent,
  CMCheckBoxComponent,
} from "./../../@cmcore/component";
import { CESTBrowseComponent, EmpresaPrecoBrowseComponent, FornecedorBrowseComponent, 
  GradeBrowseComponent, 
  GrupoDeProdutoBrowseComponent, 
  IBPTBrowseComponent, 
  PadraoDeOperacaoBrowseComponent, 
  ProdutoBrowseComponent, 
  SubGrupoDeProdutoBrowseComponent } from "..";
import { FuncsGlobais } from "../funcs.globais";
import { CMInput } from "src/app/@cmcore/decorator";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { element } from "protractor";

@Component({
  templateUrl: "produto-altera-em-lote-component.html",
})
export class ProdutoAlteraEmLoteComponent extends CMFormExecComponent {
  private sub: any;
  private controllerName: string = "";
  title: string = "";
  titleDestinatario: string = "";

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.sub = this.route.params.subscribe((params) => {
      this.title = "Alterar produtos em lote";
      this.controllerName = "produto";
      this.setfilterConfig();

      this.getObjectData('empresapreco',
      [
        {'field': 'empresa', 'value': this.paramEmpresa.idEmpresa},
        {'field': 'nome', 'value': this.paramEmpresa.nomePreco1},
      ],
      object => {
				this.filter.filtro.tabelaprecos = object;
      });

    });

  }

  
  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  clearCampos() {
    this.filter.filtro.valoralterarstring = "";
    this.filter.filtro.valoralterarselect = "";
    this.filter.filtro.valoralterarnumber = "";
    this.filter.filtro.valoralterarcest = "";
    this.filter.filtro.valoralterardata = "";
    this.filter.filtro.valoralterargrupo = "";
    this.filter.filtro.valoralteraribpt = "";
    this.filter.filtro.valoralterarorigem = "";
    this.filter.filtro.valoralterarpadraodeoperacao = "";
    this.filter.filtro.valoralterarsubgrupo = "";
    this.filter.filtro.valoralterartabelaprecos = "";
  }

  setfilterConfig() {
    this.filterConfig = {
      filtro: {
        tabelaprecos: new CMInputConfig({
          form: this,
          caption: "Tabela de Preço",
          pesqFunc: (sender) => {
            EmpresaPrecoBrowseComponent.pesquisa(sender, this.filter.filtro, 'tabelaprecos');
          },
          onExit: () => {
            console.log(this.filter.filtro);
          }
        }),
        descr: new CMInputConfig({
          form: this,
          caption: "Descrição do produto",
          maxlength: 60,
        }),
        ativo: new CMInputConfig({
          form: this,
          caption: "Apenas produtos ativos",
        }),
        fornecedor: new CMInputConfig({
          form: this,
          caption: "Fornecedor",
          pesqFunc: (sender) => {
            FornecedorBrowseComponent.pesquisa(sender, this.filter.filtro);
          },
        }),
        grupo: new CMInputConfig({
          form: this,
          caption: "Grupo",
          upper: true,
          visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
          pesqFunc: (sender) => {
            GrupoDeProdutoBrowseComponent.pesquisa(sender, this.filter.filtro, "grupo");
          },
        }),
        subgrupo: new CMInputConfig({
          form: this,
          caption: "Sub-Grupo",
          upper: true,
          visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
          pesqFunc: (sender) => {
            SubGrupoDeProdutoBrowseComponent.pesquisa(
              sender,
              this.filter.filtro,
              "subgrupo"
            );
          },
        }),
        ibpt: new CMInputConfig({
          form: this,
          caption: "IBPT (NCM)",
          upper: true,
          pesqFunc: (sender) => {
            IBPTBrowseComponent.pesquisa2(sender, this.filter.filtro, 'ibpt');
          },
        }),
        
        dataini: new CMInputConfig({
          form: this,
          caption: "Data Inicial",
          mask: "99/99/9999",
        }),
        datafim: new CMInputConfig({
          form: this,
          caption: "Data Final",
          mask: "99/99/9999",
        }),
         
        propriedadenova: new CMInputConfig({
          form: this,
          caption: 'Propriedade:',
          values: [
            {'value': "Preço de Compra",                 'tipo': "number"},
            {'value': "Preço de Venda",                  'tipo': "number"},
            {'value': "Valor da Última Compra",          'tipo': "number"},
            {'value': "Custos Administrativos",          'tipo': "number"},
            {'value': "Comissão",                        'tipo': "number"},
            {'value': "Lucro",                           'tipo': "number"},
            {'value': "Juros",                           'tipo': "number"},
            {'value': "Outros",                          'tipo': "number"},
            {'value': "Impostos",                        'tipo': "number"},
            {'value': "Padrão de Operação",              'tipo': "number"},
            {'value': "Unidade",                         'tipo': "string"},
            {'value': "Data de Cadastro",                'tipo': "data"  },
            {'value': "IBPT(NCM)",                       'tipo': "string"},
            {'value': "CEST",                            'tipo': "string"},
            {'value': "Origem da Mercadoria",            'tipo': "number"},
            {'value': "Grupo",                           'tipo': "string"},
            {'value': "Sub-Grupo",                       'tipo': "string"},
            {'value': "Fabricante",                      'tipo': "string"},
            {'value': "Status",                          'tipo': "select"},
          ],
          onExit: () => {
            this.tipoInput();
            this.clearCampos();
          }
        }),
        valoralterarselect: new CMInputConfig({
                                      form: this,
                                      caption: 'Status',
                                      values: [{value: 'Ativo'}, {value: 'Inativo'}]
                                    }),
        valoralterarstring: new CMInputConfig({
                                      form: this,
                                      caption: 'Valor a Alterar:',
                                      maxlength: 100,
                                    }),
        valoralterarnumber: new CMInputConfig({
                                      form: this,
                                      caption: 'Valor a Alterar:',
                                      maxlength: 100,
                                      dataType: "double(2)",
                                      }),
        /*valoralterint: new CMInputConfig({
                                      form: this,
                                      caption: 'Valor a Alterar:',
                                      maxlength: 100,
                                      dataType: "integer"
                                      }), */
        valoralterardata: new CMInputConfig({
                                      form: this,
                                      caption: 'Valor a Alterar:',
                                      maxlength: 100,
                                      mask: "99/99/9999",}),
        valoralterargrupo: new CMInputConfig({
          form: this,
          caption: "Grupo",
          upper: true,
          visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
          pesqFunc: (sender) => {
            GrupoDeProdutoBrowseComponent.pesquisa(sender, this.filter.filtro, "valoralterargrupo");
          },
        }),
        valoralterarsubgrupo: new CMInputConfig({
          form: this,
          caption: "Sub-Grupo",
          upper: true,
          visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
          pesqFunc: (sender) => {
            SubGrupoDeProdutoBrowseComponent.pesquisa(
              sender,
              this.filter.filtro,
              "valoralterarsubgrupo"
            );
          },
        }),
        valoralteraribpt: new CMInputConfig({
          form: this,
          caption: "IBPT (NCM)",
          upper: true,
          pesqFunc: (sender) => {
            IBPTBrowseComponent.pesquisa2(sender, this.filter.filtro, 'valoralteraribpt');
          },
        }),
        valoralterarcest: new CMInputConfig({
          form: this,
          caption: "CEST",
          upper: true,
          pesqFunc: (sender) => {
            CESTBrowseComponent.pesquisa2(sender, this.filter.filtro, 'valoralterarcest');
          },
        }),
        valoralterarpadraodeoperacao: new CMInputConfig({
          form: this,
          caption: "Padrão de Operação",
          pesqFunc: (sender) => {
            PadraoDeOperacaoBrowseComponent.pesquisa2(sender, this.filter.filtro, 'valoralterarpadraodeoperacao');
          },
        }),
        valoralterarorigem: new CMInputConfig({
          form: this,
          caption: "Origem da Mercadoria",
          dataType: "integer",
          maxlength: 100,
          iconButton: "help",
          execPesqOnlyOnExit: false,
          pesqFunc: (sender) => {
            CMFormGlobaisComponent.showMessage(
              "Origem da Mercadoria",
              '<div style="padding: 20px;">' +
                "<p><strong>0</strong> - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8;</p>" +
                "<p><strong>1</strong> - Estrangeira - Importação direta, exceto a indicada no código 6;</p>" +
                "<p><strong>2</strong> - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7;</p>" +
                "<p><strong>3</strong> - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%;</p>" +
                "<p><strong>4</strong> - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes;</p>" +
                "<p><strong>5</strong> - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%;</p>" +
                "<p><strong>6</strong> - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural;</p>" +
                "<p><strong>7</strong> - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural.</p>" +
                "<p><strong>8</strong> - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%</p>" +
                "</div>"
            );
          },
        }),
        valoralterartabelaprecos: new CMInputConfig({
          form: this,
          caption: "Tabela de Preço",
          pesqFunc: (sender) => {
            EmpresaPrecoBrowseComponent.pesquisa(sender, this.filter.filtro, 'valoralterartabelaprecos');
          }
        }),
      },
      dados: {
        produtos: new CMGridEditConfig({
          form: this,
          required: false,
          stickyNavBar: true,
          fields: {
            selecionado: new CMInputConfig({
              form: this,
              value: "Sim",
              gridConfig: new CMGridEditConfigColumn({
                tipo: "checkbox",
                width: "0px",
                deleteFunc: () => {
  
                }
              }),
            }),
            produto: new CMInputConfig({
              form: this,
              caption: 'Produto',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                width: "500px",
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'descr');
                },
                getBottomLabelColumn: (row) => {
                  return this.getValueObject(row, 'codigo');
                }
              })
            }),
            precocompra: new CMInputConfig({
              form: this,
              caption: 'Preço de Compra',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'precocompra');
                },
              })
            }),
            precovenda: new CMInputConfig({
              form: this,
              caption: 'Preço de Venda',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'precovenda');
                },
              })
            }),
            valorultimacompra: new CMInputConfig({
              form: this,
              caption: 'Valor Última Compra',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'vultimacompra');
                },
              })
            }),
            custoadm: new CMInputConfig({
              form: this,
              caption: '% Custos ADM.',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'custoadm');
                },
              })
            }),
            comissao: new CMInputConfig({
              form: this,
              caption: '% Comissão',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'comissao');
                },
              })
            }),
            lucro: new CMInputConfig({
              form: this,
              caption: '% Lucro',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'lucro');
                },
              })
            }),
            juros: new CMInputConfig({
              form: this,
              caption: '% Juros',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'juros');
                },
              })
            }),
            outros: new CMInputConfig({
              form: this,
              caption: '% Outros',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'outros');
                },
              })
            }),
            impostos: new CMInputConfig({
              form: this,
              caption: '% Impostos',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'impostos');
                },
              })
            }),
            padraodeoperacao: new CMInputConfig({
              form: this,
              caption: 'Padrão de Op.',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'padraodeoperacao');
                },
              })
            }),
            un: new CMInputConfig({
              form: this,
              caption: 'Unidade',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'un');
                },
              })
            }),
            datacadastro: new CMInputConfig({
              form: this,
              caption: 'Data de Cadastro',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'cadastro');
                },
              })
            }),
            ncm: new CMInputConfig({
              form: this,
              caption: 'IBPT(NCM)',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'ibpt');
                },
              })
            }),
            cest: new CMInputConfig({
              form: this,
              caption: 'CEST',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                width: '100px',
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'cest');
                },
              })
            }),
            origem: new CMInputConfig({
              form: this,
              caption: 'Origem',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'origem');
                },
              })
            }),
            grupo: new CMInputConfig({
              form: this,
              caption: 'Grupo',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                width: "150px",
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'grupo');
                },
              })
            }),
            subgrupo: new CMInputConfig({
              form: this,
              caption: 'Sub-Grupo',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                width: "150px",
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'subgrupo');
                },
              })
            }),
            fabricante: new CMInputConfig({
              form: this,
              caption: 'Fabricante',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                width: "150px",
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'codigofabricante');
                },
              })
            }),
          }
        })
      },
    };
    this.filter.apenasprodutoscomsaldo = "Não";
    this.filter.ativo = "Não";
    this.filter.dados.produtos = [];
  }

  valorAlterarVazio() {
    let valido = true;
    switch(this.filter.filtro.propriedadenova){
      case "Preço de Compra": {
        if (this.filter.filtro.valoralterarnumber.trim().length === 0) {
          valido = false;
        }
        break;
      }
      case "Preço de Venda": {
        if (this.filter.filtro.valoralterarnumber.trim().length === 0 ||  this.filter.filtro.valoralterartabelaprecos.length === 0 ) {
          valido = false;
        }
        break;
      }
      case "Grupo": {
        if (this.filter.filtro.valoralterargrupo.length === 0) {
          valido = false;
        }
        break;
      }
      case "Sub-Grupo": {
        if (this.filter.filtro.valoralterarsubgrupo.length === 0) {
          valido = false;
        }
        break;
      }
      case "Fabricante": {
        if (this.filter.filtro.valoralterarstring.trim().length === 0) {
          valido = false;
        }
        break;
      }
      case "Unidade": {
        if (this.filter.filtro.valoralterarstring.trim().length === 0) {
          valido = false;
        }
        break;
      }
      case "Data de Cadastro": {
        if (this.filter.filtro.valoralterardata.trim().length === 0) {
          valido = false;
        }
        break;
      }
      case "Valor da Última Compra": {
        if (this.filter.filtro.valoralterarnumber.trim().length === 0) {
          valido = false;
        }
        break;
      }
      
      case "IBPT(NCM)": {
        if (this.filter.filtro.valoralteraribpt.length === 0) {
          valido = false;
        }
        break;
      }
      case "CEST": {
        if (this.filter.filtro.valoralterarcest.length === 0) {
          valido = false;
        }
        break;
      }
      case "Origem da Mercadoria": {
        if (this.filter.filtro.valoralterarorigem.trim().length === 0) {
          valido = false;
        }
        break;
      }
      case "Padrão de Operação": {
        if (this.filter.filtro.valoralterarpadraodeoperacao.length === 0) {
          valido = false;
        }
        break;
      }

      case "Custos Administrativos": {
        if (this.filter.filtro.valoralterarnumber.trim().length === 0 || this.filter.filtro.valoralterartabelaprecos.length === 0 ) {
          valido = false;
        }
        break;
      }
      
      case "Comissão": {
        if (this.filter.filtro.valoralterarnumber.trim().length === 0 || this.filter.filtro.valoralterartabelaprecos.length === 0 ) {
          valido = false;
        }
        break;
      }
      
      case "Impostos": {
        if (this.filter.filtro.valoralterarnumber.trim().length === 0 || this.filter.filtro.valoralterartabelaprecos.length === 0 ) {
          valido = false;
        }
        break;
      }
      
      case "Outros": {
        if (this.filter.filtro.valoralterarnumber.trim().length === 0 || this.filter.filtro.valoralterartabelaprecos.length === 0 ) {
          valido = false;
        }
        break;
      }
      
      case "Juros": {
        if (this.filter.filtro.valoralterarnumber.trim().length === 0 || this.filter.filtro.valoralterartabelaprecos.length === 0 ) {
          valido = false;
        }
        break;
      }
      
      case "Lucro": {
        if (this.filter.filtro.valoralterarnumber.trim().length === 0 || this.filter.filtro.valoralterartabelaprecos.length === 0 ) {
          valido = false;
        }
        break;
      }
    }

    let shouldSkip = false;
    this.filter.dados.produtos.forEach(v => {
      if (shouldSkip) {
        return;
      }
      if (v.selecionado === 'Sim') {
        shouldSkip = true;
        return;
      }
      console.log('v: ', v.selecionado);
    });
    
    if (!shouldSkip){
      valido = false;
    }

    return valido;
  }

  checkSelect(prod) {
    return prod.selecionado === 'Sim';
  }

  innerExec() {
    super.innerExec();
    console.log(this.filter.filtro)
    console.log(this.valorAlterarVazio());
    if (this.filter.dados.produtos.length === 0 || !this.valorAlterarVazio()){
      CMFormGlobaisComponent.hideSpinner();
      CMFormGlobaisComponent.toastErro(
        "Ao menos um produto deve possuir um valor a alterar"
      );
    }
    else{
      this.alteraProdutos();
    }
  }

  tipoTabelaPrecos(){
    if (
        this.filter.filtro.propriedadenova === 'Preço de Venda'         ||
        this.filter.filtro.propriedadenova === 'Custos Administrativos' ||
        this.filter.filtro.propriedadenova === 'Comissão'               ||
        this.filter.filtro.propriedadenova === 'Impostos'               ||
        this.filter.filtro.propriedadenova === 'Outros'                 ||
        this.filter.filtro.propriedadenova === 'Juros'                  ||
        this.filter.filtro.propriedadenova === 'Lucro'                 ){
          return true
    }
    else {
      return false
    }
  }

  tipoInput(){

    if (
        this.filter.filtro.propriedadenova === 'Saldo'                  ||
        this.filter.filtro.propriedadenova === 'Preço de Venda'         ||
        this.filter.filtro.propriedadenova === 'Preço de Compra'        ||
        this.filter.filtro.propriedadenova === 'Valor da Última Compra' ||
        this.filter.filtro.propriedadenova === 'Custos Administrativos' ||
        this.filter.filtro.propriedadenova === 'Comissão'               ||
        this.filter.filtro.propriedadenova === 'Impostos'               ||
        this.filter.filtro.propriedadenova === 'Outros'                 ||
        this.filter.filtro.propriedadenova === 'Juros'                  ||
        this.filter.filtro.propriedadenova === 'Lucro'                 ){
      return "number"          
    };

    if (this.filter.filtro.propriedadenova === 'Padrão de Operação') {
      return "padraodeoperacao"
    };

    if (this.filter.filtro.propriedadenova === 'Origem da Mercadoria'){
      return "origem"
    };

    if (this.filter.filtro.propriedadenova === 'Fabricante'             ||
        this.filter.filtro.propriedadenova === 'Unidade'                ){
      return "string"          
    };

    if (this.filter.filtro.propriedadenova === 'Status'){
      return "select"
    };

    if (this.filter.filtro.propriedadenova === 'IBPT(NCM)' ){
      return "ibpt"
    };
    if (this.filter.filtro.propriedadenova === 'CEST' ){
      return "cest"
    };

    if (this.filter.filtro.propriedadenova === 'Grupo'){
      return "grupo"
    };
    if (this.filter.filtro.propriedadenova === 'Sub-Grupo'){
      return "subgrupo"
    };

    if (this.filter.filtro.propriedadenova === 'Data de Cadastro'){
      return "data"          
    };

  }

  tipoInputPromo(){

    if (this.filter.filtro.promocao === 'Valor'){
      return "number"          
    };
    if (this.filter.filtro.promocao === 'Nome'){
      return "string"          
    };

    if (this.filter.filtro.promocao === 'Data Inicial' ||
        this.filter.filtro.promocao === 'Data Final'
    ){
      return "data"          
    };
    
    if (this.filter.filtro.promocao === 'Origem'){
      return "origem"          
    };

  }

  alteraProdutos(){
    let produtos = [];
    this.filter.dados.produtos.forEach((produto) => {
      
      if (produto.selecionado === 'Sim'){
        switch(this.filter.filtro.propriedadenova){
          case "Saldo": {
            produtos.push({
              id: produto.id,
              saldo: this.filter.filtro.valoralterar
            });

            break;
          }
          case "Preço de Compra": {
            produtos.push({
              id: produto.id,
              precocompra: this.filter.filtro.valoralterarnumber
            });
            break;
          }
          case "Preço de Venda": {
            produtos.push({
              id: produto.id,
              precovenda: this.filter.filtro.valoralterarnumber,
              tabelaprecos: this.filter.filtro.valoralterartabelaprecos.id
            });
            break;
          }
          case "Grupo": {
            produtos.push({
              id: produto.id,
              grupo: this.filter.filtro.valoralterargrupo.id
            });
            break;
          }
          case "Sub-Grupo": {
            produtos.push({
              id: produto.id,
              subgrupo: this.filter.filtro.valoralterarsubgrupo.id
            });
            break;
          }
          case "Fabricante": {
            produtos.push({
              id: produto.id,
              fabricante: this.filter.filtro.valoralterarstring
            });
            break;
          }
          case "Unidade": {
            produtos.push({
              id: produto.id,
              unidade: this.filter.filtro.valoralterarstring
            });
            break;
          }
          case "Data de Cadastro": {
            produtos.push({
              id: produto.id,
              datacadastro: this.filter.filtro.valoralterardata
            });
            break;
          }
          case "Valor da Última Compra": {
            produtos.push({
              id: produto.id,
              valorultimacompra: this.filter.filtro.valoralterarnumber
            });
            break;
          }
          
          case "IBPT(NCM)": {
            produtos.push({
              id: produto.id,
              ncm: this.filter.filtro.valoralteraribpt.id
            });
            break;
          }
          case "CEST": {
            produtos.push({
              id: produto.id,
              cest: this.filter.filtro.valoralterarcest.id
            });
            break;
          }
          case "Origem da Mercadoria": {
            produtos.push({
              id: produto.id,
              origem: this.filter.filtro.valoralterarorigem
            })
            break;
          }
          case "Padrão de Operação": {
            produtos.push({
              id: produto.id,
              padraodeoperacao: this.filter.filtro.valoralterarpadraodeoperacao.id
            });
            break;
          }
          case "Status": {
            produtos.push({
              id: produto.id,
              ativo: this.filter.filtro.valoralterarselect
            })
          }

          case "Custos Administrativos": {
            produtos.push({
              id: produto.id,
              custosadm: this.filter.filtro.valoralterarnumber,
              tabelaprecos: this.filter.filtro.valoralterartabelaprecos.id
            });
            break;
          }
          
          case "Comissão": {
            produtos.push({
              id: produto.id,
              comissao: this.filter.filtro.valoralterarnumber,
              tabelaprecos: this.filter.filtro.valoralterartabelaprecos.id
            });
            break;
          }
          
          case "Impostos": {
            produtos.push({
              id: produto.id,
              impostos: this.filter.filtro.valoralterarnumber,
              tabelaprecos: this.filter.filtro.valoralterartabelaprecos.id
            });
            break;
          }
          
          case "Outros": {
            produtos.push({
              id: produto.id,
              outros: this.filter.filtro.valoralterarnumber,
              tabelaprecos: this.filter.filtro.valoralterartabelaprecos.id
            });
            break;
          }
          
          case "Juros": {
            produtos.push({
              id: produto.id,
              juros: this.filter.filtro.valoralterarnumber,
              tabelaprecos: this.filter.filtro.valoralterartabelaprecos.id
            });
            break;
          }
          
          case "Lucro": {
            produtos.push({
              id: produto.id,
              lucro: this.filter.filtro.valoralterarnumber,
              tabelaprecos: this.filter.filtro.valoralterartabelaprecos.id
            });
            break;
          }
        }
      }
    })

    console.log(produtos);
    
    this.postSubscribe("/api/" + this.controllerName + "/AlteraEmLotesProdutos/FuncaoExtra", 
      JSON.stringify(produtos),(ret) => {
        let produtos = [];
        if (ret["data"]) produtos = ret["data"];
        this.filter.dados.produtos = produtos;
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.toastSucesso("Produtos alterados com sucesso.");
      },
      error => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP('loadProdutos', error);
      }
    );
    

    console.log(produtos);

  }


  copiaProdutoFiscal() {
    return (
      this.paramEmpresa.usaCopiaProduto && this.paramEmpresa.copiaProdutoFiscal
    );
  }


  loadProdutos() {

    if (this.valid()) {
        CMFormGlobaisComponent.showSpinner();

        
        let filtro = {
          origem: "pesquisa",
          page: -1,
          limit: -1,
          filter: this.filter.filtro,
        };
        console.log('filtro ', filtro);

        this.postSubscribe(
            "/api/produto/getbrowseobjects",
            JSON.stringify(filtro), 
            (ret) => {
              let produtos = [];
              if (ret["data"]) produtos = ret["data"];
              this.filter.dados.produtos = produtos;
              if (produtos.length === 0){
                CMFormGlobaisComponent.toastErro(
                  "Nenhum produto encontrado."
                );
              }
              CMFormGlobaisComponent.hideSpinner();
            },
            error => {
              CMFormGlobaisComponent.hideSpinner();
              this.showMessageErrorHTTP('loadProdutos', error);
            });
    }
  }
  
}
