// tslint:disable
import { Component, Injector } from '@angular/core';
import { CategoriaContaContabilBrowse } from '..';

import { CMFormEditComponent, CMInputConfig, CMFormGlobaisComponent
       } from './../../@cmcore/component';
import { ContaContabilBrowseComponent } from './conta-contabil-browse.component';
@Component({
    selector: 'conta-contabil-edit',
    templateUrl: 'conta-contabil-edit.component.html',
})
export class ContaContabilEditComponent extends CMFormEditComponent {

    getFirstComponentName() {
      return 'model_descricao';
    }
  
    getTitle() {
      return 'Cadastro de Conta Contabil';
    }
  
    constructor(_injector: Injector) {
      super(_injector);
      this.controllerName = 'ContaContabil';
      this.setModelConfig();
    }

    setModelConfig() {
      this.modelConfig = {
        codigo: new CMInputConfig({form: this,
                                   caption: '#',
                                   disabled: true}),
        descricao: new CMInputConfig({form: this,
                                      caption: 'Descrição',
                                      maxlength: 100,
                                      required: true}),

        entsai: new CMInputConfig({form: this,
                                      caption: 'Conta de:',
                                      values: [
                                        {'value': "Pagamento"},
                                        {'value': "Recebimento"},
                                      ],
                                      maxlength: 100,
                                      required: true}),
                              
        provisao: new CMInputConfig({form: this,
                                      caption: 'Orçamento Previsto',
                                      dataType: 'double(2)',
                                      required: true}),
        especial: new CMInputConfig({form: this,
                                      caption: 'Conta Especial: Possível bloquear visualização por nível de acesso',
                                      maxlength: 100,}),
        tipo: new CMInputConfig({
                                        form: this,
                                        caption: 'Tipo de Conta - DRE',
                                        values: [
                                          {value: "DespesaFixa", text: "Despesa Fixa"},
                                          {value: "CustoVariavel", text: "Custo Variável"},
                                          {value: "ReceitaRecorrente", text: "Receita Recorrente"},
                                          {value: "ReceitaFinanceira", text: "Receita Financeira"},
                                          {value: "ReceitaComProdutos", text: "Receita Com Produtos"},
                                          {value: "ImpostoDeRenda", text: "Imposto de Renda"},
                                          {value: "DespesaFinanceira", text: "Despesas Financeiras"},
                                          {value: "Investimento", text: "Investimento"},
                                          {value: "CustoMercadoriaAdquirida", text: "Custo de Mercadoria Adquirida"},
                                          {value: "NaoAplicavel", text: "Não aplicável"},
                                        ],
                                      }),
        ativo: new CMInputConfig({form: this,
                                      caption: 'Ativo',
                                      maxlength: 100,}),
        categoriacontacontabil: new CMInputConfig({
                                      form: this,
                                      caption: "Categoria",
                                      pesqFunc: (sender) => {
                                        CategoriaContaContabilBrowse.pesquisa(sender, this.model, "Categoria de Conta Contábil");
                                      },
        }),
                      };
    }
  
}  
