// tslint:disable
import { Component, Injector, ViewChild } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
  CMPanelConfigMore,
} from "./../../@cmcore/component";
import {
  PessoaBrowseComponent,
  TransportadoraBrowseComponent,
  ProdutoBrowseComponent,
  VendedorBrowseComponent,
  FormaPagamentoBrowseComponent,
  NFeBrowseComponent,
  CFOPBrowseComponent,
  PadraoDeOperacaoEditComponent,
  MunicipioBrowseComponent,
  CupomDescontoBrowseComponent,
} from "./../index";
import { CMValidatorCNPJCPF, CMValidator } from "./../../@cmcore/validator";
import { FuncsGlobais } from "../funcs.globais";

@Component({
  selector: "nfe-edit",
  templateUrl: "nfe-edit.component.html",
})
export class NFeEditComponent extends CMFormEditComponent {
  valuesTipoPagamento = [];

  configPesqLocalEntrega: CMPanelConfigMore;

  setValuesTipoPagamento() {
    if (this.paramEmpresa.modoOperacao === "Nivel1") {
      this.valuesTipoPagamento = [
        { value: "AVista", text: "a Vista" },
        { value: "FormaPagamento", text: "Por forma de pagamento" },
      ];
    } else {
      this.valuesTipoPagamento = [
        { value: "AVista", text: "a Vista" },
        { value: "FormaPagamento", text: "Por forma de pagamento" },
        { value: "RecebimentoFuturo", text: "Recebimento Futuro" },
      ];
    }
  }

  getFirstComponentName() {
    return "model_infnfe_dest_pessoa";
  }

  getTitle() {
    let r: string = "Nota Fiscal Eletrônica";
    let tmp: string = this.fieldByName("infnfe.ide.nnf");
    if (tmp !== "0") r += " - #" + tmp;
    tmp = this.fieldByName("infnfe.dest.pessoa.nome");
    if (tmp !== "") r += " - " + tmp;
    return r;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "nfe";
    this.setValuesTipoPagamento();
    this.setModelConfig();
    PessoaBrowseComponent.setValidProcSolicitaPermissaoVendaContaVencida(this, () => {
      return this.model.infnfe.tipopagamento === 'AVista'
    });
    this.configPesqLocalEntrega = new CMPanelConfigMore({
      onClick: () => this.onActionpesqLocalEntrega(),
    });
  }

  afterGetModelData() {
    super.afterGetModelData();
    if (!this.disabledAll)
      this.disabledAll = this.model.infnfe.podeeditar !== "Sim";
    this.setCFOPNovosItens();
  }

  setCFOPNovosItens() {
    if (this.model.infnfe.ide.finnfe == 'Devolucao') {
      this.model.cfopnovositenscfop = Object.assign({}, this.currentUserStorage.cfoppadraodevolucao);
    }
    else {
      this.model.cfopnovositenscfop = Object.assign({}, this.currentUserStorage.cfoppadrao);
    }
  }

  setModelConfig() {
    this.modelConfig = {
      cfopnovositenscfop: new CMInputConfig({
        form: this,
        caption: "CFOP novos itens",
        mask: "9.999",
        customvalidators: [
          new CMValidator(() => {
            return this.validCFOPAtivo();
          }),
        ],
        pesqFunc: (sender) => {
          CFOPBrowseComponent.pesquisa(
            sender,
            this.model,
            "cfopnovositenscfop",
            this.model.infnfe.ide.tpnf,
            this.model.infnfe.ide.finnfe
          );
        },
      }),
      infnfe: {
        ide: {
          tpnf: new CMInputConfig({
            form: this,
            caption: "Tipo de Operação",
            values: [{ value: "Entrada" }, { value: "Saida", text: "Saída" }],
          }),

          finnfe: new CMInputConfig({
            form: this,
            caption: "Finalidade de emissão da NF-e",
            values: [
              { value: "Normal" },
              { value: "Complementar" },
              { value: "Ajuste" },
              { value: "Devolucao", text: "Devolução" },
            ],
            onChange: () => {
              this.setCFOPNovosItens();
            }
          }),
          serie: new CMInputConfig({
            form: this,
            caption: "Série",
            dataType: "integer",
            disabledFunc: () => {
              return this.valueToInteger(this.model.id, 0) > 0;
            },
            required: true,
          }),
          nnf: new CMInputConfig({ form: this, caption: "#", disabled: true }),
          dhemi: new CMInputConfig({
            form: this,
            caption: "Data emissão",
            mask: "99/99/9999",
            required: true,
          }),
          natop: new CMInputConfig({
            form: this,
            caption: "Natureza da Operação",
            maxlength: 60,
          }),
          nfref: new CMGridEditConfig({
            form: this,
            required: false,
            caption: "Documentos Fiscais referenciados",
            onNewItem: (model) => {
              Object.assign(model, {
                refnf_cuf: 35,
                refnf_dtemissao: this.date,
                refnf_mod: "01",
                refnf_serie: 0,
                refnfp_cuf: 35,
                refnfp_dtemissao: this.date,
                refnfp_mod: "04",
                refnfp_serie: 0,
                refecf_mod: "2D",
                tipo: "NFe",
              });
            },
            extraColumns: {
              tipo: new CMInputConfig({
                form: this,
                caption: "Informações",
                gridConfig: new CMGridEditConfigColumn({
                  getValue: (row) => {
                    return this.getInfoDocumentosFiscaisReferenciados(row);
                  },
                }),
              }),
            },
            moreActions: [
              new CMGridEditConfigMore({
                hint: "Mais detalhes",
                defaultInAdd: true,
                icone: () => {
                  if (this.disabledAll) return "visibility";
                  else return "edit";
                },
              }),
            ],
            fields: {
              tipo: new CMInputConfig({
                form: this,
                caption: "Tipo de Documento",
                gridConfig: new CMGridEditConfigColumn({
                  width: "250px",
                  readOnly: true,
                }),
                values: [
                  {
                    value: "CTe",
                    text: "Conhecimento de Transporte Eletrônico",
                  },
                  { value: "ECF", text: "Cupom Fiscal" },
                  { value: "NFe", text: "Nota Fiscal Eletrônica" },
                  { value: "NF", text: "Nota Fiscal Modelo 1/1A" },
                  { value: "NFP", text: "Nota Fiscal Produtor Rural" },
                ],
              }),
              refnfe: new CMInputConfig({
                form: this,
                caption: "Chave de acesso",
                maxlength: 44,
              }),
              refnf_cuf: new CMInputConfig({
                form: this,
                caption: "Estado",
                values: [
                  { value: "12", text: "AC" },
                  { value: "27", text: "AL" },
                  { value: "16", text: "AP" },
                  { value: "13", text: "AM" },
                  { value: "29", text: "BA" },
                  { value: "23", text: "CE" },
                  { value: "53", text: "DF" },
                  { value: "32", text: "ES" },
                  { value: "52", text: "GO" },
                  { value: "21", text: "MA" },
                  { value: "51", text: "MT" },
                  { value: "50", text: "MS" },
                  { value: "31", text: "MG" },
                  { value: "41", text: "PR" },
                  { value: "25", text: "PB" },
                  { value: "15", text: "PA" },
                  { value: "26", text: "PE" },
                  { value: "22", text: "PI" },
                  { value: "33", text: "RJ" },
                  { value: "24", text: "RN" },
                  { value: "43", text: "RS" },
                  { value: "11", text: "RO" },
                  { value: "14", text: "RR" },
                  { value: "42", text: "SC" },
                  { value: "28", text: "SE" },
                  { value: "35", text: "SP" },
                  { value: "17", text: "TO" },
                ],
              }),
              refnf_dtemissao: new CMInputConfig({
                form: this,
                caption: "Data de Emissão",
                mask: "99/99/9999",
              }),
              refnf_cnpj: new CMInputConfig({
                form: this,
                caption: "CNPJ do Emitente",
                mask: "99.999.999/9999-99",
                customvalidators: [new CMValidatorCNPJCPF()],
              }),
              refnf_mod: new CMInputConfig({
                form: this,
                caption: "Modelo",
                maxlength: 2,
              }),
              refnf_serie: new CMInputConfig({
                form: this,
                caption: "Serie",
                dataType: "integer",
              }),
              refnf_nnf: new CMInputConfig({
                form: this,
                caption: "Número",
                dataType: "integer",
              }),
              refnfp_cuf: new CMInputConfig({
                form: this,
                caption: "Estado",
                values: [
                  { value: "12", text: "AC" },
                  { value: "27", text: "AL" },
                  { value: "16", text: "AP" },
                  { value: "13", text: "AM" },
                  { value: "29", text: "BA" },
                  { value: "23", text: "CE" },
                  { value: "53", text: "DF" },
                  { value: "32", text: "ES" },
                  { value: "52", text: "GO" },
                  { value: "21", text: "MA" },
                  { value: "51", text: "MT" },
                  { value: "50", text: "MS" },
                  { value: "31", text: "MG" },
                  { value: "41", text: "PR" },
                  { value: "25", text: "PB" },
                  { value: "15", text: "PA" },
                  { value: "26", text: "PE" },
                  { value: "22", text: "PI" },
                  { value: "33", text: "RJ" },
                  { value: "24", text: "RN" },
                  { value: "43", text: "RS" },
                  { value: "11", text: "RO" },
                  { value: "14", text: "RR" },
                  { value: "42", text: "SC" },
                  { value: "28", text: "SE" },
                  { value: "35", text: "SP" },
                  { value: "17", text: "TO" },
                ],
              }),
              refnfp_dtemissao: new CMInputConfig({
                form: this,
                caption: "Data de Emissão",
                mask: "99/99/9999",
              }),
              refnfp_cnpj: new CMInputConfig({
                form: this,
                caption: "CNPJ do Emitente",
                mask: "99.999.999/9999-99",
                customvalidators: [new CMValidatorCNPJCPF()],
              }),
              refnfp_cpf: new CMInputConfig({
                form: this,
                caption: "CPF do Emitente",
                mask: "999.999.999-99",
                customvalidators: [new CMValidatorCNPJCPF()],
              }),
              refnfp_ie: new CMInputConfig({
                form: this,
                caption: "IE do Emitente",
                maxlength: 18,
              }),
              refnfp_mod: new CMInputConfig({
                form: this,
                caption: "Modelo",
                maxlength: 2,
              }),
              refnfp_serie: new CMInputConfig({
                form: this,
                caption: "Série",
                dataType: "integer",
              }),
              refnfp_nnf: new CMInputConfig({
                form: this,
                caption: "Número",
                dataType: "integer",
              }),
              refcte: new CMInputConfig({
                form: this,
                caption: "Chave de acesso",
                maxlength: 44,
              }),
              refecf_mod: new CMInputConfig({
                form: this,
                caption: "Modelo",
                maxlength: 2,
              }),
              refecf_necf: new CMInputConfig({
                form: this,
                caption: "Número do Caixa",
                dataType: "integer",
              }),
              refecf_ncoo: new CMInputConfig({
                form: this,
                caption: "Número do Cupom",
                dataType: "integer",
              }),
            },
          }),
        },
        dest: {
          tipopessoa: new CMInputConfig({
            form: this,
            caption: "Tipo de Destinatário",
            disabledFunc: () => {
              return this.impostosCalculados;
            },
            onEnter: () => {
              this.onEnterTipoPessoa();
            },
            onExit: () => {
              this.onExitTipoPessoa();
            },
            values: [
              { value: "Cliente" },
              { value: "Fornecedor" },
              { value: "Empresa" },
            ],
          }),
          pessoa: new CMInputConfig({
            form: this,
            caption: "Destinatário",
            required: true,
            disabledFunc: () => {
              return (
                this.impostosCalculados ||
                this.model.infnfe.dest.tipopessoa === "Empresa"
              );
            },
            pesqFunc: (sender) => {
              PessoaBrowseComponent.pesquisa(
                sender,
                this.model.infnfe.dest,
                this.model.infnfe.dest.tipopessoa
              );
            },
          }),
          usaenderecoentrega: new CMInputConfig({
            form: this,
          }),
          armazenaenderecoentrega: new CMInputConfig({
            form: this,
            caption: "Salva endereço de entrega como:",
          }),
          identificacaoenderecoentrega: new CMInputConfig({
            form: this,
            maxlength: 30,
            requiredFunc: () =>
              this.model.infnfe.dest.armazenaenderecoentrega == "Sim",
          }),
          enderecoentrega: {
            logradouro: new CMInputConfig({
              form: this,
              caption: "Logradouro",
              maxlength: 60,
              requiredFunc: () => this.usaEnderecoEntrega(),
            }),
            numero: new CMInputConfig({
              form: this,
              caption: "Número",
              maxlength: 10,
              requiredFunc: () => this.usaEnderecoEntrega(),
            }),
            bairro: new CMInputConfig({
              form: this,
              caption: "Bairro",
              maxlength: 60,
              requiredFunc: () => this.usaEnderecoEntrega(),
            }),
            complemento: new CMInputConfig({
              form: this,
              caption: "Complemento",
              maxlength: 60,
            }),
            estado: new CMInputConfig({
              form: this,
              caption: "Estado",
              values: [
                { value: "AC" },
                { value: "AL" },
                { value: "AP" },
                { value: "AM" },
                { value: "BA" },
                { value: "CE" },
                { value: "DF" },
                { value: "ES" },
                { value: "GO" },
                { value: "MA" },
                { value: "MT" },
                { value: "MS" },
                { value: "MG" },
                { value: "PR" },
                { value: "PB" },
                { value: "PA" },
                { value: "PE" },
                { value: "PI" },
                { value: "RJ" },
                { value: "RN" },
                { value: "RS" },
                { value: "RO" },
                { value: "RR" },
                { value: "SC" },
                { value: "SE" },
                { value: "SP" },
                { value: "TO" },
              ],
            }),
            ibge: new CMInputConfig({
              form: this,
              caption: "Código do Município no IBGE",
              pesqFunc: (sender) => {
                MunicipioBrowseComponent.pesquisa(
                  this.model.infnfe.dest.enderecoentrega.estado,
                  sender,
                  this.model.infnfe.dest.enderecoentrega,
                  "ibge"
                );
              },
              requiredFunc: () => this.usaEnderecoEntrega(),
            }),
            cep: new CMInputConfig({
              form: this,
              caption: "CEP",
              mask: "99999-999",
              requiredFunc: () => this.usaEnderecoEntrega(),
            }),
          },
        },
        det: new CMGridEditConfig({
          form: this,
          objName: "det",
          caption: "Produtos",
          onNewItem: (model) => {
            Object.assign(model, {
              prod: {
                qcom: "1,00",
                vuncom: "0,00",
                vprod: "0,00",
                vdesc: "0,00",
                pdesc: "0,00",
                tipodesconto: "Percentual",
                cfop: this.getCFOPPadrao(),
              },
              estoque: [],
            });
          },
          customValidItem: (item) => {
            return this.validItem(item);
          },
          onRemoveItem: (item) => {
            this.onRemoveItem(item);
          },
          moreActions: [
            new CMGridEditConfigMore({
              hint: "Mais detalhes",
              icone: () => {
                if (this.disabledAll) return "visibility";
                else return "edit";
              },
            }),
          ],
          fields: {
            prod: {
              produto: new CMInputConfig({
                form: this,
                caption: "Produto",
                required: true,
                maxlength: 30,
                padInfo: { size: 30 },
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 1,
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "prod.produto.codigo");
                  },
                  getBottomLabelColumn: (row) => {
                    return this.getValueObject(row, "prod.produto.descr");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisa(
                    sender,
                    this.modelConfig.infnfe.det.current.prod,
                    "Ativos",
                    undefined,
                    (produto) => {
                      this.modelConfig.infnfe.det.current.prod.vuncom = ProdutoBrowseComponent.get_vUniCon(
                        produto
                      );
                      if(this.currentProdutoUsaGrade()){
                        this.modelConfig.infnfe.det.current.prod.qcom = "0,00";

                        let grades = this.modelConfig.infnfe.det.current.prod.produto.grades;

                        grades.forEach((grade) => {
                          this.modelConfig.infnfe.det.current.estoque.push({
                            "datavalidadelote": "",
                            "grade": grade,
                            "id": CMInputConfig.getRandomInt(1, 9999) * -1,
                            "numerolote": "",
                            "qcom": "0,00"
                          })
                        });
                      }
                      else{
                        this.modelConfig.infnfe.det.current.prod.qcom = "1,00";
                      };
                      this.calcTotalItem();
                      this.setInfoFiscalProduto(
                        this.modelConfig.infnfe.det.current,
                        "produto"
                      );
                    }
                  );
                },
                disabledFunc: () => {
                  return this.impostosCalculados;
                },
              }),
              qcom: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 2,
                }),
                hintButton: "Editar",
                iconButton: () => {
                  if (this.disabledAll) return "visibility";
                  else return "edit";
                },
                showButtonClear: false,
                pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
                pesqFunc: (sender) => {
                  if (
                    this.mostraDetalhesQuantidade(
                      this.modelConfig.infnfe.det.current
                    )
                  )
                    this.modelConfig.infnfe.det.extraClick(
                      new CMGridEditConfigMore({
                        index: 0,
                        title: "Detalhes da Quantidade",
                      })
                    );
                },
                onExit: () => {
                  this.calcTotalItem();
                },
                disabledFunc: () => {
                  return this.impostosCalculados;
                },
                showButtonFunc: (show, row) =>
                  this.mostraDetalhesQuantidade(row),
                required: true,
              }),
              vuncom: new CMInputConfig({
                form: this,
                caption: "Valor unitário",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 3,
                }),
                onExit: () => {
                  this.calcTotalItem();
                },
                disabledFunc: () => {
                  return this.impostosCalculados;
                },
                hintButton: "Preço",
                iconButton: "attach_money",
                dropdownValues: (row) => this.dropdownValuesVUnCom(row),
                required: true,
              }),
              vtotal: new CMInputConfig({
                form: this,
                caption: "Valor total",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 7,
                }),
                disabled: true,
                required: true,
              }),
              tipodesconto: new CMInputConfig({
                form: this,
                caption: "Tipo de desconto",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 4,
                }),
                values: [{ value: "Percentual" }, { value: "Valor" }],
                visibleFunc: () => {
                  return this.isDescontoItem();
                },
              }),
              vdesc: new CMInputConfig({
                form: this,
                caption: "Valor de desconto",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 6,
                  disabledFunc: (row) =>
                    this.getValueObject(row, "prod.tipodesconto") ===
                    "Percentual",
                }),
                onExit: () => {
                  this.calcTotalItem("v");
                },
                visibleFunc: () => {
                  return this.impostosCalculados || this.isDescontoItem();
                },
                disabledFunc: () => {
                  return (
                    this.impostosCalculados &&
                    !this.isAlteraImpostosManualmente()
                  );
                },
              }),
              pdesc: new CMInputConfig({
                form: this,
                caption: "% de desconto",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 5,
                  disabledFunc: (row) =>
                    this.getValueObject(row, "prod.tipodesconto") === "Valor",
                }),
                onExit: () => {
                  this.calcTotalItem("p");
                },
                visibleFunc: () => {
                  return this.impostosCalculados || this.isDescontoItem();
                },
                disabledFunc: () => {
                  return (
                    this.impostosCalculados &&
                    !this.isAlteraImpostosManualmente()
                  );
                },
              }),
              vfrete: new CMInputConfig({
                form: this,
                caption: "Valor do frete",
                dataType: "double(2)",
                disabledFunc: () => {
                  return !this.isAlteraImpostosManualmente();
                },
              }),
              vseg: new CMInputConfig({
                form: this,
                caption: "Valor do seguro",
                dataType: "double(2)",
                disabledFunc: () => {
                  return !this.isAlteraImpostosManualmente();
                },
              }),
              voutro: new CMInputConfig({
                form: this,
                caption: "Outras despesas acessórias",
                dataType: "double(2)",
                disabledFunc: () => {
                  return !this.isAlteraImpostosManualmente();
                },
              }),
              cfop: new CMInputConfig({
                form: this,
                caption: "CFOP",
                mask: "9.999",
                required: true,
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 8,
                  tipo: "input-pesquisa",
                  width: "130px",
                  getValue: (row) => {
                    return this.getValueObject(row, "prod.cfop.codigo");
                  },
                  getBottomLabelColumn: (row) => {
                    return this.getValueObject(row, "prod.cfop.descricao");
                  },
                }),
                disabledFunc: () => {
                  return this.impostosCalculados;
                },
                customvalidators: [
                  new CMValidator(() => {
                    return this.validCFOPAtivo();
                  }),
                ],
                pesqFunc: (sender) => {
                  CFOPBrowseComponent.pesquisa(
                    sender,
                    this.modelConfig.infnfe.det.current.prod,
                    "cfop",
                    this.model.infnfe.ide.tpnf,
                    this.model.infnfe.ide.finnfe,
                    (cfop) => {
                      this.setInfoFiscalProduto(
                        this.modelConfig.infnfe.det.current,
                        "cfop"
                      );
                    }
                  );
                },
              }),
              indtot: new CMInputConfig({
                form: this,
                caption: "Valor do Item entra no valor total da NF-e",
                disabledFunc: () => {
                  return !this.isAlteraImpostosManualmente();
                },
                values: [
                  { value: "NaoCompoeValorTotal", text: "Não" },
                  { value: "CompoeValorTotal", text: "Sim" },
                ],
              }),
            },
            imposto: {
              vtottrib: new CMInputConfig({
                form: this,
                caption: "Valor dos tributos aproximados",
                disabledFunc: () => {
                  return !this.isAlteraImpostosManualmente();
                },
                dataType: "double(2)",
              }),
              icms: {
                csosn: new CMInputConfig({
                  form: this,
                  caption: "CSOSN",
                  dataType: "integer",
                  required: true,
                  gridConfig: new CMGridEditConfigColumn({
                    ordem: 9,
                    width: "6em",
                  }),
                  disabledFunc: () => {
                    return this.impostosCalculados;
                  },
                  values: PadraoDeOperacaoEditComponent.CSOSN,
                }),
                modbc: new CMInputConfig({
                  form: this,
                  caption:
                    "Modalidade de determinação da BC do ICMS (CSOSN 900)",
                  dataType: "integer",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                vbc: new CMInputConfig({
                  form: this,
                  caption: "Base de Cálculo (CSOSN 900)",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                picms: new CMInputConfig({
                  form: this,
                  caption: "Alíquota (CSOSN 900)",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                vicms: new CMInputConfig({
                  form: this,
                  caption: "Valor do ICMS (CSOSN 900)",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                pcredsn: new CMInputConfig({
                  form: this,
                  caption:
                    "Alíquota aplicável de cálculo do crédito (Simples Nacional)",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                vcredicmssn: new CMInputConfig({
                  form: this,
                  caption:
                    "Valor crédito do ICMS que pode ser aproveitado nos termos do art. 23 da LC 123 (Simples Nacional)",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                predbc: new CMInputConfig({
                  form: this,
                  caption: "Percentual da Redução de BC (CSOSN 900)",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
              },
              icmsst: {
                modbcst: new CMInputConfig({
                  form: this,
                  caption: "Modalidade de determinação da BC do ICMS ST",
                  dataType: "integer",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                vbcst: new CMInputConfig({
                  form: this,
                  caption: "Base de Cálculo",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                predbcst: new CMInputConfig({
                  form: this,
                  caption: "Percentual da Redução de BC do ICMS ST",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                pmvast: new CMInputConfig({
                  form: this,
                  caption: "MVA",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                picmsst: new CMInputConfig({
                  form: this,
                  caption: "Alíquota",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                vicmsst: new CMInputConfig({
                  form: this,
                  caption: "Valor do ICMS",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                vbcstret: new CMInputConfig({
                  form: this,
                  caption: "Base Cálculo (CSOSN 500)",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                vicmsstret: new CMInputConfig({
                  form: this,
                  caption: "Valor ICMS (CSOSN 500)",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
              },
              pis: {
                cst: new CMInputConfig({
                  form: this,
                  caption: "CST",
                  dataType: "integer",
                  values: PadraoDeOperacaoEditComponent.CST_PIS_COFINS,
                }),
                vbc: new CMInputConfig({
                  form: this,
                  caption: "Base de Cálculo",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                ppis: new CMInputConfig({
                  form: this,
                  caption: "Alíquota",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                vpis: new CMInputConfig({
                  form: this,
                  caption: "Valor do PIS",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
              },
              cofins: {
                cst: new CMInputConfig({
                  form: this,
                  caption: "CST",
                  dataType: "integer",
                  values: PadraoDeOperacaoEditComponent.CST_PIS_COFINS,
                }),
                vbc: new CMInputConfig({
                  form: this,
                  caption: "Base de Cálculo",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                pcofins: new CMInputConfig({
                  form: this,
                  caption: "Alíquota",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
                vcofins: new CMInputConfig({
                  form: this,
                  caption: "Valor da COFINS",
                  dataType: "double(2)",
                  disabledFunc: () => {
                    return !this.isAlteraImpostosManualmente();
                  },
                }),
              },
            },
            estoque: new CMGridEditConfig({
              form: this,
              required: false,
              onRemoveItem: (item) => {
                this.onRemoveItemEstoque(item);
              },
              fields: {
                grade: new CMInputConfig({
                  form: this,
                  caption: "Grade",
                  required: true,
                  pesqOnly: true,
                  gridConfig: new CMGridEditConfigColumn({
                    tipo: "input-pesquisa",
                    getValue: (row) => {
                      return this.getValueObject(row, "grade.descricao");
                    },
                  }),
                  pesqFunc: (sender) => {
                    ProdutoBrowseComponent.pesquisaGrade(
                      sender,
                      this.modelConfig.infnfe.det.fields.estoque.current,
                      this.modelConfig.infnfe.det.current.prod.produto.id
                    );
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaGrade();
                  },
                  disabledFunc: () => {
                    return this.impostosCalculados;
                  },
                }),
                numerolote: new CMInputConfig({
                  form: this,
                  caption: "Número Lote",
                  dataType: "string",
                  maxlength: 20,
                  gridConfig: new CMGridEditConfigColumn(),
                  requiredFunc: () => {
                    return this.currentProdutoUsaLote();
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaLote();
                  },
                  pesqFunc: (sender) => {
                    ProdutoBrowseComponent.pesquisaLote(
                      sender,
                      this.modelConfig.infnfe.det.fields.estoque.current,
                      this.modelConfig.infnfe.det.current.prod.produto.id,
                      this.modelConfig.infnfe.det.fields.estoque.current.grade.id,
                      undefined,
                      (lote) => {
                        this.modelConfig.infnfe.det.fields.estoque.current.numerolote = lote.numerolote;
                        this.modelConfig.infnfe.det.fields.estoque.current.datavalidadelote = lote.datavalidadelote;
                      }
                    );
                  },
                }),
                datavalidadelote: new CMInputConfig({
                  form: this,
                  caption: "Data Validade Lote",
                  mask: "99/99/9999",
                  gridConfig: new CMGridEditConfigColumn(),
                  requiredFunc: () => {
                    return this.currentProdutoUsaValidadeLote();
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaValidadeLote();
                  },
                }),
                qcom: new CMInputConfig({
                  form: this,
                  caption: "Quantidade",
                  dataType: "double(2)",
                  gridConfig: new CMGridEditConfigColumn(),
                  onExit: () => {
                    this.calcTotalItemEstoque();
                  },
                  disabledFunc: () => {
                    return this.impostosCalculados;
                  },
                  required: true,
                }),
              },
            }),
          },
        }),
        total: {
          vtotal: new CMInputConfig({
            form: this,
            caption: "Valor total dos produtos",
            dataType: "double(2)",
            disabled: true,
            required: true,
          }),
          icmstot: {
            vbc: new CMInputConfig({
              form: this,
              caption: "Base de cálculo do ICMS",
              disabledFunc: () => {
                return !this.isAlteraImpostosManualmente();
              },
              dataType: "double(2)",
            }),
            vprod: new CMInputConfig({
              form: this,
              caption: "Valor total dos produtos",
              dataType: "double(2)",
              disabledFunc: () => {
                return !this.isAlteraImpostosManualmente();
              },
              required: true,
            }),
            vdesc: new CMInputConfig({
              form: this,
              caption: "Valor de desconto",
              dataType: "double(2)",
              disabledFunc: () => {
                let r = this.isDescontoItem();
                if (!r) r = this.model.infnfe.tipodesconto === "Percentual";
                return r;
              },
              onEnter: () => {
                this.onEnterDescTotal("v");
              },
              onExit: () => {
                this.onExitDescTotal("v");
              },
            }),
            pdesc: new CMInputConfig({
              form: this,
              caption: "% de desconto",
              dataType: "double(2)",
              disabledFunc: () => {
                let r = this.isDescontoItem();
                if (!r) r = this.model.infnfe.tipodesconto === "Valor";
                return r;
              },
              onEnter: () => {
                this.onEnterDescTotal("p");
              },
              onExit: () => {
                this.onExitDescTotal("p");
              },
            }),
            vicms: new CMInputConfig({
              form: this,
              caption: "Valor do ICMS",
              disabledFunc: () => {
                return !this.isAlteraImpostosManualmente();
              },
              dataType: "double(2)",
            }),
            vbcst: new CMInputConfig({
              form: this,
              caption: "Base de cálculo do ICMS ST",
              disabledFunc: () => {
                return !this.isAlteraImpostosManualmente();
              },
              dataType: "double(2)",
            }),
            vst: new CMInputConfig({
              form: this,
              caption: "Valor do ICMS ST",
              disabledFunc: () => {
                return !this.isAlteraImpostosManualmente();
              },
              dataType: "double(2)",
            }),
            vfrete: new CMInputConfig({
              form: this,
              caption: "Valor do frete",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.impostosCalculados;
              },
            }),
            vseg: new CMInputConfig({
              form: this,
              caption: "Valor do seguro",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.impostosCalculados;
              },
            }),
            vpis: new CMInputConfig({
              form: this,
              caption: "Valor do PIS",
              disabledFunc: () => {
                return !this.isAlteraImpostosManualmente();
              },
              dataType: "double(2)",
            }),
            vcofins: new CMInputConfig({
              form: this,
              caption: "Valor da COFINS",
              disabledFunc: () => {
                return !this.isAlteraImpostosManualmente();
              },
              dataType: "double(2)",
            }),
            voutro: new CMInputConfig({
              form: this,
              caption: "Outras despesas acessórias",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.impostosCalculados;
              },
            }),
            vnf: new CMInputConfig({
              form: this,
              caption: "Valor total",
              dataType: "double(2)",
              disabledFunc: () => {
                return !this.isAlteraImpostosManualmente();
              },
            }),
            vtottrib: new CMInputConfig({
              form: this,
              caption: "Valor dos tributos aproximados",
              disabledFunc: () => {
                return !this.isAlteraImpostosManualmente();
              },
              dataType: "double(2)",
            }),
          },
        },
        transp: {
          modfrete: new CMInputConfig({
            form: this,
            caption: "Modalidade do frete",
            values: [
              { value: "PorContaDoEmitente", text: "Por conta do emitente" },
              {
                value: "PorContaDoDestinatario",
                text: "Por conta do destinatário/remetente",
              },
              { value: "PorContaDeTerceiros", text: "Por conta de terceiros" },
              { value: "SemFrete", text: "Sem frete" },
            ],
          }),
          transportadora: new CMInputConfig({
            form: this,
            caption: "Transportadora",
            pesqFunc: (sender) => {
              TransportadoraBrowseComponent.pesquisa(
                sender,
                this.model.infnfe.transp
              );
            },
          }),
          placa: new CMInputConfig({
            form: this,
            caption: "Placa",
            maxlength: 7,
          }),
          uf_placa: new CMInputConfig({
            form: this,
            caption: "Estado",
            values: [
              { value: "AC" },
              { value: "AL" },
              { value: "AP" },
              { value: "AM" },
              { value: "BA" },
              { value: "CE" },
              { value: "DF" },
              { value: "ES" },
              { value: "GO" },
              { value: "MA" },
              { value: "MT" },
              { value: "MS" },
              { value: "MG" },
              { value: "PR" },
              { value: "PB" },
              { value: "PA" },
              { value: "PE" },
              { value: "PI" },
              { value: "RJ" },
              { value: "RN" },
              { value: "RS" },
              { value: "RO" },
              { value: "RR" },
              { value: "SC" },
              { value: "SE" },
              { value: "SP" },
              { value: "TO" },
            ],
          }),
          rntc: new CMInputConfig({
            form: this,
            caption: "RNTC / ANTT",
            maxlength: 20,
          }),
          vol: new CMGridEditConfig({
            form: this,
            required: false,
            onNewItem: (model) => {
              Object.assign(model, {
                qvol: "0",
                pesol: "0,000",
                pesob: "0,000",
              });
            },
            fields: {
              qvol: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                gridConfig: new CMGridEditConfigColumn(),
                dataType: "integer",
              }),
              esp: new CMInputConfig({
                form: this,
                caption: "Espécie",
                gridConfig: new CMGridEditConfigColumn(),
                maxlength: 60,
              }),
              marca: new CMInputConfig({
                form: this,
                caption: "Marca",
                gridConfig: new CMGridEditConfigColumn(),
                maxlength: 60,
              }),
              nvol: new CMInputConfig({
                form: this,
                caption: "Numeração",
                gridConfig: new CMGridEditConfigColumn(),
                maxlength: 60,
              }),
              pesol: new CMInputConfig({
                form: this,
                caption: "Peso Líquido (em kg)",
                gridConfig: new CMGridEditConfigColumn(),
                dataType: "double(3)",
              }),
              pesob: new CMInputConfig({
                form: this,
                caption: "Peso Bruto (em kg)",
                gridConfig: new CMGridEditConfigColumn(),
                dataType: "double(3)",
              }),
            },
          }),
        },
        pag: {
          cupomdesconto: new CMInputConfig({
            form: this,
            caption: "Cupom de Desconto",
            upper: true,
            pesqFunc: (sender) => {
              CupomDescontoBrowseComponent.pesquisa(
                "Não",
                sender,
                this.model.infnfe.pag,
                undefined,
                (cupom) => {
                  let msg;
                  msg = this.cupomValido(cupom);
                  if (this.trimValue(msg) === "") {
                    this.model.infnfe.pag.vutilizadocupom = cupom.valor;
                    this.model.infnfe.cobr.vdesconto = cupom.valor;
                    this.calcValorLiquidoDuplicatas();
                  } else {
                    this.model.infnfe.pag.cupomdesconto = "";
                    CMFormGlobaisComponent.showMessageError(msg);
                  }
                }
              );
            },
            onExit: () => {
              this.onExitCupomDesconto();
            },
          }),
          vutilizadocupom: new CMInputConfig({
            form: this,
            caption: "Valor do cupom",
            dataType: "double(2)",
            disabled: true,
          }),
        },
        cobr: {
          vdesconto: new CMInputConfig({
            form: this,
            caption: "Desconto financeiro",
            dataType: "double(2)",
            disabledFunc: () => {
              return (
                this.valueToInteger(
                  this.getValueObject(
                    this.model.model_infnfe,
                    "pag.cupomdesconto.id"
                  ),
                  0
                ) > 0
              );
            },
            onExit: () => {
              this.calcValorLiquidoDuplicatas();
            },
          }),
          vliquido: new CMInputConfig({
            form: this,
            caption: "Valor líquido",
            dataType: "double(2)",
            disabled: true,
          }),
          duplicatas: new CMGridEditConfig({
            form: this,
            caption: "Duplicatas",
            customValidItem: (item) => {
              return this.validDuplicata(item);
            },
            requiredFunc: () => {
              //return this.model.infnfe.tipopagamento === "FormaPagamento";
            },
            moreActions: [
              new CMGridEditConfigMore({
                hint: "Mais detalhes",
                icone: () => {
                  if (this.disabledAll) return "visibility";
                  else return "edit";
                },
              }),
            ],
            fields: {
              dvenc: new CMInputConfig({
                form: this,
                caption: "Data de vencimento",
                mask: "99/99/9999",
                gridConfig: new CMGridEditConfigColumn(),
                required: true,
              }),
              valor: new CMInputConfig({
                form: this,
                caption: "Valor da duplicata",
                dataType: "double(2)",
                onExit: () => this.recalcularValorParcelas(),
                gridConfig: new CMGridEditConfigColumn({
                  disabledFunc: (row) =>
                    this.getValueObject(row, "alteradomanualmente") !== "Sim",
                }),
                requiredFunc: () => {
                  return this.impostosCalculados;
                },
              }),
              alteradomanualmente: new CMInputConfig({
                form: this,
                caption: "Alterar",
                dataType: "boolean",
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "checkbox",
                  width: "60px",
                }),
              }),
              formapagamento: new CMInputConfig({
                form: this,
                caption: "Forma de pagamento",
                requiredFunc: () => {
                  return this.model.infnfe.tipopagamento === "FormaPagamento";
                },
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "select",
                  onGetValue: (row) =>
                    this.getValueObject(row, "formapagamento.id"),
                  onSetValue: (row, value) => {
                    this.modelConfig.infnfe.cobr.duplicatas.fields.formapagamento.setValueInObject(
                      row,
                      "formapagamento",
                      value
                    );
                  },
                }),
                values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
                valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
              }),
              databaixa: new CMInputConfig({
                form: this,
                caption: "Data da Baixa",
                visibleFunc: () => {
                  return this.model.infnfe.podeeditar !== "Sim";
                },
                gridConfig: new CMGridEditConfigColumn(),
                disabled: true,
              }),
              vsaldo: new CMInputConfig({
                form: this,
                caption: "Saldo",
                visibleFunc: () => {
                  return this.model.infnfe.podeeditar !== "Sim";
                },
                gridConfig: new CMGridEditConfigColumn(),
                disabled: true,
              }),
              observacao: new CMInputConfig({
                form: this,
                caption: "Observação",
              }),
            },
          }),
        },

        infadic: {
          infadfisco: new CMInputConfig({
            form: this,
            caption: "Informações adicionais de interesse do fisco",
          }),
          infcpl: new CMInputConfig({
            form: this,
            caption: "Informações Complementares de interesse do Contribuinte",
          }),
        },
        vendedor: new CMInputConfig({
          form: this,
          caption: "Vendedor",
          pesqFunc: (sender) => {
            VendedorBrowseComponent.pesquisa(sender, this.model.infnfe);
          },
        }),
        tipopagamento: new CMInputConfig({
          form: this,
          caption: "Tipo de Pagamento",
          values: this.valuesTipoPagamento,
        }),
        alteraimpostosmanualmente: new CMInputConfig({
          form: this,
          caption: "Alterar valores manualmente",
          onExit: () => {
            if (!this.isAlteraImpostosManualmente()) {
              let icmstot = this.model.infnfe.total.icmstot;
              icmstot.vdesc = "0,00";
              this.calcularImpostos(false);
            }
          },
        }),
        formapagamento: new CMInputConfig({
          form: this,
          caption: "Forma de pagamento",
          values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
          valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
        }),
        qtdeparcelas: new CMInputConfig({
          form: this,
          onSetValue: (oldValue, newValue) => {
            if (this.trimValue(oldValue) !== "" && oldValue !== newValue)
              this.gerarDuplicatas();
          },
          caption: "Condição de pagamento",
          values: FormaPagamentoBrowseComponent.valuesQtdeParcelas(),
        }),
        chaveacesso: new CMInputConfig({
          form: this,
          caption: "Chave de acesso",
          disabled: true,
        }),
        tipodesconto: new CMInputConfig({
          form: this,
          caption: "Tipo de desconto",
          values: [{ value: "Percentual" }, { value: "Valor" }],
          visibleFunc: () => {
            return !this.isDescontoItem();
          },
        }),
        descontoaplicato: new CMInputConfig({
          form: this,
          caption: "Desconto será aplicado",
          disabledFunc: () => {
            return this.impostosCalculados;
          },
          onExit: () => {
            this.zeraDesconto();
          },
          values: [
            { value: "NosItens", text: "Nos itens" },
            { value: "NoTotal", text: "No total" },
          ],
        }),
      },
    };
  }

  currentProdutoUsaValidadeLote() {
    return (
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usavalidadelote"
      ) === "Sim"
    );
  }

  validEstoque(item: any) {
    let r: string = "";
    console.log('item ', item);
    console.log('item.qcom ', item.prod.qcom);

    if (this.valueToFloat(item.prod.qcom, 0) <= 0)
      r = "Valor total deve ser maior que zero";
    return r;
  }

  onRemoveItemEstoque(item: any) {
    let itemsEstoque = this.modelConfig.infnfe.det.current;
    let qtotal = 0;
    
    itemsEstoque.estoque.forEach((est) => {
      if (item !== est){
        qtotal += this.valueToFloat(est.qcom);
      }
    });
    itemsEstoque.prod.qcom = this.floatToValue(qtotal);
    this.calcTotalItem(this.modelConfig.infnfe.det.current.prod);
  }

  onEnterDescTotal(tipo) {
    let icmstot = this.model.infnfe.total.icmstot;
    icmstot["old_" + tipo + "desc"] = icmstot[tipo + "desc"];
  }

  onExitDescTotal(tipo) {
    if (!this.isAlteraImpostosManualmente()) {
      let desc1 = "vdesc";
      let desc2 = "pdesc";
      if (tipo === "p") {
        desc1 = "pdesc";
        desc2 = "vdesc";
      }
      let icmstot = this.model.infnfe.total.icmstot;
      if (icmstot["old_" + desc1] !== icmstot[desc1]) {
        icmstot[desc2] = "0,00";
        this.calcularImpostos(false, () => {
          let icmstot2 = this.model.infnfe.total.icmstot;
          icmstot2["old_" + desc2] = icmstot2[desc2];
        });
      }
    }
  }

  isAlteraImpostosManualmente() {
    return this.model.infnfe.alteraimpostosmanualmente === "Sim";
  }

  isDescontoItem() {
    return this.model.infnfe.descontoaplicato === "NosItens";
  }

  getColumnDescontoItem(atual, inc) {
    if (this.isDescontoItem()) inc = 0;
    return atual + inc;
  }

  onRemoveItem(item) {
    this.add_vTotal(this.valueToFloat(item.prod.vtotal, 0), 0);
  }

  calc_vDescItem(item) {
    let vtotal =
      this.valueToFloat(item.qcom, 0) * this.valueToFloat(item.vuncom, 0);
    let vdesc = (vtotal * this.valueToFloat(item.pdesc, 0)) / 100;
    return this.floatToValue(vdesc);
  }

  calc_pDescItem(item) {
    let vtotal =
      this.valueToFloat(item.qcom, 0) * this.valueToFloat(item.vuncom, 0);
    let vdesc = this.valueToFloat(item.vdesc, 0);
    let pdesc = (vdesc / vtotal) * 100;
    return this.floatToValue(pdesc);
  }

  calcTotalItem(tipo = undefined) {
    this.calcTotalItem2(this.modelConfig.infnfe.det.current.prod, tipo);
  }

  calcTotalItem2(item, tipo) {
    let vdesc = this.calc_vDescItem(item);
    if (item.vdesc !== vdesc) {
      if (tipo === "v") item.pdesc = this.calc_pDescItem(item);
      else item.vdesc = vdesc;
    }
    let vtotal =
      this.valueToFloat(item.qcom, 0) * this.valueToFloat(item.vuncom, 0);
    vtotal = vtotal - this.valueToFloat(item.vdesc, 0);
    this.add_vTotal(this.valueToFloat(item.vtotal, 0), vtotal);
    item.vtotal = this.floatToValue(vtotal);
  }

  calcTotalItemEstoque() {
    let item = this.modelConfig.infnfe.det.current;
    let qtotal = 0;
    item.estoque.forEach((est) => {
      qtotal += this.valueToFloat(est.qcom);
    });
    item.prod.qcom = this.floatToValue(qtotal);
    this.calcTotalItem2(item.prod, undefined);
  }

  add_vTotal(oldValue: number, newValue: number) {
    let vtotal = this.valueToFloat(this.model.infnfe.total.vtotal, 0);
    vtotal += newValue - oldValue;
    this.model.infnfe.total.vtotal = this.floatToValue(vtotal);
  }

  validItem(item: any) {
    let r: string = "";
    console.log(item);
    if (this.valueToFloat(item.prod.vtotal, 0) <= 0)
      r = "Valor total deve ser maior que zero";
    return r;
  }

  validDuplicata(duplicata: any) {
    let r: string = "";
    if (this.impostosCalculados) {
      if (this.model.infnfe.tipopagamento === "FormaPagamento") {
        let dvenc = this.valueToDate(duplicata.dvenc);
        let data = this.valueToDate(this.model.infnfe.ide.dhemi);
        if (dvenc < data)
          r = "Data de vencimento deve ser maior que a data de emissão";
      }
    }
    return r;
  }

  onExitCupomDesconto() {
    if (
      this.valueToInteger(
        this.getValueObject(this.model.infnfe, "pag.cupomdesconto.id"),
        0
      ) <= 0
    ) {
      this.model.infnfe.cobr.vdesconto = 0;
      this.model.infnfe.pag.vutilizadocupom = 0;
      this.calcValorLiquidoDuplicatas();
    }
  }

  calcValorLiquidoDuplicatas() {
    let vliquido =
      this.valueToFloat(this.model.infnfe.total.icmstot.vnf, 0) -
      this.valueToFloat(this.model.infnfe.cobr.vdesconto, 0);
    this.model.infnfe.cobr.vliquido = this.floatToValue(vliquido);
  }

  valid() {
    let r = true;
    if (this.impostosCalculados || this.calculandoImpostos) r = super.valid();
    return r;
  }

  customValid() {
    let r = super.customValid();
    if (!this.calculandoImpostos) {
      if (r === "") {
        if (
          this.valueToFloat(this.model.infnfe.total.icmstot.vprod, 0) <=
          this.valueToFloat(this.model.infnfe.total.icmstot.vdesc, 0)
        )
          r = "Valor de desconto deve ser menor que o valor dos produtos";
      }
      if (r === "") {
        if (this.valueToFloat(this.model.infnfe.total.icmstot.vnf, 0) <= 0)
          r = "Valor total deve ser maior que zero";
      }
      if (r === "") {
        if (this.valueToFloat(this.model.infnfe.cobr.vliquido, 0) < 0)
          r = "Valor total Líquido deve ser maior ou igual a zero";
      }
      if (r === "" && this.model.infnfe.tipopagamento === "FormaPagamento") {
        let duplicatas = this.model.infnfe.cobr.duplicatas;
        let vTotalDup: number = 0;
        for (let i = 0; i < duplicatas.length; i++)
          vTotalDup += this.valueToFloat(duplicatas[i].valor);
        let vliquido = this.valueToFloat(this.model.infnfe.cobr.vliquido, 0);
        if (this.arredondar(vliquido) !== this.arredondar(vTotalDup))
          r = "Valor líquido difere do valor total das duplicatas";
      }
    }
    return r;
  }

  afterSave() {
    super.afterSave();
    if (this.impostosCalculados && this.formBrowse) {
      let form: NFeBrowseComponent = <NFeBrowseComponent>this.formBrowse;
      form.openFormAcoesNFe(this.model.id);
    }
  }

  gerarDuplicatas() {
    CMFormGlobaisComponent.showSpinner();
    let duplicatas = [];
    let numeroParcelas = this.valueToInteger(this.model.infnfe.qtdeparcelas, 0);
    if (numeroParcelas > 0) {
      let vliquido = this.valueToFloat(this.model.infnfe.cobr.vliquido, 0);
      let valorParcela = this.valueToFloat(
        this.arredondar(vliquido / numeroParcelas)
      );
      let dvenc = this.valueToDate(this.model.infnfe.ide.dhemi);
      for (let i = 0; i < numeroParcelas; i++) {
        dvenc.setMonth(dvenc.getMonth() + 1);
        duplicatas.push({
          id: CMGridEditConfig.newId(),
          dvenc: this.dateToValue(dvenc),
          valor: this.floatToValue(valorParcela),
          formapagamento: this.model.infnfe.formapagamento,
        });
      }
      let dif = this.valueToFloat(
        this.arredondar(vliquido - valorParcela * numeroParcelas)
      );
      if (dif !== 0) {
        let valor = this.valueToFloat(duplicatas[0].valor);
        valor += dif;
        duplicatas[0].valor = this.floatToValue(valor);
      }
    }
    setTimeout(() => {
      this.modelConfig.infnfe.cobr.duplicatas.unSelect();
      this.model.infnfe.cobr.duplicatas = duplicatas;
      CMFormGlobaisComponent.hideSpinner();
    }, 500);
  }

  recalcularValorParcelas() {
    let valorFixo = 0;
    let numeroParcelas = 0;
    let primeiraDuplicataAutomatica = undefined;
    this.model.infnfe.cobr.duplicatas.forEach((duplicata) => {
      if (duplicata.alteradomanualmente == "Sim")
        valorFixo += this.valueToFloat(duplicata.valor);
      else {
        if (primeiraDuplicataAutomatica == undefined)
          primeiraDuplicataAutomatica = duplicata;
        numeroParcelas += 1;
      }
    });
    if (numeroParcelas > 0) {
      let vliquido =
        this.valueToFloat(this.model.infnfe.cobr.vliquido, 0) - valorFixo;
      if (vliquido > 0) {
        let valorParcela = this.valueToFloat(
          this.arredondar(vliquido / numeroParcelas)
        );
        this.model.infnfe.cobr.duplicatas.forEach((duplicata) => {
          if (duplicata.alteradomanualmente !== "Sim")
            duplicata.valor = this.floatToValue(valorParcela);
        });
        let dif = this.valueToFloat(
          this.arredondar(vliquido - valorParcela * numeroParcelas)
        );
        if (dif !== 0) {
          let valor = this.valueToFloat(primeiraDuplicataAutomatica.valor);
          valor += dif;
          primeiraDuplicataAutomatica.valor = this.floatToValue(valor);
        }
      }
    }
  }

  isHomologacao() {
    let r: boolean = true;
    if (this.model && this.model.infnfe && this.model.infnfe.ide)
      r = this.model.infnfe.ide.tpamb === "Homologacao";
    return r;
  }

  get impostosCalculados() {
    return this.model.impostocalculado === "Sim";
  }

  set impostosCalculados(value: boolean) {
    if (value) this.model.impostocalculado = "Sim";
    else this.model.impostocalculado = "Não";
  }

  calculandoImpostos: boolean = false;
  calcularImpostos(nextPage: boolean, afterCalcula = undefined) {
    this.calculandoImpostos = true;
    if (this.valid()) {
      CMFormGlobaisComponent.showSpinner();
      this.postSubscribe(
        "/api/nfe/CalculaImposto",
        JSON.stringify(this.model),
        (result) => {
          this.unSelectItens(this.modelConfig);
          this.model = result;
          this.impostosCalculados = true;
          if (afterCalcula) afterCalcula();
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          CMFormGlobaisComponent.showMessageError(error);
        }
      );
    }
    this.calculandoImpostos = false;
  }

  liberarCalculoImposto() {
    this.impostosCalculados = false;
    let icmstot = this.model.infnfe.total.icmstot;
    icmstot.vdesc = "0,00";
    this.model.infnfe.alteraimpostosmanualmente = "Não";
  }

  zeraDesconto() {
    if (this.isDescontoItem()) {
      this.model.vdesc = "0,00";
      this.model.pdesc = "0,00";
    } else {
      this.model.infnfe.det.forEach((item) => {
        item.vdesc = "0,00";
        item.pdesc = "0,00";
        this.calcTotalItem2(item, undefined);
      });
    }
  }

  getCFOPPadrao() {
    return this.model.cfopnovositenscfop;
  }

  temPessoa() {
    return this.valueToInteger(this.fieldByName("infnfe.dest.pessoa.id")) > 0;
  }

  getInfoFiscal(item) {
    if (item.prod.cfop && item.prod.produto) {
      CMFormGlobaisComponent.showSpinner();
      this.postSubscribe(
        "/api/Produto/GetInfoFiscal",
        JSON.stringify({
          id: item.prod.produto.id,
          cfop: item.prod.cfop.codigo,
          idpessoa: this.fieldByName("infnfe.dest.pessoa.id"),
        }),
        (result) => {
          item.imposto.icms.csosn = result["icms_csosn"];
          item.imposto.pis.cst = result["pis_cst"];
          item.imposto.cofins.cst = result["cofins_cst"];
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          CMFormGlobaisComponent.showMessageError(error);
        }
      );
    }
  }

  getCFOPPessoa(item) {
    if (item.prod.cfop && item.prod.produto) {
      item.prod.produto.fiscal.padraodeoperacao
      CMFormGlobaisComponent.showSpinner();
      this.postSubscribe(
        "/api/Cliente/GetInfoFiscal",
        JSON.stringify({
          idproduto: item.prod.produto.id,
          cfop: item.prod.cfop.codigo,
          idpessoa: this.fieldByName("infnfe.dest.pessoa.id"),
        }),
        (result) => {
          if (result["cfop"]) item.prod.cfop = result["cfop"];
          CMFormGlobaisComponent.hideSpinner();
          this.getInfoFiscal(item);
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          CMFormGlobaisComponent.showMessageError(error);
        }
      );
    }
  }

  setInfoFiscalProduto(item, campoOgirem) {
    if (campoOgirem !== "cfop") {
      this.getCFOPPessoa(item);
    } else {
      this.getInfoFiscal(item);
    }
  }

  
  recarregaInformacoesFiscaisProduto() {
    CMFormGlobaisComponent.showConfirmation(
      "ATENÇÃO",
      "Este procedimento irá recarregar as informações de CFOP, CSOSN, " +
        "CST PIS e CST COFINS de acordo com o cadastro do produto e destinatário." +
        "Deseja continuar?",
      () => {
        console.log('azuredragon');
        this.model.infnfe.det.forEach((item) => {
          //if (item.prod.produto.fiscal.padraodeoperacao !== undefined ){
            console.log("item.prod.produto.fiscal.padraodeoperacao", item.prod.produto.fiscal.padraodeoperacao);
          //}
          item.prod.cfop = this.getCFOPPadrao();
          this.setInfoFiscalProduto(item, "");
        });
      }
    );
  }

  getVisibleCobranca() {
    let r: boolean = this.impostosCalculados || this.disabledAll;
    if (r) r = this.model.infnfe.ide.tpnf === "Saida";
    if (r) r = this.model.infnfe.ide.finnfe === "Normal";
    return r;
  }

  getVisibleDocumentosFiscaisReferenciados() {
    let r: boolean = this.impostosCalculados || this.disabledAll;
    if (r) r = this.model.infnfe.ide.finnfe !== "Normal";
    return r;
  }

  getInfoDocumentosFiscaisReferenciados(row) {
    let r: string = "";
    if (row.tipo === "CTe") r = row.refcte;
    else if (row.tipo === "NFP")
      r = "Série: " + row.refnfp_serie + " / Núm.: " + row.refnfp_nnf;
    else if (row.tipo === "NF")
      r = "Série: " + row.refnf_serie + " / Núm.: " + row.refnf_nnf;
    else if (row.tipo === "NFe") r = row.refnfe;
    else if (row.tipo === "ECF")
      r = "ECF: " + row.refecf_necf + " / CCO: " + row.refecf_ncoo;
    return r;
  }

  onExitTipoPessoa() {
    if (
      this.model.infnfe.dest.tipopessoa !==
      this.model.infnfe.dest.old_tipopessoa
    )
      this.model.infnfe.dest.pessoa = {};
    if (this.model.infnfe.dest.tipopessoa === "Empresa") {
      this.model.infnfe.dest.pessoa = {
        id: this.currentUserStorage["Empresa.Id"],
        codigo: this.currentUserStorage["Empresa.Codigo"],
        nome: this.currentUserStorage["Empresa.Nome"],
      };
    }
  }

  onEnterTipoPessoa() {
    this.model.infnfe.dest.old_tipopessoa = this.model.infnfe.dest.tipopessoa;
  }

  validCFOPAtivo() {
    let r: string = "";
    let item = this.modelConfig.infnfe.det.current;
    if (item && item.prod && item.prod.cfop && item.prod.cfop.ativo !== "Sim") {
      r = "CFOP Inativo. Utilize a pesquisa para verificar os CFOPs ativos.";
    }
    return r;
  }

  mostraDetalhesQuantidade(row) {
    let r = false;
    if (row && row.prod && row.prod.produto) {
      r =
        row.prod.produto.usagrade1 === "Sim" ||
        row.prod.produto.usagrade2 === "Sim" ||
        row.prod.produto.usalote === "Sim";
    }
    return r;
  }

  dropdownValuesVUnCom(row) {
    let r = [];
    if (row && row.prod && row.prod.produto)
      r = ProdutoBrowseComponent.dropdownValuesVUnCom(row.produto);
    return r;
  }

  currentProdutoUsaLote() {
    return (
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usalote"
      ) === "Sim"
    );
  }

  currentProdutoUsaGrade() {
    return (
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usagrade1"
      ) === "Sim" ||
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usagrade2"
      ) === "Sim"
    );
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  usaEnderecoEntrega() {
    return this.model.infnfe.dest.usaenderecoentrega == "Sim";
  }

  onActionpesqLocalEntrega() {
    const idPessoa = this.valueToInteger(
      this.fieldByName("infnfe.dest.pessoa.id")
    );
    if (idPessoa > 0) {
      PessoaBrowseComponent.pesquisaEnderecoEntrega(idPessoa, (row) => {
        this.model.infnfe.dest.usaenderecoentrega = "Sim";
        this.model.infnfe.dest.enderecoentrega.logradouro = row.logradouro;
        this.model.infnfe.dest.enderecoentrega.numero = row.numero;
        this.model.infnfe.dest.enderecoentrega.bairro = row.bairro;
        this.model.infnfe.dest.enderecoentrega.complemento = row.complemento;
        this.model.infnfe.dest.enderecoentrega.estado = row.estado;
        if (this.valueToInteger(row.ibge_id) > 0) {
          this.model.infnfe.dest.enderecoentrega.ibge = {
            id: row.ibge_id,
            estado: row.ibge_estado,
            codigouf: row.ibge_codigouf,
            municipio: row.ibge_municipio,
            codigomunicipio: row.ibge_codigomunicipio,
          };
        } else this.model.infnfe.dest.enderecoentrega.ibge = {};
        this.model.infnfe.dest.enderecoentrega.cep = row.cep;
        this.model.infnfe.dest.identificacaoenderecoentrega = row.identificacao;
        this.model.infnfe.dest.armazenaenderecoentrega = "Sim";
      });
    }
  }

  cupomValido(cupom) {
    let r: string = "";
    if (cupom) {
      if (cupom.datavalidade > 0 && cupom.datavalidade <= this.date)
        r = "Cupom de Desconto vencido";
      if (r && cupom.valor === 0)
        r = "Cupom de Desconto inválido ou já utilizado";
      return r;
    }
  }
}
