// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMInputConfig,
         CMFormGlobaisComponent } from './../../@cmcore/component';
import { DuplicataPagarEditComponent } from './duplicata-pagar-edit.component';
import { FornecedorBrowseComponent } from './../fornecedor/fornecedor-browse.component';
import { ContaContabilBrowseComponent } from '../conta-contabil';
import { FormaPagamentoBrowseComponent } from '../formapagamento';

@Component({
  selector: 'duplicata-pagar-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class DuplicataPagarBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'duplicatapagar';
    this.formEditComponent = DuplicataPagarEditComponent;
    this.buttonsExtra = 2;
    this.showPrintButton = true;
    this._hideShowFilters = true;
    this.columns = [{field: 'origem', caption: 'Origem',
                     filter: new CMInputConfig({
                       values: [{value: 'Cadastro', text: 'Cadastro'},
                                {value: 'NFeRecebimento', text: 'NF-e de Recebimento'},
                                {value: 'Todas'}]
                     }),
                     value: 'Todas'
                    },
                    {field: 'codigocontapagar', caption: '#',
                     filter: new CMInputConfig({
                       dataType: 'integer'
                    })},
                    {field: 'nfatura', caption: 'Fatura',
                     filter: new CMInputConfig({
                       maxlength: 56
                    })},
                    {field: 'nduplicata', caption: 'Duplicata',
                     filter: new CMInputConfig({
                       maxlength: 60
                    })},
                    {field: 'dvenc', caption: 'Data de Vencimento'},
                    { field: "datacadastro", caption: "Data de Emissão" },
                    {field: 'datainipagar', caption: 'Data de Vencimento Inicial',
                     visible: false,
                     filter: new CMInputConfig({
                       mask: '99/99/9999'
                     }),
                     value: this.firstDateLastMonth()
                    },
                    {field: 'datafimpagar', caption: 'Data de Vencimento Final',
                     visible: false,
                     filter: new CMInputConfig({
                       mask: '99/99/9999'
                     }),
                     value: this.lastDateNextMonth()
                    },
                    {
                      field: "datainipagarliquidacao",
                      caption: "Data de Baixa Inicial",
                      visible: false,
                      filter: new CMInputConfig({
                        mask: "99/99/9999",
                      }),
                      //value: this.firstDateLastMonth(),
                    },
                    {
                      field: "datafimpagarliquidacao",
                      caption: "Data de Baixa Final",
                      visible: false,
                      filter: new CMInputConfig({
                        mask: "99/99/9999",
                      }),
                      //value: this.lastDateNextMonth(),
                    },
                    {
                      field: "datacadastropagarini",
                      caption: "Data de Emissão Inicial",
                      visible: false,
                      filter: new CMInputConfig({
                        mask: "99/99/9999",
                      }),
                      //value: this.firstDateLastMonth(),
                    },
                    {
                      field: "datacadastropagarfim",
                      caption: "Data de Emissão Final",
                      visible: false,
                      filter: new CMInputConfig({
                        mask: "99/99/9999",
                      }),
                      //value: this.lastDateNextMonth(),
                    },
                    {field: 'destinatario_nome', caption: 'Fornecedor',
                     filterField: 'destinatario.id',
                     filterValue: 'destinatario.codigo',
                     fieldBottom: 'destinatario.nome',
                     filter: new CMInputConfig({
                       dataType: 'integer',
                       pesqFunc: sender => {
                         FornecedorBrowseComponent.pesquisa(sender, this.valuesFilters, 'destinatario');
                      }
                     })
                    },
                    {field: 'contacontabil_descricao', caption: 'Conta Contábil',
                     filterField: 'contacontabil.id',
                     filterValue: 'contacontabil.codigo',
                     fieldBottom: 'contacontabil.descricao',
                     filter: new CMInputConfig({
                       dataType: "integer",
                       pesqFunc: (sender) => {
                         ContaContabilBrowseComponent.pesquisa(sender, this.valuesFilters, "Conta Contábil");
                       },
                     })
                    },
                    {
                      field: "formapagamento_descricao",
                      caption: "Forma de Pagamento",
                      width: 250,
                      filterField: "formapagamento.id",
                      fieldBottom: "formapagamento.descricao",
                      filter: new CMInputConfig({
                        dataType: "integer",
                        pesqFunc: (sender) => {
                          FormaPagamentoBrowseComponent.pesquisa(sender, this.valuesFilters);
                        },
                      }),
                    },
                    {field: 'status', caption: 'Status',
                     visible: false,
                     filter: new CMInputConfig({
                       values: [{value: 'Abertas'}, {value: 'Baixadas'}, {value: 'Todas'}]
                     }),
                     value: 'Abertas'
                    },
                    {field: 'valor', caption: 'Valor'},
                    {field: 'vsaldo', caption: 'Saldo'},
                    {field: 'databaixa', caption: 'Data de baixa'}
                  ];
  }

  getTitle() {
    return 'Duplicata a Pagar';
  }

  getShowActionAdd() {
    return false;
  }

  getShowActionRemove() {
    return false;
  }

  baixaTotal() {
    this.postSubscribeT<Array<string>>('/api/'+this.controllerName.toLowerCase()+'/baixatotal/FuncaoExtra',
                  JSON.stringify({"id": this.grid.fieldByName('id')}),
            (result) => {
              let result_str = result.pop();
              if (result_str === 'ok') {
                CMFormGlobaisComponent.toastSucesso('Duplicata baixada');
                this.refresh();
                return true;
              }
              else {
                CMFormGlobaisComponent.toastErro(result_str);
                return false;
              }
            },
            error => {
              this.showMessageErrorHTTP('onPage', error);
            });
  }

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, 'BaixaTotal');
    }
    if (index === 1) {
      this.getDireitosMenuBrowseButtonsExtra(index, 'ImpressaoDuplicataPagarReduzida');
    }
  }

  buttonsExtraClick(index: number) {
    super.buttonsExtraClick(index);
    if (index === 0) {
      CMFormGlobaisComponent.showConfirmation('Baixa total', 'Deseja realizar a baixa total da duplicata?',
      () => {
        this.baixaTotal();
      })
    } else if (index === 1) {
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat(
        "/ImpressaoDuplicataPagarReduzida/FuncaoExtra",
        data,
        this.controllerName
      );
    }
  }

  buttonsExtraIcon(index: number) {
    let r: string = '';
    if (index === 0)
      r = 'attach_money';
    else if (index === 1)
      r = 'receipt';
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = '';
    if (index === 0)
      r = 'Baixa total';
    return r;
  }

  buttonsExtraVisible(index: number) {
    let r: boolean = super.buttonsExtraVisible(index);
    if (r) {
      let isCurrent: boolean = false;
      if (this.grid) {
        isCurrent = this.grid.isCurrent;
      }
      if (index === 0)
        r = isCurrent;
    }
    return r;
  }

}