// tslint:disable
import { Component, Input } from "@angular/core";

@Component({
  selector: "cmrow",
  templateUrl: "cmrow.component.html",
  styleUrls: ["./cmrow.component.scss"],
})
export class CMRowComponent {
  @Input() background: string = "";
  @Input() padding: string = "";
  @Input() paddingtop: string = "";
  @Input() marginbottom: string = "";
  @Input() margintop: string = "";
  @Input() marginleft: string = "";
  @Input() marginright: string = "";
  @Input() height: string = "";
  @Input() minHeight: string = "";
  @Input() width: string = "";
  @Input() classRow: string = "";
  @Input() textAlign: string = "";
  @Input() display: string = "";
  @Input() visible: boolean = true;
  @Input() defaultClassRow: string = "row";

  getStyle() {
    let r: string = "";
    if (this.display !== "") r += "display: " + this.display + ";";
    if (this.textAlign !== "") r += "text-align: " + this.textAlign + ";";
    if (this.height !== "") r += "height: " + this.height + ";";
    if (this.minHeight !== "") r += "min-height: " + this.minHeight + ";";
    if (this.width !== "") r += "width: " + this.width + ";";
    if (this.background !== "") r += "background: " + this.background + ";";
    if (this.padding !== "") r += "padding: " + this.padding + ";";
    if (this.paddingtop !== "") r += "padding-top: " + this.paddingtop + ";";
    if (this.marginbottom !== "")
      r += "margin-bottom: " + this.marginbottom + ";";
    if (this.marginleft !== "") r += "margin-left: " + this.marginleft + ";";
    if (this.marginright !== "") r += "margin-right: " + this.marginright + ";";
    if (this.margintop !== "") r += "margin-top: " + this.margintop + ";";
    return r;
  }
}
