// tslint:disable
import {Injector, Component} from '@angular/core';

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMFormModalComponent
} from './../../@cmcore/component';
import {
  NFCeEditComponent,
  NFCeAcoesComponent,
  ClienteBrowseComponent,
  VendedorBrowseComponent,
  FormaPagamentoBrowseComponent
} from './../index';

@Component({
  selector: 'nfce-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class NFCeBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'nfce';
    this.formEditComponent = NFCeEditComponent;
    this.buttonsExtra = 1;
    this.columns = [
      {
        field: 'nnf',
        caption: '#',
        filter: new CMInputConfig({
          dataType: 'integer'
        })
      },
      {
        field: 'dhemi',
        caption: 'Data',
        filter: new CMInputConfig({
          mask: '99/99/9999'
        })
      },
       {
        field: "hemi",
        caption: "Hora",
        filter: new CMInputConfig({
          mask: "99:99:9999",
        }),
      },
      {
        field: "serie",
        caption: "Série",
      },
      {
        field: "tpemis",
        caption: "Tipo Emissão",
      },
      {
        field: "emcont",
        caption: "Contingência em",
      },
      {
        field: 'pessoa_nome',
        caption: 'Cliente',
        filterField: 'cliente.id',
        filterValue: 'cliente.codigo',
        fieldBottom: 'cliente.nome',
        filter: new CMInputConfig({
          dataType: 'integer',
          pesqFunc: (sender) => {
            ClienteBrowseComponent.pesquisa(sender, this.valuesFilters);
          }
        })
      },
      {field: 'statusstr', caption: 'Status'},
      {
        field: 'vendedor_nome',
        caption: 'Vendedor',
        filterField: 'vendedor.id',
        filterValue: 'vendedor.codigo',
        fieldBottom: 'vendedor.nome',
        filter: new CMInputConfig({
          dataType: 'integer',
          pesqFunc: (sender) => {
            VendedorBrowseComponent.pesquisa(sender, this.valuesFilters);
          }
        })
      },
      {
        field: 'formapag_descricao',
        caption: 'Forma de Pagamento',
        filterField: 'formapagamento.id',
        fieldBottom: 'formapagamento.descricao',
        filter: new CMInputConfig({
          dataType: 'integer',
          pesqFunc: (sender) => {
            FormaPagamentoBrowseComponent.pesquisa(sender, this.valuesFilters);
          }
        })
      }
    ];
  }

  buttonsExtraClick(index: number) {
    if (index === 0) {
      if (this.grid.fieldByName('impostocalculado') === 'Sim')
        this.openFormAcoesNFe(this.grid.fieldByName('id'));
      else
        CMFormGlobaisComponent.showMessageAviso(
          'Calcule os impostos da NF-e antes de abrir o gerenciamento'
        );
    }
  }

  idFormAcoesNFe: number = 0;
  openFormAcoesNFe(id) {
    this.idFormAcoesNFe = id;
    this.beforeComponent = NFCeAcoesComponent;
    setTimeout(() => {
      CMFormModalComponent.open('formAcoesNFCe');
    }, 100);
  }

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, 'GetDadosAcoesNFCe');
    }
  }

  buttonsExtraVisible(index: number) {
    let r: boolean = super.buttonsExtraVisible(index);
    if (r) {
      let isCurrent: boolean = false;
      if (this.grid) {
        isCurrent = this.grid.isCurrent;
      }
      if (index === 0) r = isCurrent;
    }
    return r;
  }

  buttonsExtraIcon(index: number) {
    let r: string = '';
    if (index === 0) r = 'build';
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = '';
    if (index === 0) r = 'Gerenciamento';
    return r;
  }

  getTitle() {
    return 'Nota Fiscal de Consumidor Eletrônica';
  }

  static pesquisa(
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function,
    idCliente?: number,
    tipoPagamento?: string
  ) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'nfce',
        'Pesquisa de NFC-e',
        [
          {field: 'nnf', caption: '#'},
          {field: 'pessoa_nome', caption: 'Cliente', filter: true},
          {field: 'dhemi', caption: 'Data', filter: true, mask: '99/99/9999'},
          {field: "serie", caption: "Série", },
          {field: "tpemis",caption: "Tipo Emissão",},
        ],
        target,
        targetProp,
        'infnfe.ide.nnf',
        onAfterSelect,
        undefined,
        undefined,
        {
          idcliente: idCliente,
          tipopagamento: tipoPagamento,
          status: 'Autorizado'
        }
      );
    }
  }
}
