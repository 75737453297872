// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "./../../@cmcore/component/cmform/cmform.component";

import {
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMGridEditConfig,
  CMInputConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "./../../@cmcore/component";
import {
  NFeRecebimentoBrowseComponent,
  ProdutoBrowseComponent,
  ProdutoEditComponent,
} from "./../index";
@Component({
  selector: "nfe-recebimento-acoes",
  templateUrl: "nfe-recebimento-acoes.component.html",
  styleUrls: ["nfe-recebimento-acoes.component.scss"],
})
export class NFeRecebimentoAcoesComponent extends CMFormComponent {
  _owner: NFeRecebimentoBrowseComponent = undefined;
  get owner() {
    return this._owner;
  }

  dados = {
    produtosnaoencontrados: [],
    atualizafornecedor: "Sim",
  };

  config = {
    produtosnaoencontrados: new CMGridEditConfig(),
    atualizafornecedor: new CMInputConfig({
      form: this,
      caption: "Atualiza dados do Fornecedor",
    }),
  };

  podeCadastrarProduto: boolean = false;
  set owner(value) {
    this._owner = value;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.getDireitos("produto", undefined, undefined, (data) => {
      this.podeCadastrarProduto = data["Cadastrar"] === "true";
    });
    this.config.produtosnaoencontrados = new CMGridEditConfig({
      form: this,
      caption: "Produtos não cadastrados",
      moreActions: [
        new CMGridEditConfigMore({
          hint: "Novo",
          icone: "add",
          visible: (row) => {
            return this.podeCadastrarProduto;
          },
          onClick: (row) => {
            if (this.podeCadastrarProduto) {
              if (row.novo == undefined) {
                CMFormGlobaisComponent.showMessageAviso(
                  "<b>Produto já cadastrado anteriormente</b>"
                );
              } else {
                this.produtoNovoData = row.novo;
                this.produtoNovoComponent = ProdutoEditComponent;
                CMFormModalComponent.open("formAcoesNFeRecebimentoNovoProduto");
              }
            }
          },
        }),
      ],
      fields: {
        codigo: new CMInputConfig({
          form: this,
          caption: "Código no Fornecedor",
          gridConfig: new CMGridEditConfigColumn({
            width: "200px",
          }),
          readonly: true,
        }),
        descricao: new CMInputConfig({
          form: this,
          caption: "Descrição",
          gridConfig: new CMGridEditConfigColumn(),
          readonly: true,
        }),
        ean: new CMInputConfig({
          form: this,
          caption: "EAN",
          gridConfig: new CMGridEditConfigColumn({
            width: "200px",
          }),
          readonly: true,
        }),
        produto: new CMInputConfig({
          form: this,
          caption: "Seu Produto",
          maxlength: 30,
          padInfo: { size: 30 },
          gridConfig: new CMGridEditConfigColumn({
            width: "250px",
            tipo: "input-pesquisa",
            getValue: (row) => {
              return this.getValueObject(row, "produto.codigo");
            },
            getBottomLabelColumn: (row) => {
              return this.getValueObject(row, "produto.descr");
            },
          }),
          pesqFunc: (sender) => {
            ProdutoBrowseComponent.pesquisa(
              sender,
              this.config.produtosnaoencontrados.current,
              "Todos"
            );
          },
        }),
        taxaconversao: new CMInputConfig({
          form: this,
          caption: "Taxa Conversão",
          dataType: "double(2)",
          gridConfig: new CMGridEditConfigColumn({
            width: "120px",
          }),
        }),
      },
    });
  }

  nomeFornecedor() {
    if (this.dados.produtosnaoencontrados.length > 0)
      return this.dados.produtosnaoencontrados[0].pessoa_nome;
    else return "";
  }

  processaUploadServer(keyserver, fileName, sender: any) {
    let values = {
      keyserver: keyserver,
      filename: fileName,
      novosprodutosfornecedor: this.dados.produtosnaoencontrados,
      atualizafornecedor: this.dados.atualizafornecedor,
    };
    this.postSubscribe(
      "/api/nferecebimento/ImportNFeRecebimento/FuncaoExtra",
      JSON.stringify(values),
      (ret) => {
        this.dados.produtosnaoencontrados = [];
        if (ret["result"] !== "ok") {
          let msg = "<p>Erro ao enviar arquivo. Tente novamente.</p>";
          if (ret["erros"]) {
            ret["erros"].forEach((erro) => {
              msg += "<p>" + erro + "</p>";
            });
          }
          if (ret["produtosnaoencontrados"]) {
            msg +=
              "<p>Existem produtos não vinculados a este fornecedor. Vincule e tente novamente.</p>";
            this.dados.produtosnaoencontrados = ret["produtosnaoencontrados"];
          }
          CMFormGlobaisComponent.showMessageAviso(
            '<div style="padding: 20px;">' + msg
          ) + "</div>";
        } else {
          this.owner.add(undefined, (model) => {
            Object.assign(model, ret["nfe"]);
          });
          this.close("formAcoesNFeRecebimento");
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("processaUploadServer", error);
      }
    );
  }

  onCompleteUploadFile(sender: any) {
    sender.form.processaUploadServer(
      sender.keyserver,
      sender.file.name,
      sender
    );
  }

  onChangeUploadFile(sender: any) {
    sender.form.produtosnaoencontrados = [];
  }

  onStartUploadFile(sender: any) {
    CMFormGlobaisComponent.showSpinner();
  }

  onErrorUploadFile(sender: any) {
    CMFormGlobaisComponent.hideSpinner();
  }

  close(formName) {
    if (this.owner) this.owner.beforeComponent = undefined;
    CMFormModalComponent.close(formName);
  }

  public produtoNovoComponent: any = undefined;
  public produtoNovoData: any = undefined;
  public produtoNovoInstance: ProdutoEditComponent = undefined;
  public produtoNovoComponentCreatedCallback(component: Component) {
    let produtoNovo: any = component;
    produtoNovo.owner.produtoNovoInstance = produtoNovo;
    Object.assign(produtoNovo.model, produtoNovo.owner.produtoNovoData);
  }
  closeNovoProduto(formName) {
    this.produtoNovoData = undefined;
    this.produtoNovoComponent = undefined;
    this.produtoNovoInstance = undefined;
    CMFormModalComponent.close(formName);
  }
  saveNovoProduto(formName) {
    if (this.produtoNovoInstance.valid()) {
      this.produtoNovoInstance.procAfterSave = (model) => {
        this.config.produtosnaoencontrados.current.produto = {
          id: model.id,
          codigo: model.codigo,
          descr: model.descr,
        };
        this.config.produtosnaoencontrados.current.novo = undefined;
        this.closeNovoProduto(formName);
      };
      this.produtoNovoInstance.save(undefined);
    }
  }
}
