// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
  CMPanelConfigMore,
  CMFormModalComponent,
} from "./../../@cmcore/component";
import { ClienteBrowseComponent } from "./../cliente/cliente-browse.component";
import { VendedorBrowseComponent } from "./../vendedor/vendedor-browse.component";
import { ProdutoBrowseComponent } from "./../produto/produto-browse.component";
import { FormaPagamentoBrowseComponent } from "./../formapagamento/formapagamento-browse.component";
import { FuncsGlobais } from "../funcs.globais";
import { MunicipioBrowseComponent } from "../municipio";
import { PessoaBrowseComponent } from "../pessoa";
import { CupomDescontoBrowseComponent } from "../cupom-desconto";
import { SolicitaSenhaSupervisor } from "../solicita-senha-supervisor";

@Component({
  selector: "orcamento-edit",
  templateUrl: "orcamento-edit.component.html",
})
export class OrcamentoEditComponent extends CMFormEditComponent {
  valuesTipoPagamento = [];

  configPesqLocalEntrega: CMPanelConfigMore;

  setValuesTipoPagamento() {
    if (this.paramEmpresa.modoOperacao === "Nivel1") {
      this.valuesTipoPagamento = [
        { value: "AVista", text: "a Vista" },
        { value: "FormaPagamento", text: "Por forma de pagamento" },
      ];
    } else {
      this.valuesTipoPagamento = [
        { value: "AVista", text: "a Vista" },
        { value: "FormaPagamento", text: "Por forma de pagamento" },
        { value: "RecebimentoFuturo", text: "Recebimento Futuro" },
      ];
    }
  }

  getFirstComponentName() {
    return "model_cliente";
  }

  getTitle() {
    let r: string = "Orçamento";
    let tmp: string = this.fieldByName("numero");
    if (tmp !== "0") r += " - #" + tmp;
    tmp = this.fieldByName("cliente.nome");
    if (tmp !== "") r += " - " + tmp;
    return r;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "orcamento";
    this.setValuesTipoPagamento();
    this.setModelConfig();
    PessoaBrowseComponent.setValidProcSolicitaPermissaoVendaContaVencida(
      this,
      () => {
        return this.model.tipopagamento === "AVista";
      }
    );

    this.configPesqLocalEntrega = new CMPanelConfigMore({
      onClick: () => this.onActionpesqLocalEntrega(),
    });
  }

  afterGetModelData() {
    super.afterGetModelData();
  }

  setModelConfig() {
    this.modelConfig = {
      numero: new CMInputConfig({ form: this, caption: "#", disabled: true }),
      data: new CMInputConfig({
        form: this,
        caption: "Data emissão",
        mask: "99/99/9999",
        required: true,
      }),
      datavalidade: new CMInputConfig({
        form: this,
        caption: "Validade (dias)",
        required: true,
      }),
      cliente: new CMInputConfig({
        form: this,
        caption: "Cliente",
        required: true,
        pesqFunc: (sender) => {
          ClienteBrowseComponent.pesquisa(sender, this.model);
        },
      }),
      vendedor: new CMInputConfig({
        form: this,
        caption: "Vendedor",
        pesqFunc: (sender) => {
          VendedorBrowseComponent.pesquisa(sender, this.model);
        },
      }),
      observacao: new CMInputConfig({ form: this, caption: "Observação" }),
      vdespesasadicionais: new CMInputConfig({
        form: this,
        caption: "Despesas Adicionais",
        dataType: "double(2)",
        onExit: () => {
          console.log(this.model);
          let tipodesconto = "v";
          if (this.model.tipodesconto == "Percentual") {
            tipodesconto = "p";
          }
          this.calcTotalVenda(tipodesconto);
        },
      }),
      vtotal: new CMInputConfig({
        form: this,
        caption: "Valor total",
        dataType: "double(2)",
        disabled: true,
      }),
      formapagamento: new CMInputConfig({
        form: this,
        caption: "Forma de pagamento",
        values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
        valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
      }),
      qtdeparcelas: new CMInputConfig({
        form: this,
        caption: "Condição de pagamento",
        values: FormaPagamentoBrowseComponent.valuesQtdeParcelas(),
      }),
      vtotalprodutos: new CMInputConfig({
        form: this,
        caption: "Valor total dos produtos",
        dataType: "double(2)",
        disabled: true,
      }),
      tipodesconto: new CMInputConfig({
        form: this,
        caption: "Tipo de desconto",
        values: [{ value: "Percentual" }, { value: "Valor" }],
        visibleFunc: () => {
          return !this.isDescontoItem();
        },
      }),
      vdesconto: new CMInputConfig({
        form: this,
        caption: "Valor de desconto",
        dataType: "double(2)",
        disabledFunc: () => this.isDescontoPercentual(this.model),
        visibleFunc: () => {
          return !this.isDescontoItem();
        },
        onExit: () => {
          this.calcTotalVenda("v");
        },
      }),
      pdesconto: new CMInputConfig({
        form: this,
        caption: "% de desconto",
        dataType: "double(2)",
        disabledFunc: () => !this.isDescontoPercentual(this.model),
        visibleFunc: () => {
          return !this.isDescontoItem();
        },
        onExit: () => {
          this.calcTotalVenda("p");
        },
      }),
      tipopagamento: new CMInputConfig({
        form: this,
        caption: "Tipo de Pagamento",
        values: this.valuesTipoPagamento,
      }),
      descontoaplicato: new CMInputConfig({
        form: this,
        caption: "Desconto será aplicado",
        onExit: () => {
          this.zeraDesconto();
        },
        values: [
          { value: "NosItens", text: "Nos itens" },
          { value: "NoTotal", text: "No total" },
        ],
      }),
      itens: new CMGridEditConfig({
        form: this,
        onNewItem: (model) => {
          Object.assign(model, {
            quantidade: "1,00",
            vunitario: "0,00",
            vtotal: "0,00",
            pdesconto: "0,00",
            vdesconto: "0,00",
            tipodesconto: "Percentual",
            estoque: [],
          });
        },
        onRemoveItem: (item) => {
          this.onRemoveItem(item);
        },
        customValidItem: (item) => {
          return this.validItem(item);
        },
        fields: {
          produto: new CMInputConfig({
            form: this,
            caption: "Produto",
            required: true,
            maxlength: 30,
            padInfo: { size: 30 },
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "produto.codigo");
              },
              getBottomLabelColumn: (row) => {
                return this.getValueObject(row, "produto.descr");
              },
            }),
            pesqFunc: (sender) => {
              ProdutoBrowseComponent.pesquisa(
                sender,
                this.modelConfig.itens.current,
                "Ativos",
                undefined,
                (produto) => {
                  this.modelConfig.itens.current.vunitario =
                    ProdutoBrowseComponent.get_vUniCon(produto);
                  if(this.currentProdutoUsaGrade()){
                    console.log(this.modelConfig.itens.current);
                    this.modelConfig.itens.current.quantidade = "0,00";

                    let grades = this.modelConfig.itens.current.produto.grades;

                    grades.forEach((grade) => {
                      this.modelConfig.itens.current.estoque.push({
                        "datavalidadelote": "",
                        "grade": grade,
                        "id": CMInputConfig.getRandomInt(1, 9999) * -1,
                        "numerolote": "",
                        "quantidade": "0,00"
                      })
                    });
                  }
                  else{
                    this.modelConfig.itens.current.quantidade = "1,00";
                  };
                  this.calcTotalItem();
                }
              );
            },
          }),
          quantidade: new CMInputConfig({
            form: this,
            caption: "Quantidade",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn(),
            hintButton: "Editar",
            iconButton: () => {
              if (this.disabledAll) return "visibility";
              else return "edit";
            },
            showButtonClear: false,
            pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
            pesqFunc: (sender) => {
              if (this.mostraDetalhesQuantidade(this.modelConfig.itens.current))
                this.modelConfig.itens.extraClick(
                  new CMGridEditConfigMore({
                    index: 0,
                    title: "Detalhes da Quantidade",
                  })
                );
            },
            showButtonFunc: (show, row) => this.mostraDetalhesQuantidade(row),
            onExit: () => {
              this.calcTotalItem();
            },
          }),
          vunitario: new CMInputConfig({
            form: this,
            caption: "Valor unitário",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn(),
            onExit: () => {
              this.calcTotalItem();
            },
            hintButton: "Preço",
            iconButton: "attach_money",
            //dropdownValues: (row) => this.dropdownValuesVUnCom(row),
            required: true,
          }),
          tipodesconto: new CMInputConfig({
            form: this,
            caption: "Tipo de desconto",
            gridConfig: new CMGridEditConfigColumn(),
            values: [{ value: "Percentual" }, { value: "Valor" }],
            visibleFunc: () => {
              return this.isDescontoItem();
            },
          }),
          vdesconto: new CMInputConfig({
            form: this,
            caption: "Valor de desconto",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn({
              disabledFunc: (row) => this.isDescontoPercentual(row),
            }),
            visibleFunc: () => {
              return this.isDescontoItem();
            },
            onExit: () => {
              this.calcTotalItem("v");
            },
          }),
          pdesconto: new CMInputConfig({
            form: this,
            caption: "% de desconto",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn({
              disabledFunc: (row) => !this.isDescontoPercentual(row),
            }),
            visibleFunc: () => {
              return this.isDescontoItem();
            },
            onExit: () => {
              this.calcTotalItem("p");
            },
          }),
          vtotal: new CMInputConfig({
            form: this,
            caption: "Valor total",
            dataType: "double(2)",
            disabled: true,
            gridConfig: new CMGridEditConfigColumn(),
            required: true,
          }),
          estoque: new CMGridEditConfig({
            form: this,
            required: false,
            onRemoveItem: (item) => {
              this.onRemoveItemEstoque(item);
            },
            fields: {
              grade: new CMInputConfig({
                form: this,
                caption: "Grade",
                required: true,
                pesqOnly: true,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "grade.descricao");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisaGrade(
                    sender,
                    this.modelConfig.itens.fields.estoque.current,
                    this.modelConfig.itens.current.produto.id
                  );
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaGrade();
                },
              }),
              numerolote: new CMInputConfig({
                form: this,
                caption: "Número Lote",
                dataType: "string",
                maxlength: 20,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  disabledFunc: (row) => {
                    if (this.currentProdutoUsaGrade())
                      return this.getValueObject(row, "grade.id") === "";
                    else return false;
                  },
                  getValue: (row) => {
                    return this.getValueObject(row, "numerolote");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisaLote(
                    sender,
                    this.modelConfig.itens.fields.estoque.current,
                    this.modelConfig.itens.current.produto.id,
                    this.modelConfig.itens.fields.estoque.current.grade.id,
                    undefined,
                    (lote) => {
                      this.modelConfig.itens.fields.estoque.current.numerolote = lote.numerolote;
                      this.modelConfig.itens.fields.estoque.current.datavalidadelote = lote.datavalidadelote;
                    }
                  );
                },
                requiredFunc: () => {
                  return this.currentProdutoUsaLote();
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaLote();
                },
              }),
              datavalidadelote: new CMInputConfig({
                form: this,
                caption: "Data Validade Lote",
                mask: "99/99/9999",
                disabled: true,
                gridConfig: new CMGridEditConfigColumn(),
                getValue: (row) => {
                  return this.getValueObject(row, "datavalidadelote");
                },
                requiredFunc: () => {
                  return this.currentProdutoUsaValidadeLote();
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaValidadeLote();
                },
              }),
              quantidade: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn(),
                onExit: () => {
                  this.calcTotalItemEstoque();
                },
              }),
            },
          }),
        },
      }),
      usaenderecoentrega: new CMInputConfig({
        form: this,
      }),
      armazenaenderecoentrega: new CMInputConfig({
        form: this,
        caption: "Salva endereço de entrega como:",
      }),
      identificacaoenderecoentrega: new CMInputConfig({
        form: this,
        maxlength: 30,
        requiredFunc: () => this.model.armazenaenderecoentrega == "Sim",
      }),
      enderecoentrega: {
        logradouro: new CMInputConfig({
          form: this,
          caption: "Logradouro",
          maxlength: 60,
          requiredFunc: () => this.usaEnderecoEntrega(),
        }),
        numero: new CMInputConfig({
          form: this,
          caption: "Número",
          maxlength: 10,
          requiredFunc: () => this.usaEnderecoEntrega(),
        }),
        bairro: new CMInputConfig({
          form: this,
          caption: "Bairro",
          maxlength: 60,
          requiredFunc: () => this.usaEnderecoEntrega(),
        }),
        complemento: new CMInputConfig({
          form: this,
          caption: "Complemento",
          maxlength: 60,
        }),
        estado: new CMInputConfig({
          form: this,
          caption: "Estado",
          values: [
            { value: "AC" },
            { value: "AL" },
            { value: "AP" },
            { value: "AM" },
            { value: "BA" },
            { value: "CE" },
            { value: "DF" },
            { value: "ES" },
            { value: "GO" },
            { value: "MA" },
            { value: "MT" },
            { value: "MS" },
            { value: "MG" },
            { value: "PR" },
            { value: "PB" },
            { value: "PA" },
            { value: "PE" },
            { value: "PI" },
            { value: "RJ" },
            { value: "RN" },
            { value: "RS" },
            { value: "RO" },
            { value: "RR" },
            { value: "SC" },
            { value: "SE" },
            { value: "SP" },
            { value: "TO" },
          ],
        }),
        ibge: new CMInputConfig({
          form: this,
          caption: "Código do Município no IBGE",
          pesqFunc: (sender) => {
            MunicipioBrowseComponent.pesquisa(
              this.model.enderecoentrega.estado,
              sender,
              this.model.enderecoentrega,
              "ibge"
            );
          },
          requiredFunc: () => this.usaEnderecoEntrega(),
        }),
        cep: new CMInputConfig({
          form: this,
          caption: "CEP",
          mask: "99999-999",
          requiredFunc: () => this.usaEnderecoEntrega(),
        }),
      },
    };
  }

  currentProdutoUsaValidadeLote() {
    return (
      this.getValueObject(
        this.modelConfig.itens.current,
        "produto.usavalidadelote"
      ) === "Sim")
  }

  onRemoveItemEstoque(item: any) {
    let itemsEstoque = this.modelConfig.itens.current;
    let qtotal = 0;
    
    itemsEstoque.estoque.forEach((est) => {
      if (item !== est){
        qtotal += this.valueToFloat(est.quantidade);
      }
    });
    itemsEstoque.quantidade = this.floatToValue(qtotal);
    this.calcTotalItem(this.modelConfig.itens.current);
  }

  isDescontoItem() {
    return this.model.descontoaplicato === "NosItens";
  }

  isDescontoPercentual(row) {
    let r = false;
    if (row) r = row.tipodesconto === "Percentual";
    return r;
  }

  getColumnDescontoTotal(atual, inc) {
    if (!this.isDescontoItem()) inc = 0;
    return atual + inc;
  }

  onRemoveItem(item) {
    this.add_vTotalProdutos(this.valueToFloat(item.vtotal, 0), 0);
  }

  calcTotalItem(tipo = undefined) {
    this.calcTotalItem2(this.modelConfig.itens.current, tipo);
  }

  calc_vDescontoItem(item) {
    let vtotal =
      this.valueToFloat(item.quantidade, 0) *
      this.valueToFloat(item.vunitario, 0);
    let vdesconto = (vtotal * this.valueToFloat(item.pdesconto, 0)) / 100;
    return this.floatToValue(vdesconto);
  }

  calc_pDescontoItem(item) {
    let vtotal =
      this.valueToFloat(item.quantidade, 0) *
      this.valueToFloat(item.vunitario, 0);
    let vdesconto = this.valueToFloat(item.vdesconto, 0);
    let pdesconto = (vdesconto / vtotal) * 100;
    return this.floatToValue(pdesconto);
  }

  calcTotalItem2(item, tipo) {
    let vdesconto = this.calc_vDescontoItem(item);
    if (item.vdesconto !== vdesconto) {
      if (tipo === "v") item.pdesconto = this.calc_pDescontoItem(item);
      else item.vdesconto = vdesconto;
    }
    let vtotal =
      this.valueToFloat(item.quantidade, 0) *
      this.valueToFloat(item.vunitario, 0);
    vtotal = vtotal - this.valueToFloat(item.vdesconto, 0);
    this.add_vTotalProdutos(this.valueToFloat(item.vtotal, 0), vtotal);
    item.vtotal = this.floatToValue(vtotal);
  }

  add_vTotalProdutos(oldValue: number, newValue: number) {
    let vtotalprodutos = this.valueToFloat(this.model.vtotalprodutos, 0);
    vtotalprodutos += newValue - oldValue;
    this.model.vtotalprodutos = this.floatToValue(vtotalprodutos);
    this.calcTotalVenda();
  }

  calc_vDescontoTotal() {
    let vtotalprodutos = this.valueToFloat(this.model.vtotalprodutos, 0);
    let vdesconto =
      (vtotalprodutos * this.valueToFloat(this.model.pdesconto, 0)) / 100;
    return this.floatToValue(vdesconto);
  }

  calc_pDescontoTotal() {
    let vtotalprodutos = this.valueToFloat(this.model.vtotalprodutos, 0);
    let vdesconto = this.valueToFloat(this.model.vdesconto, 0);
    let pdesconto = (vdesconto / vtotalprodutos) * 100;
    return this.floatToValue(pdesconto);
  }

  calcTotalVenda(tipo = undefined) {
    let vdesconto = this.calc_vDescontoTotal();
    if (this.model.vdesconto !== vdesconto) {
      if (tipo === "v") this.model.pdesconto = this.calc_pDescontoTotal();
      else this.model.vdesconto = vdesconto;
    }
    let vtotal =
      this.valueToFloat(this.model.vtotalprodutos, 0) -
      this.valueToFloat(this.model.vdesconto, 0) +
      this.valueToFloat(this.model.vdespesasadicionais, 0);
    this.model.vtotal = this.floatToValue(vtotal);
  }

  validItem(item: any) {
    let r: string = "";
    if (this.valueToFloat(item.vtotal, 0) <= 0)
      r = "Valor total deve ser maior que zero";
    if (this.valueToFloat(item.quantidade, 0) <= 0)
      r = "Valor total deve ser maior que zero";
    return r;
  }

  customValid() {
    let r = super.customValid();
    console.log('here');
    if (r === "") {
      if (this.valueToFloat(this.model.vtotal, 0) <= 0)
        r = "Valor total deve ser maior que zero";
    }
    return r;
  }

  zeraDesconto() {
    if (this.isDescontoItem()) {
      this.model.vdesconto = "0,00";
      this.model.pdesconto = "0,00";
      this.calcTotalVenda();
    } else {
      this.model.itens.forEach((item) => {
        item.vdesconto = "0,00";
        item.pdesconto = "0,00";
        this.calcTotalItem2(item, undefined);
      });
    }
  }

  mostraDetalhesQuantidade(row) {
    let r = false;
    if (row && row.produto) {
      r =
        row.produto.usagrade1 === "Sim" ||
        row.produto.usagrade2 === "Sim" ||
        row.produto.usalote === "Sim";
    }
    return r;
  }

  currentProdutoUsaLote() {
    return (
      this.getValueObject(this.modelConfig.itens.current, "produto.usalote") ===
      "Sim"
    );
  }

  currentProdutoUsaGrade() {
    return (
      this.getValueObject(
        this.modelConfig.itens.current,
        "produto.usagrade1"
      ) === "Sim" ||
      this.getValueObject(
        this.modelConfig.itens.current,
        "produto.usagrade2"
      ) === "Sim"
    );
  }

  usaEnderecoEntrega() {
    return this.model.usaenderecoentrega == "Sim";
  }

  calcTotalItemEstoque() {
    let item = this.modelConfig.itens.current;
    let qtotal = 0;
    item.estoque.forEach((est) => {
      qtotal += this.valueToFloat(est.quantidade);
    });
    item.quantidade = this.floatToValue(qtotal);
    this.calcTotalItem2(item, undefined);
  }

  /*
  dropdownValuesVUnCom(row) {
    let r = [];
    if (row && row.produto)
      r = ProdutoBrowseComponent.dropdownValuesVUnCom(row.produto);
    return r;
  }*/

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  onActionpesqLocalEntrega() {
    const idCliente = this.valueToInteger(this.fieldByName("cliente.id"));
    if (idCliente > 0) {
      PessoaBrowseComponent.pesquisaEnderecoEntrega(idCliente, (row) => {
        this.model.usaenderecoentrega = "Sim";
        this.model.enderecoentrega.logradouro = row.logradouro;
        this.model.enderecoentrega.numero = row.numero;
        this.model.enderecoentrega.bairro = row.bairro;
        this.model.enderecoentrega.complemento = row.complemento;
        this.model.enderecoentrega.estado = row.estado;
        if (this.valueToInteger(row.ibge_id) > 0) {
          this.model.enderecoentrega.ibge = {
            id: row.ibge_id,
            estado: row.ibge_estado,
            codigouf: row.ibge_codigouf,
            municipio: row.ibge_municipio,
            codigomunicipio: row.ibge_codigomunicipio,
          };
        } else this.model.enderecoentrega.ibge = {};
        this.model.enderecoentrega.cep = row.cep;
        this.model.identificacaoenderecoentrega = row.identificacao;
        this.model.armazenaenderecoentrega = "Sim";
      });
    }
  }

  formAddProd = {
    opened: false,
    modelConfig: {
      quantidade: new CMInputConfig({
        form: this,
        caption: "Quantidade",
        dataType: "double(2)",
      }),
      produto: new CMInputConfig({
        form: this,
        caption: "Produto",
        maxlength: 30,
        padInfo: { size: 30 },
        pesqFunc: (sender) => {
          ProdutoBrowseComponent.pesquisa(
            sender,
            this.formAddProd.model,
            "Ativos",
            undefined,
            (produto) => {
              this.formAddProd.model.grade = { id: "0", descricao: "" };
              this.formAddProd.model.numerolote = "";
            }
          );
        },
      }),
      grade: new CMInputConfig({
        form: this,
        caption: "Grade",
        required: true,
        pesqOnly: true,
        pesqFunc: (sender) => {
          ProdutoBrowseComponent.pesquisaGrade(
            sender,
            this.formAddProd.model,
            this.formAddProd.model.produto.id
          );
        },
        visibleFunc: () => {
          return this.formAddProd.usaGrade();
        },
      }),
      numerolote: new CMInputConfig({
        form: this,
        caption: "Número Lote",
        dataType: "string",
        maxlength: 20,
        disabledFunc: (row) => {
          if (this.formAddProd.usaGrade()) {
            return this.formAddProd.model.grade.id === "";
          } else {
            return false;
          }
        },
        pesqFunc: (sender) => {
          ProdutoBrowseComponent.pesquisaLote(
            sender,
            this.formAddProd.model,
            this.formAddProd.model.produto.id,
            this.formAddProd.model.grade.id
          );
        },
        visibleFunc: () => {
          return this.formAddProd.usaLote();
        },
      }),
    },
    model: {
      quantidade: "1,00",
      produto: {
        id: "0",
        codigo: "",
        descr: ""
      },
      grade: {
        id: "0",
        descricao: ""
      },
      numerolote: "",
    },

    show: () => {
      CMFormModalComponent.open("formAddProduto", () => {
        this.formAddProd.modelConfig.produto.focus();
      });
      this.formAddProd.opened = true;
    },

    close: () => {
      CMFormModalComponent.close("formAddProduto");
      this.formAddProd.opened = false;
    },

    valid: () => {
      if (this.valueToFloat(this.formAddProd.model.quantidade) <= 0) {
        this.formAddProd.modelConfig.quantidade.focus();
        CMFormGlobaisComponent.toastErro("Não informada", "Quantidade");
        return false;
      }
      if (this.valueToFloat(this.getValueObject(this.formAddProd.model, 'produto.id'), 0) <= 0) {
        this.formAddProd.modelConfig.produto.focus();
        CMFormGlobaisComponent.toastErro("Não informado", "Produto");
        return false;
      }
      if (this.formAddProd.usaGrade()) {
        if (this.valueToFloat(this.getValueObject(this.formAddProd.model, 'grade.id'), 0) <= 0) {
          this.formAddProd.modelConfig.grade.onClick();
          return false;
        }
      }
      if (this.formAddProd.usaLote()) {
        if (this.trimValue(this.formAddProd.model.numerolote) == "") {
          this.formAddProd.modelConfig.numerolote.focus();
          CMFormGlobaisComponent.toastErro("Não informada", "Lote");
          return false;
        }
      }
      return true;
    },

    add: () => {
      if (this.formAddProd.valid() == true) {
        let estoque = [];
        if (this.formAddProd.usaGrade() || this.formAddProd.usaLote()) {
          estoque = [
            {
              id: CMGridEditConfig.newId(),
              grade: this.formAddProd.model.grade,
              numerolote: this.formAddProd.model.numerolote,
              quantidade: this.formAddProd.model.quantidade,
            },
          ];
        }
        let item: any = {
          id: CMGridEditConfig.newId(),
          produto: this.formAddProd.model.produto,
          quantidade: this.formAddProd.model.quantidade,
          vunitario: ProdutoBrowseComponent.get_vUniCon(
            this.formAddProd.model.produto
          ),
          vtotal: "0,00",
          pdesconto: "0,00",
          vdesconto: "0,00",
          tipodesconto: "Percentual",
          estoque: estoque,
        };
        this.model.itens.unshift(item);
        this.modelConfig.itens.selectFirst(false);
        this.calcTotalItem();
        this.formAddProd.model.quantidade = "1,00";
        this.formAddProd.model.produto = { id: "0", codigo: "", descr: ""};
        this.formAddProd.model.grade = { id: "0", descricao: "" };
        this.formAddProd.model.numerolote = "1,00";
        this.formAddProd.modelConfig.produto.focus();
      }
    },

    usaGrade: () => {
      let b = false;
      if (
        this.formAddProd &&
        this.formAddProd.model &&
        this.formAddProd.model.produto
      ) {
        let produto: any = this.formAddProd.model.produto;
        b = produto.usagrade1 === "Sim" || produto.usagrade2 === "Sim";
      }
      return b;
    },

    usaLote: () => {
      let b = false;
      if (
        this.formAddProd &&
        this.formAddProd.model &&
        this.formAddProd.model.produto
      ) {
        let produto: any = this.formAddProd.model.produto;
        b = produto.usalote === "Sim";
      }
      return b;
    },
  };

  protected getKeyDownCodes() {
    let r = super.getKeyDownCodes();
    r.push(13);
    return r;
  }

  waitCloseGlobalSpinner() {
    if ($("#globalSpinner").css("display") !== "none") {
      setTimeout(() => {
        this.waitCloseGlobalSpinner();
      }, 200);
    } else {
      this.formAddProd.add();
    }
  }

  protected onKeydown(keyCode: number, event: KeyboardEvent) {
    super.onKeydown(keyCode, event);
    switch (keyCode) {
      case 13: // enter
        if (this.formAddProd.opened) {
          $(":text").blur();
          setTimeout(() => {
            this.waitCloseGlobalSpinner();
          }, 200);
        }
        break;
    }
  }
}
