// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormRelatComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
} from "./../../../@cmcore/component";
import { VendedorBrowseComponent } from "../../vendedor";

@Component({
  selector: "vendas-por-mes",
  templateUrl: "vendas-por-mes.component.html",
})
export class RelatVendasPorMesComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoVendasPorMes";
    this.filterConfig = {
      anoref: new CMInputConfig({
        form: this,
        caption: "Ano de referência",
        dataType: "integer",
      }),
      consideraprevenda: new CMInputConfig({
        form: this,
        caption: "Considera Pré-Venda em aberto",
      }),
    };
    this.filter.anoref = this.integerToValue(new Date().getFullYear());
    this.filter.consideraprevenda = "Não";
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.anoref);
      if (!r)
        CMFormGlobaisComponent.toastErro("Ano de referência não informado");
    }
    return r;
  }
}
