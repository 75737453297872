// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "./../../@cmcore/component/cmform/cmform.component";

import {
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMGridEditConfig,
  CMInputConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "./../../@cmcore/component";
import { ProdutoBrowseComponent } from "./../index";
import { FuncsGlobais } from "../funcs.globais";
@Component({
  selector: "produto-detalhes-saldo",
  templateUrl: "produto-detalhes-saldo.component.html",
  styleUrls: ["produto-detalhes-saldo.component.scss"],
})
export class ProdutoDetalhesSaldoComponent extends CMFormComponent {
  _owner: ProdutoBrowseComponent = undefined;
  get owner() {
    return this._owner;
  }
  set owner(value) {
    this._owner = value;
  }

  dados = {
    usalote: "Não",
    usavalidadelote: "Não",
    usagrade1: "Não",
    usagrade2: "Não",
    saldos: [],
  };

  config = {
    saldos: new CMGridEditConfig(),
  };

  constructor(_injector: Injector) {
    super(_injector);
    this.setConfig();
    setTimeout(() => {
      this.getSaldos();
    }, 10);
  }

  setConfig() {
    this.config.saldos = new CMGridEditConfig({
      form: this,
      caption: "Saldos",
      moreActions: [
        new CMGridEditConfigMore({
          hint: "Detalhes consignação",
          icone: "visibility",
          onClick: (row) => this.detalhesConsignacao(row),
          visible: (row) => this.valueToFloat(row.saldoconsignacao) > 0,
        }),
      ],
      fields: {
        numerolote: new CMInputConfig({
          form: this,
          caption: "Lote",
          visibleFunc: () => this.dados.usalote == "Sim",
          gridConfig: new CMGridEditConfigColumn({
            readOnly: true,
          }),
        }),
        datavalidadelote: new CMInputConfig({
          form: this,
          caption: "Lote",
          visibleFunc: () => this.dados.usavalidadelote == "Sim",
          gridConfig: new CMGridEditConfigColumn({
            readOnly: true,
          }),
        }),
        grade1: new CMInputConfig({
          form: this,
          caption: FuncsGlobais.paramEmpresa.nomeGrade1,
          visibleFunc: () => this.dados.usagrade1 == "Sim",
          gridConfig: new CMGridEditConfigColumn({
            readOnly: true,
          }),
        }),
        grade2: new CMInputConfig({
          form: this,
          caption: FuncsGlobais.paramEmpresa.nomeGrade2,
          visibleFunc: () => this.dados.usagrade2 == "Sim",
          gridConfig: new CMGridEditConfigColumn({
            readOnly: true,
          }),
        }),
        saldofisico: new CMInputConfig({
          form: this,
          caption: "Saldo Físico",
          gridConfig: new CMGridEditConfigColumn({
            readOnly: true,
          }),
        }),
        saldocomprometido: new CMInputConfig({
          form: this,
          caption: "Comprometido em Pré-Venda",
          gridConfig: new CMGridEditConfigColumn({
            readOnly: true,
          }),
        }),
        saldoconsignacao: new CMInputConfig({
          form: this,
          caption: "Consignado à terceiros",
          gridConfig: new CMGridEditConfigColumn({
            readOnly: true,
          }),
        }),
        saldodisponivel: new CMInputConfig({
          form: this,
          caption: "Saldo Disponível",
          gridConfig: new CMGridEditConfigColumn({
            readOnly: true,
          }),
        }),
      },
    });
  }

  descricaoProduto() {
    let r =
      this.owner.fieldByName("codigo") +
      " - " +
      this.owner.fieldByName("descr");
    return r;
  }

  detalhesConsignacao(row) {
    console.log('Row: ', row);
    let html = "<div style='margin: 1em;'>";
    row.detalhesconsignacao.forEach((item) => {
      html += `<p>
                 <span><strong>Consignação: </strong>${item.nnf}</span>
                 <span><strong>Quantidade: </strong>${item.qtde}</span>
                 <span><strong>Cliente: </strong>${item.nomecliente}</span>
               </p>
               `;
    });
    html += "</div>";
    CMFormGlobaisComponent.showMessageHtml(html, "Detalhes da Consignação");
  }

  getSaldos() {
    let values = {
      id: this.owner.fieldByName("id"),
    };
    this.postSubscribe(
      "/api/produto/GetSaldo",
      JSON.stringify(values),
      (ret) => {
        this.dados.saldos = [];
        if (ret["result"] === "ok") {
          console.log('ret: ', ret);
          Object.assign(this.dados, ret);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("getSaldos", error);
      }
    );
  }

  close(formName) {
    if (this.owner) this.owner.beforeComponent = undefined;
    CMFormModalComponent.close(formName);
  }
}
