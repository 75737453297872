// tslint:disable
import { Component, Input, EventEmitter, Output } from "@angular/core";
import { CMFormGlobaisComponent, CMFormRelatComponent } from "..";

import { CMInput } from "../../../decorator";
import { CMInputComponent } from "../../cminput/cminput.component";
import { CMFormComponent } from "../cmform.component";

@Component({
  selector: "cmform-relat",
  templateUrl: "./cmform-relat-default.component.html",
  styleUrls: ["./cmform-relat-default.component.scss"],
})
export class CMFormRelatDefaultComponent {
  @Output()
  clickPrint = new EventEmitter();
  _clickButtonPrint(event: any) {
    this.clickPrint.emit(event);
  }

  @Input()
  @CMInput()
  caption: string = "";

  @Input()
  @CMInput()
  iconeButton: string = "print";

  @Input()
  @CMInput()
  hintButton: string = "Imprimir";

  @Input()
  get owner() {
    return this.fOwner;
  }
  set owner(aOwner) {
    this.fOwner = aOwner;
    if (aOwner && aOwner.relatName) {
      aOwner.getDireitos("relats", "Extra", aOwner.relatName, (data) => {
        if (data && data.Helps) {
          this.helps = data.Helps;
        }
      });
    }
  }
  fOwner: CMFormRelatComponent = undefined;

  public helps = [];

  name: string = "cmform_" + CMInputComponent.nextGlobalId().toString();

  getShowActionHelp() {
    return this.helps.length > 0;
  }

  togglePopup(event) {
    setTimeout(() => {
      const popup = document.getElementById("popupHelp_" + this.name);
      popup.style.top = "auto";
      popup.style.left = "auto";
      popup.style.marginRight = "5%";
      popup.style.right = "0";
      popup.classList.toggle("show");
    }, 10);
  }

  showHelpLinkIFrame(recursoHelp) {
    let html = CMFormComponent.trimValue(recursoHelp.link);
    if (!html.toLocaleLowerCase().startsWith("<iframe")) {
      html =
        '<iframe src="' +
        recursoHelp.link +
        '"' +
        '        style="width: 100%; height: calc(100vh - 200px)"' +
        '        frameborder="0"' +
        '        allow="accelerometer;' +
        "        autoplay;" +
        "        encrypted-media;" +
        "        gyroscope;" +
        '        picture-in-picture"' +
        "        allowfullscreen" +
        "></iframe>";
    }
    CMFormGlobaisComponent.showMessageHtml(html, recursoHelp.titulo);
  }

}
