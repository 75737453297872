// tslint:disable
import { Component, Injector } from "@angular/core";
import { CupomDescontoBrowseComponent, MunicipioBrowseComponent } from "..";
import { FuncsGlobais } from "../funcs.globais";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "./../../@cmcore/component";

@Component({
  selector: "cfe-edit",
  templateUrl: "cfe-edit.component.html",
})
export class CFeEditComponent extends CMFormEditComponent {
  getTitle() {
    let r: string = "CF-e";
    let tmp: string = this.fieldByName("infnfe.ide.nnf");
    if (tmp !== "0") r += " - #" + tmp;
    return r;
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "cfe";
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      infnfe: {
        ide: {
          cnf: new CMInputConfig({ form: this, caption: "Caixa" }),
          serie: new CMInputConfig({
            form: this,
            caption: "Serie SAT",
            dataType: "integer",
          }),
          nnf: new CMInputConfig({ form: this, caption: "#" }),
          dhemi: new CMInputConfig({ form: this, caption: "Data emissão" }),
          nfref: new CMGridEditConfig({
            form: this,
            required: false,
            caption: "Cupom de cancelamento",
            fields: {
              refnfe: new CMInputConfig({
                form: this,
                caption: "Chave de acesso",
                maxlength: 44,
                gridConfig: new CMGridEditConfigColumn(),
              }),
            },
          }),
        },
        dest: {
          cpf: new CMInputConfig({
            form: this,
            caption: "CPF Cliente",
          }),
        },
        det: new CMGridEditConfig({
          form: this,
          objName: "det",
          caption: "Produtos",
          fields: {
            prod: {
              produto: new CMInputConfig({
                form: this,
                caption: "Produto",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 1,
                  tipo: "input-pesquisa",
                  width: "350px",
                  getValue: (row) => {
                    return this.getValueObject(row, "prod.produto.codigo");
                  },
                  getBottomLabelColumn: (row) => {
                    return this.getValueObject(row, "prod.produto.descr");
                  },
                }),
              }),
              qcom: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 2,
                }),
                hintButton: "Visualizar",
                iconButton: "visibility",
                showButtonClear: false,
                pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
                pesqFunc: (sender) => {
                  if (
                    this.mostraDetalhesQuantidade(
                      this.modelConfig.infnfe.det.current
                    )
                  )
                    this.modelConfig.infnfe.det.extraClick(
                      new CMGridEditConfigMore({
                        index: 0,
                        title: "Detalhes da Quantidade",
                      })
                    );
                },
                showButtonFunc: (show, row) =>
                  this.mostraDetalhesQuantidade(row),
              }),
              vuncom: new CMInputConfig({
                form: this,
                caption: "Valor unitário",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 3,
                }),
              }),
              vtotal: new CMInputConfig({
                form: this,
                caption: "Valor total",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 5,
                }),
              }),
              vdesc: new CMInputConfig({
                form: this,
                caption: "Valor de desconto",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 4,
                }),
              }),
              cfop: new CMInputConfig({
                form: this,
                caption: "CFOP",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 6,
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "prod.cfop.codigo");
                  },
                  getBottomLabelColumn: (row) => {
                    return this.getValueObject(row, "prod.cfop.descricao");
                  },
                }),
              }),
            },
            estoque: new CMGridEditConfig({
              form: this,
              required: false,
              fields: {
                grade: new CMInputConfig({
                  form: this,
                  caption: "Grade",
                  required: true,
                  pesqOnly: true,
                  gridConfig: new CMGridEditConfigColumn({
                    tipo: "input-pesquisa",
                    getValue: (row) => {
                      return this.getValueObject(row, "grade.descricao");
                    },
                  }),
                }),
                qcom: new CMInputConfig({
                  form: this,
                  caption: "Quantidade",
                  dataType: "double(2)",
                  gridConfig: new CMGridEditConfigColumn(),
                  required: true,
                }),
              },
            }),
          },
        }),
        total: {
          icmstot: {
            vprod: new CMInputConfig({
              form: this,
              caption: "Valor total dos produtos",
              dataType: "double(2)",
            }),
            vdesc: new CMInputConfig({
              form: this,
              caption: "Valor de desconto",
              dataType: "double(2)",
            }),
            vnf: new CMInputConfig({
              form: this,
              caption: "Valor Liquído",
              dataType: "double(2)",
            }),
          },
        },

        pag: {
          cupomdesconto: new CMInputConfig({
            form: this,
            caption: "Cupom de Desconto",
            pesqFunc: (sender) => {
              CupomDescontoBrowseComponent.pesquisa(
                "Não",
                sender,
                this.model.infnfe.pag,
                undefined
              );
            },
          }),
          vutilizadocupom: new CMInputConfig({
            form: this,
            caption: "Valor utilizado",
            dataType: "double(2)",
            disabled: true,
          }),
        },
        cobr: {
          duplicatas: new CMGridEditConfig({
            form: this,
            caption: "Duplicatas",
            fields: {
              dvenc: new CMInputConfig({
                form: this,
                caption: "Data de vencimento",
                gridConfig: new CMGridEditConfigColumn(),
              }),
              valor: new CMInputConfig({
                form: this,
                caption: "Valor da duplicata",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({}),
              }),
              formapagamento: new CMInputConfig({
                form: this,
                caption: "Forma de pagamento",
                gridConfig: new CMGridEditConfigColumn({
                  readonly: true,
                  getValue: (row) =>
                    this.getValueObject(row, "formapagamento.descricao"),
                }),
              }),
            },
          }),
        },
        vendedor: new CMInputConfig({
          form: this,
          caption: "Vendedor",
        }),
        tipopagamento: new CMInputConfig({
          form: this,
          caption: "Tipo de Pagamento",
          values: [
            { value: "AVista", text: "a Vista" },
            { value: "FormaPagamento", text: "Por forma de pagamento" },
          ],
        }),
        qtdeparcelas: new CMInputConfig({
          form: this,
          caption: "Condição de pagamento",
        }),
        chaveacesso: new CMInputConfig({
          form: this,
          caption: "Chave de acesso",
          disabled: true,
        }),
        status: new CMInputConfig({ form: this, caption: "Status" }),
      },
    };
  }

  mostraDetalhesQuantidade(row) {
    let r = false;
    if (row && row.prod && row.prod.produto) {
      r =
        row.prod.produto.usagrade1 === "Sim" ||
        row.prod.produto.usagrade2 === "Sim" ||
        row.prod.produto.usalote === "Sim";
    }
    return r;
  }
}
